import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Constants } from '../../../core/constants/constants';
import { UtilsService } from '../../../services/utils.service';
import { ActivatedRoute, Params } from '@angular/router';
import { SearchDomain } from './../../../domain/search.domain';
import { PLATFORM_ID } from '@angular/core';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import {
  DropdownSelect,
  QrListListingSubNavbarComponent,
} from '../listing-list/components/qr-list-listing-sub-navbar/qr-list-listing-sub-navbar.component';
import { ParamsDomain } from '../../../domain/params.domain';
import { LandingTotalResultService } from '@app/services/landing-total-result.service';
import { QrCardPropertyComponent } from '@app/components/qr-card-property/qr-card-property.component';
import {
  QrDialogComponent,
  QRDialogConfig,
} from '@app/components/qr-dialog/qr-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { Location as LocationM } from '@app/core/models/location.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeolocationLatLng } from '@app/components/qr-google-autocomplete/qr-google-autocomplete.component';
import { MarkerMap } from '@app/core/models/coords-map.model';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { TotalResultsSEO } from '@app/core/models/total-results-seo.model';
import { Maps } from '@base/src/app/core/models/maps.model';
import { ListingAndEntrepreneurshipByIdService } from '@base/src/app/services/listing-and-entrepreneurship-by-id.service';
import { ListingListLoaderComponent } from '../listing-list/components/listing-list-loader/listing-list-loader.component';
import { QrMapGoogleComponent } from '@base/src/app/components/qr-map-google/qr-map-google.component';
import { QrPaginationComponent } from '@base/src/app/components/qr-pagination/qr-pagination.component';
import { Polyline } from '@base/src/app/core/models/polyline.model';
import { ListingAndEntrepreneurship } from '@base/src/app/core/interfaces/listing-and-entrepreneurship.interface';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRButtonComponent } from '@base/src/app/components/ui/qr-button/qr-button.component';
import { HeaderResultsComponent } from '../listing-list/components/header-results/header-results.component';
import { QRIcon } from '@base/src/app/core/models/qr-icon.model';
import { ButtonWithIconConfig } from '@base/src/app/components/qr-button-with-icon/qr-button-with-icon.component';

@Component({
  selector: 'app-entrepreneurship-list',
  templateUrl: './entrepreneurship-list.page.html',
  styleUrls: ['./entrepreneurship-list.page.scss'],
  standalone: true,
  imports: [
    MatSelectModule,
    L10nTranslationModule,
    CommonModule,
    ListingListLoaderComponent,
    QrDialogComponent,
    QRButtonComponent,
    QrCardPropertyComponent,
    QrMapGoogleComponent,
    QrPaginationComponent,
    QrListListingSubNavbarComponent,
    HeaderResultsComponent,
  ],
})
export class EntrepreneurshipListPage implements OnInit, OnDestroy {
  readonly LISTINGS_VIEW_MODE_LIST: string = Constants.LISTINGS_VIEW_MODE_LIST;
  readonly LISTINGS_VIEW_MODE_GRID: string = Constants.LISTINGS_VIEW_MODE_GRID;
  readonly LISTINGS_VIEW_MODE_MAP: string = Constants.LISTINGS_VIEW_MODE_MAP;

  public mapCenter: GeolocationLatLng = Constants.DEFAULT_CENTER_COORDS;
  public isBrowser: boolean = false;
  public coords: MarkerMap[] = [];
  public zoom: number = 14;
  public polylines: Polyline[] = [];
  public isResultsLoading: boolean = true;
  public isMapResultsLoading: boolean = true;
  public statusCardPropMapMode: { isVisible: boolean } = { isVisible: false };
  public cardPropMapModeSelected: ListingAndEntrepreneurship | null = null;

  public innerWidth: number = 0;
  public isEntrepreneurshipLoading: boolean = true;
  public isDesktop: boolean = isPlatformBrowser(this.platformId)
    ? window.innerWidth >= 1200
    : false;

  public isSidebarHidden: boolean = false;
  // Opción seleccionada: Ordenar por
  public propHoverID: any;
  // Propiedad a la que se le hace hover
  public redirect: boolean = false;
  public loadingFavorites: boolean = false;
  public listingNewProperties: any[] = [];

  public dialogFilters: any = {
    isDialogOpen: false,
    configButtonLeft: {
      style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Limpiar filtros',
    },
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Aplicar',
    },
    dinamicComponent: 'filters-list',
    isTopbarVisible: true,
  };

  public readonly BUTTON_CLEAN_FILTERS: QRButton = {
    id: 'button-clean-filters',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Limpiar filtros',
  };

  public saveSearchButtons: ButtonWithIconConfig = {
    text: 'list.saveSearch',
    icon: QRIcon.NAME_BOOKMARK,
    height: 48,
    paddingHorizontal: 16,
    paddingVertical: 0,
    style:
      'button-color-primary-border row-reverse margin-right icon-color-primary',
  };

  public dialogDataSaveSearch: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: 'Guardá tu búsqueda y creá el alerta',
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Guardar búsqueda',
      id: 'apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'save-search',
    isTopbarVisible: true,
    isBotbarVisible: true,
    isHeightAutoFitContent: true,
  };

  public dialogDataNotSaveSearch: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: 'Guardá tu búsqueda y creá el alerta',
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Aceptar',
      id: 'apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'not-save-search',
    isTopbarVisible: true,
    isBotbarVisible: true,
    isHeightAutoFitContent: true,
  };

  public readonly configButtonCleanFilter = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Limpiar filtros',
  };

  public readonly dropdownSelectOrderBy: DropdownSelect = {
    placeholder: 'list.orderBy',
    isOpen: false,
  };

  public dialogCreateAccount: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: '¡Bienvenido!',
    configButtonLeft: {
      style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Cancelar',
      id: 'clean-filters',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Crear mi cuenta',
      id: '@apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'invitation-to-register',
    isTopbarVisible: true,
    isBotbarVisible: false,
    isHeightAutoFitContent: true,
    fixedWidth: 460,
  };

  public readonly BUTTON_ARROW: QRButton = {
    id: 'button-hidde-map',
    hierarchy: QRButton.HIERARCHY_GREY,
    size: QRButton.SIZE_40_PX,
    icon: QRIcon.NAME_ARROW_LEFT,
    iconOnly: true,
    iconTooltipOnly: 'Mapa completo',
  };

  @ViewChildren(QrCardPropertyComponent)
  qrCardProperties: QueryList<QrCardPropertyComponent> =
    new QueryList<QrCardPropertyComponent>();

  private tagCity: string = '';
  private tagOperation: string = '';
  private tagProperty: string = '';
  private getURLLocation: Location | undefined;
  private filterOrderBySubscription: Subscription | undefined;
  private destroy$: Subject<void> = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    this.isDesktop = window.innerWidth >= 1200; // Adjust the breakpoint as needed
  }

  constructor(
    public searchDomain: SearchDomain,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    public paramsDomain: ParamsDomain,
    private titleService: Title,
    private metaTagService: Meta,
    private cdr: ChangeDetectorRef,
    private landingTotalResultService: LandingTotalResultService,
    private listingAndEntrepreneurshipByIdService: ListingAndEntrepreneurshipByIdService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.initializeState();
    this.handleLoader();
  }

  ngOnDestroy(): void {
    this.filterOrderBySubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  openFilters(): void {
    this.dialogFilters.isDialogOpen = true; // Diálogo visible
    this.dialogFilters = Object.assign({}, this.dialogFilters);
  }

  public toggleSidebar() {
    this.isSidebarHidden = !this.isSidebarHidden;
    const TOGGLE_BUTTON = document.getElementById('toggle-sidebar');
    if (TOGGLE_BUTTON) {
      TOGGLE_BUTTON.classList.toggle('hidden', this.isSidebarHidden);
    }
  }

  pagination(page: any): void {
    if (this.searchDomain.page != page - 1) {
      this.searchDomain.page = page - 1;
      this.searchDomain.windowLocationHrefEmpre();
    }
  }

  changeView(viewType: any): void {
    this.searchDomain.viewMode = viewType;
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ top: 0 });
    }
    this.refreshMarkers();

    this.cdr.detectChanges();
  }

  public onclickmarker(listingId: any): void {
    this.statusCardPropMapMode.isVisible = true;

    const LIST = this.searchDomain.listingAndEntrepreneurshipMap.find(
      (p: any) => {
        return p.listingId === listingId;
      }
    );

    if (LIST) {
      this.listingAndEntrepreneurshipByIdService
        .getData<ListingAndEntrepreneurship>(listingId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: IQrResponse<ListingAndEntrepreneurship>) => {
            if (response.code === 200) {
              this.cardPropMapModeSelected = response.data;
            }
          },
          error: error => {
            console.error('Error occurred:', error);
          },
        });
    } else {
      console.warn('Listing not found in the map data.');
    }
  }

  polygonCoordinatesChanged(value: any): void {
    if (value != null && this.searchDomain.filterPoligono != value) {
      this.searchDomain.filterPoligono = value;
      this.searchDomain.windowLocationHrefEmpre();
    }
  }

  // Arma la variable defaultParams que contiene los parámetros para la búsqueda
  setDefaultParams(
    path: string, // url
    defaultParams: Params, // const que agrupa los parámetros del filtro de búsqueda
    tagCityParam: string, // Nombre del lugar, ejemplo: 'La Plata'
    codePlace: string, // Código del lugar, ejemplo '68@La Plata'
    typePlace: string, // Tipo de lugar: cityId, stateId, countyId, privateCommunityId
    label?: string
  ): Params {
    const parameters = defaultParams;
    //     const operation = '';
    let typeProp = '';
    const stage = '';
    this.tagOperation = '';

    this.tagCity = '';
    if (tagCityParam) {
      this.tagCity = tagCityParam;
    }

    switch (true) {
      case path.includes('departamento'):
        typeProp = '1,2,3,4,5,6,7,8';
        this.tagProperty = 'departamentos';
        break;
      case path.includes('apartamento'):
        typeProp = '1,2,3,4,5,6,7,8';
        this.tagProperty = 'apartamentos';
        break;
      case path.includes('casa'):
        typeProp = '9,10,11';
        this.tagProperty = 'casas';
        break;
      case path.includes('terreno'):
        typeProp = '18';
        this.tagProperty = 'terrenos';
        break;
      case path.includes('locales'):
        typeProp = '17';
        this.tagProperty = 'locales';
        break;
      case path.includes('oficinas'):
        typeProp = '16';
        this.tagProperty = 'oficinas';
        break;
      default:
        break;
    }

    parameters['in:eStageId'] = parameters['in:eStageId']
      ? parameters['in:eStageId']
      : stage;

    parameters['in:typeId'] = parameters['in:typeId']
      ? parameters['in:typeId']
      : typeProp;

    if (codePlace && typePlace) {
      parameters['eq:' + typePlace + 'Id'] = parameters[
        'eq:' + typePlace + 'Id'
      ]
        ? parameters['eq:' + typePlace + 'Id']
        : codePlace;
    }

    if (label) {
      parameters['eq:label'] = parameters['eq:label']
        ? parameters['eq:label']
        : label;
    }

    return parameters;
  }

  // setea parámetros de busqueda y metaTags para url específicas
  addDefaultParamsByRoute(params: Params, path: string): Params {
    const stageId = params['in:eStageId'] ? params['in:eStageId'] : 0;
    const stagetypes = [];
    stagetypes[3] = 'pozo';
    stagetypes[1] = 'pre venta';
    stagetypes[4] = 'terminado';
    stagetypes[2] = 'en construcción';

    const defaultParams = Object.assign({}, params);
    defaultParams['landingPath'] = path;

    defaultParams['sort'] = defaultParams['sort']
      ? defaultParams['sort']
      : '-createdAt';
    defaultParams['page'] = defaultParams['page'] ? defaultParams['page'] : '0';
    defaultParams['pageSize'] = defaultParams['pageSize']
      ? defaultParams['pageSize']
      : '21';
    this.tagOperation = stagetypes[stageId];
    // IMPORTANTE: En la base de datos está como "Countie" pero acá se usa como "County"
    switch (path) {
      case 'emprendimientos':
        this.generateMetatags(
          this.searchDomain.reportResult.quantity,
          'Venta de emprendimientos | RE/MAX',
          'Explorá unidades en venta de entre más de',
          'emprendimientos con RE/MAX.'
        );
        this.tagOperation = 'Venta';

        break;
      default:
        this.setDefaultMetaTags(
          this.tagCity,
          this.tagProperty,
          this.tagOperation
        );
        break;
    }
    return defaultParams;
  }

  updateCanonicalUrl(url: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  cleanFilters(): void {
    this.searchDomain.resetAll();
    this.searchDomain.windowLocationHrefEmpre();
  }

  //setea los metaTags default
  setDefaultMetaTags(city: string, property: string, operation: string): void {
    this.getURLLocation = this.document.location;
    const url =
      this.getURLLocation.protocol +
      '//' +
      this.getURLLocation.host +
      '/' +
      this.route.snapshot.url[0]?.path;

    this.updateCanonicalUrl(url);

    this.metaTagService.updateTag({
      name: 'og:url',
      content: url,
    });

    if (operation) {
      if (city) {
        let propertyCase = '';
        if (property) {
          propertyCase = this.toTitleCase(property);
        }
        const operationCase = this.toTitleCase(operation);

        this.titleService.setTitle(
          propertyCase + ' en ' + operationCase + ' en ' + city + ' | RE/MAX'
        );
        this.metaTagService.updateTag({
          name: 'og:title',
          content:
            propertyCase + ' en ' + operationCase + ' en ' + city + ' | RE/MAX',
        });

        this.metaTagService.updateTag({
          name: 'description',
          content:
            'Explorá ' +
            property +
            ' en ' +
            operation +
            ' en ' +
            city +
            ' de entre más de ' +
            this.searchDomain.reportResult.quantity +
            ' propiedades. RE/MAX tiene las mejores opciones para vos. Visitá nuestra web para encontrar tu próximo hogar. ¡Hacé tu sueño realidad!',
        });

        this.metaTagService.updateTag({
          name: 'og:description',
          content:
            'Explorá ' +
            property +
            ' en ' +
            operation +
            ' en ' +
            city +
            ' de entre más de ' +
            this.searchDomain.reportResult.quantity +
            ' propiedades. RE/MAX tiene las mejores opciones para vos. Visitá nuestra web para encontrar tu próximo hogar. ¡Hacé tu sueño realidad! ',
        });
      } else {
        this.titleService.setTitle('Venta y alquiler de propiedades | RE/MAX');
      }
    } else if (city) {
      let propertyCase = '';
      if (property) {
        propertyCase = this.toTitleCase(property);
      }
      this.titleService.setTitle(propertyCase + ' en ' + city + ' | RE/MAX');

      this.metaTagService.updateTag({
        name: 'og:title',
        content: propertyCase + ' en ' + city + ' | RE/MAX',
      });

      this.metaTagService.updateTag({
        name: 'description',
        content:
          'Explorá ' +
          property +
          ' en ' +
          city +
          ' de entre más de ' +
          this.searchDomain.reportResult.quantity +
          ' propiedades. RE/MAX tiene las mejores opciones para vos. Visitá nuestra web para encontrar tu próximo hogar. ¡Hacé tu sueño realidad!',
      });

      this.metaTagService.updateTag({
        name: 'og:description',
        content:
          'Explorá ' +
          property +
          ' en ' +
          city +
          ' de entre más de ' +
          this.searchDomain.reportResult.quantity +
          ' propiedades. RE/MAX tiene las mejores opciones para vos. Visitá nuestra web para encontrar tu próximo hogar. ¡Hacé tu sueño realidad!',
      });
    } else {
      this.titleService.setTitle('Explorá propiedades | RE/MAX');
    }
  }

  toTitleCase(str: any) {
    return str.replace(/\w\S*/g, function (txt: any) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  async generateMetatags(
    TOTAL_RESULTS_PARAMETER: string | number, //conteo de propiedades | string: conteo desde TotalResultsSEO | number: conteo desde this.searchDomain.reportResult.quantity
    title: string, // Tag title
    predescription: string, // Tag description: texto previo al n° de resultados
    description: string, // Tag description: texto posterior al n° de resultados
    keywords?: string // Tag keywords
  ): Promise<void> {
    let totalResult: string | number = '';
    if (typeof TOTAL_RESULTS_PARAMETER == 'string') {
      const totalResultData: IQrResponse<TotalResultsSEO> =
        await this.landingTotalResultService.get(
          TOTAL_RESULTS_PARAMETER.toString()
        );

      totalResult = 0;

      if (totalResultData) {
        totalResult = totalResultData.data.totalResult;
      }
    } else if (typeof TOTAL_RESULTS_PARAMETER == 'number') {
      totalResult = TOTAL_RESULTS_PARAMETER;
    }
    // setea título y descr.
    this.titleService.setTitle(title);
    this.metaTagService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: predescription + ' ' + totalResult + ' ' + description,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: predescription + ' ' + totalResult + ' ' + description,
    });
    if (keywords) {
      this.metaTagService.updateTag({
        name: 'keywords',
        content: keywords,
      });
    }
  }

  cardPropertyChange(e: string): void {
    switch (e) {
      case QrCardPropertyComponent.CARD_EVENT_OPEN_DIALOG_CREATE_ACCOUNT:
        if (this.statusCardPropMapMode.isVisible) {
          this.cardPropertyChange(
            QrCardPropertyComponent.CARD_EVENT_CLOSE_CARD
          );
        }
        this.openDialogCreateAccount();
        break;
      case QrCardPropertyComponent.CARD_EVENT_CLOSE_CARD:
        this.statusCardPropMapMode.isVisible = false;
        break;
    }
  }

  openDialogCreateAccount(): void {
    this.dialogCreateAccount = {
      ...this.dialogCreateAccount,
      isDialogOpen: true,
    };
  }

  private async initializeState(): Promise<void> {
    const PARAMS = this.addDefaultParamsByRoute(
      this.route.snapshot.queryParams,
      this.route.snapshot.url[0]?.path || ''
    );
    this.searchDomain.queryParams2filter(PARAMS);
    await this.searchDomain.newApiFindEntrepreneurship(false);
    // this.searchDomain.loadState2QrPageListingAndEntrepreneurship(null);
  }

  private handleLoader(): void {
    this.searchDomain.isResultsLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isResultsLoading: boolean) => {
        if (!isResultsLoading) {
          this.isResultsLoading = false;
        }
      });
    this.searchDomain.listingAndEntrepreneurshipMapLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMapResultsLoading: boolean) => {
        if (!isMapResultsLoading) {
          this.refreshMarkers();
          this.isMapResultsLoading = false;
        }
      });
  }

  private refreshMarkers(): void {
    const TEMP_COORDS: MarkerMap[] = [];

    const FORMAT_LABEL = (
      currency: { id: number; value: string } | null,
      price: number
    ): string => {
      return `${currency?.value} ${this.utilsService.formatPriceOnMap(price)}`;
    };

    this.searchDomain.listingAndEntrepreneurshipMap.forEach(
      (resultMap: Maps) => {
        const LOCATION: LocationM | null = resultMap.location;
        if (LOCATION) {
          const PRICE: number | null = resultMap.entrepreneurship
            ? resultMap.eminPrice
            : resultMap.price;
          const CURRENCY: {
            id: number;
            value: string;
          } | null = resultMap.entrepreneurship
            ? resultMap.ecurrency
            : resultMap.currency;
          TEMP_COORDS.push({
            lng: resultMap.location?.coordinates[0],
            lat: resultMap.location?.coordinates[1],
            id: resultMap.id.toString(),
            icon: 'apartments-in-building',
            label: FORMAT_LABEL(CURRENCY, PRICE || 0),
            listingId: resultMap.listingId,
            entrepreneurship: resultMap.entrepreneurship,
          });
        }
      }
    );

    this.mapCenter =
      TEMP_COORDS.length > 0
        ? { lat: TEMP_COORDS[0].lat, lng: TEMP_COORDS[0].lng }
        : Constants.DEFAULT_CENTER_COORDS;
    this.coords = [...TEMP_COORDS];
  }
}
