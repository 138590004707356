import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { Constants } from '@base/src/app/core/constants/constants';
import { QrFiltersListComponent } from '../dialogs-content/qr-filters-list/qr-filters-list.component';
import { QrDialogMoreThanMovingComponent } from '../dialogs-content/qr-dialog-more-than-moving/qr-dialog-more-than-moving.component';
import { QrPropertyTypeHomeComponent } from '../dialogs-content/qr-property-type-home/qr-property-type-home.component';
import { InvitationToRegisterComponent } from '../dialogs-content/invitation-to-register/invitation-to-register.component';
import {
  IButtonConfig,
  QrButtonComponent,
} from '../qr-button/qr-button.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { QrIconButtonComponent } from '../qr-icon-button/qr-icon-button.component';
import { Subject } from 'rxjs';
import { DeleteSaveSearchComponent } from '../../pages/public/user-web/saved-search/components/delete-save/delete-save.component';

export interface QRDialogConfig {
  isDialogOpen: boolean; // Define si el diálogo esta abierto o cerrado
  configButtonLeft?: IButtonConfig; // Configuración del botón izquierdo de la barra inferior
  configButtonRight?: IButtonConfig; // Configuración del botón derecho de la barra inferior
  dinamicComponent: string; // Nombre del componente a cargar dinamicamente
  isTopbarVisible: boolean; // Define si se muestra la topbar o no
  backgroundImage?: string[]; // Variable opcional para mandar fotos
  titleFilter?: string; // Variable para darle titulo al dialogo
  isBotbarVisible?: boolean;
  isEntrepreneurship?: boolean; // Variable para saber si estamos en emprendimientos o listings
  fixedHeight?: number; // Definir un alto fijo (Solo aplica para tamaños de disposito de desktop, mayor a 960px de ancho)
  isHeightAutoFitContent?: boolean; // Define si el alto del diálogo se ajusta automáticamente al alto del componente dinámico (Solo aplica para tamaños de disposito de desktop, mayor a 960px de ancho)
  fixedWidth?: number; // Definir un ancho fijo (Solo aplica para tamaños de disposito de desktop, mayor a 960px de ancho)
  isWidthAutoFitContent?: boolean; // Define si el ancho del diálogo se ajusta automáticamente al ancho del componente dinámico (Solo aplica para tamaños de disposito de desktop, mayor a 960px de ancho)
}

@Component({
  selector: 'qr-dialog',
  templateUrl: './qr-dialog.component.html',
  styleUrls: ['./qr-dialog.component.scss'],
  standalone: true,
  imports: [
    QrButtonComponent,
    CommonModule,
    QrIconButtonComponent,
    L10nTranslationModule,
  ],
})
export class QrDialogComponent implements OnChanges, OnDestroy {
  @Input() data!: QRDialogConfig; // Data que viene del componente padre

  isDialogOpen = false; // Define si el diálogo esta abierto o cerrado
  configButtonLeft: any; // Configuración del botón izquierdo de la barra inferior
  configButtonRight: any; // Configuración del botón derecho de la barra inferior

  @Output() onButtonLeft = new EventEmitter<any>(); // Evento para el componente padre: al clickear botón izquierdo
  @Output() onButtonRight = new EventEmitter<any>(); // Evento para el componente padre: al clickear botón derecho

  // Componente dinamico
  dinamicComponent: string = ''; // Nombre del componente a cargar dinamicamente
  @ViewChild('dinamicComponent', { read: ViewContainerRef })
  target: ViewContainerRef | null = null;

  dataFromDinamicComponente: any;
  // variable que guarda la data que viene del componente dinamico hijo que luego se manda al padre
  isTopbarVisible: boolean = false;
  isBotbarVisible?: boolean = true;
  configButtonClose = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  // Topbar: Botón: Cerrar
  heightClasses = '';
  heightValue: string = '';
  widthValue: string = '';
  isDesktopWidth: boolean | null = isPlatformBrowser(this.platformId)
    ? window.innerWidth > 960
    : null;

  private componentRef: ComponentRef<any> | null = null;
  private destroy$: Subject<void> = new Subject<void>();

  // Eventos de teclado
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key == 'Escape' && this.isDialogOpen == true) {
      // Tecla ESC: Cierra Diálogo
      this.close();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isDesktopWidth = window.innerWidth > 960;
      this.defineSizes();
    }
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private cdRef: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  /*
	ngOnChanges: Detecta que hubo un cambio en el componente
	*/
  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.isDialogOpen = this.data.isDialogOpen;
      this.configButtonLeft = this.data.configButtonLeft;
      this.configButtonRight = this.data.configButtonRight;
      this.dinamicComponent = this.data.dinamicComponent;
      this.isTopbarVisible = this.data.isTopbarVisible;
      if (this.isDialogOpen) this.manageDinamicContent();
      if (
        this.data.isBotbarVisible != null ||
        this.data.isBotbarVisible != undefined
      ) {
        this.isBotbarVisible = this.data.isBotbarVisible;
      }
      if (changes['data'].currentValue.isDialogOpen) {
        this.defineSizes();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  manageDinamicContent(): void {
    switch (this.dinamicComponent) {
      case 'save-search-delete':
        this.addComponentDynamically(DeleteSaveSearchComponent);
        break;

      // Diálogo: Tipo de propiedad (Home)
      case 'property-type-home':
        this.addComponentDynamically(QrPropertyTypeHomeComponent);
        break;

      // Diálogo: Filtros (Listado de resultados)
      case 'filters-list':
        this.addComponentDynamically(QrFiltersListComponent);
        break;

      // Diálogo: More than moving
      case 'more-than-moving':
        this.addComponentDynamically(QrDialogMoreThanMovingComponent);
        break;

      case 'invitation-to-register':
        this.addComponentDynamically(InvitationToRegisterComponent);
        break;

      default:
        break;
    }
  }

  close(): void {
    this.isDialogOpen = false;
    this.data.isDialogOpen = false;
  }

  defineSizes(): void {
    if (!this.data?.fixedHeight && !this.data?.isHeightAutoFitContent) {
      if (!this.data?.isTopbarVisible || !this.data?.isBotbarVisible) {
        this.heightClasses += ' fullHeight';
      }
      if (this.data?.titleFilter) {
        this.heightClasses += ' no-max-height';
      }
    }

    if (this.isDesktopWidth) {
      if (this.data?.fixedHeight) {
        this.heightValue = this.data?.fixedHeight + 'px';
      }
      if (this.data?.isHeightAutoFitContent) {
        this.heightValue = 'fit-content';
      }
      if (this.data?.fixedWidth) {
        this.widthValue = this.data?.fixedWidth + 'px';
      }
      if (this.data?.isWidthAutoFitContent) {
        this.widthValue = 'fit-content';
      }
    } else {
      this.heightValue = '';
      this.widthValue = '';
    }
  }

  /*
  addComponentDynamically: Agrega el componente de forma dinámica
  */
  addComponentDynamically(componentToAdd: any): void {
    this.cdRef.detectChanges(); // Ejecutar detección de cambios inmediatamente
    this.target?.clear(); // Limpiar contenedor de componentes dinámicos
    const childComponent =
      this.componentFactoryResolver.resolveComponentFactory(componentToAdd);
    this.componentRef = this.target?.createComponent(childComponent) || null;

    /* --------------------------------------------------------*/
    /* Comunicación entre componentes padres e hijos */
    /* --------------------------------------------------------*/

    // Diálogo: Filtros (Listado de resultados)
    if (this.dinamicComponent == 'filters-list' && this.componentRef) {
      // Enviar data a la variable "dataFromParent" del child dinámico
      this.componentRef.instance.dataFromParent = this.data;
      // Escuchar el @Output "dataForParent" del child dinámico
      this.componentRef.instance.dataForParent.subscribe(
        (dataFromChild: any) => {
          if (dataFromChild) {
            this.dataFromDinamicComponente = dataFromChild;
          }
        }
      );
    }

    if (this.dinamicComponent == 'more-than-moving' && this.componentRef) {
      // Enviar data a la variable "dataFromParent" del child dinámico
      this.componentRef.instance.dataFromParent = this.data;
      // Escuchar el @Output "dataForParent" del child dinámico
      this.componentRef.instance.dataForParent.subscribe(
        (dataFromChild: any) => {
          if (dataFromChild) {
            this.dataFromDinamicComponente = dataFromChild;
          }
        }
      );
    }
  }

  leftButton(): void {
    if (this.configButtonLeft.text === 'Cancelar') {
      this.close();
    } else {
      this.onButtonLeft.emit(this.dataFromDinamicComponente);
      if (this.dinamicComponent === 'filters-list') {
        this.componentRef?.instance.cleanFilters();
      }
    }
  }

  rightButton(): void {
    this.onButtonRight.emit(this.dataFromDinamicComponente);

    if (this.dinamicComponent == 'save-search-delete') {
      this.close();
    }
  }
}
