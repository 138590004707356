<div class="content">
  <div class="field">
    <div class="field__name">
      <label class="field__label" for="name">Nombre de tu búsqueda</label>
    </div>
    <div class="field__content">
      <qr-input
        [config]="INPUT_NAME"
        [(value)]="inputNameValue"
        (valueChange)="inputNameChange($event)"></qr-input>
    </div>
  </div>
  <div class="field">
    <div class="field__name">
      <label class="field__label" for="alert">Frecuencia de alerta</label>
    </div>
    <div class="field__content">
      <p class="field__text">
        Recibí las notificaciones de tu búsqueda, según la frecuencia de alerta
        que prefieras.
      </p>
      <qr-dropdown
        class="field__dropdown-alert"
        [config]="DROPDOWN_ALERT"
        [selected]="dropdownAlertSelected"
        (onChange)="dropdownAlertChange($event)"></qr-dropdown>
    </div>
  </div>
  <p class="footer">
    Podés ver y editar tus búsquedas desde tu perfil, ingresando a
    <a class="footer__link" href="/busquedas">búsquedas y alertas</a>
  </p>
</div>
