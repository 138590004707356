import { Injectable } from '@angular/core';
import { Alerts } from '@base/src/app/core/enum/Alerts';

@Injectable({ providedIn: 'root' })
export class SaveSearchsUtilsService {
  /**
   * Obtiene el índice/ID basado en el string de una alerta del enum Alerts.
   * Si la opción proporcionada es inválida, devuelve 1 como valor por defecto.
   *
   * @param optionSelected - La alerta seleccionada como cadena de texto.
   * @returns El índice/ID de la alerta o 1 si la opción es inválida.
   */
  public static getAlertID(optionSelected: string): number {
    if (!optionSelected || typeof optionSelected !== 'string') {
      throw new Error('Invalid optionSelected provided');
    }
    const ALERT_KEYS: readonly string[] = Object.values(Alerts);
    const POSITION: number = ALERT_KEYS.indexOf(optionSelected);
    return POSITION >= 0 ? POSITION + 1 : 1; // Devuelve 1 para opciones invalidas
  }

  /**
   * Corrige el formato de una query string aplicando un formato específico.
   *
   * Cambia las coincidencias del formato `in=field:value` a `in:field=value`.
   *
   * Ejemplo:
   * Entrada: "in=name:JohnDoe,eq=age:30"
   * Salida: "in:name=JohnDoe,eq:age=30"
   *
   * @param query - La query string que necesita ser formateada.
   * @returns La query string con el formato corregido.
   */
  public static correctQueryFormat(query: string): string {
    const REGEX: RegExp = /(in|eq|lte|gte)=([a-zA-Z]+):([\w\d,]+)/g;
    const FORMAT_QUERY: string = query.replace(
      REGEX,
      (match, prefix, field, value) => {
        return `${prefix}:${field}=${value}`;
      }
    );
    return FORMAT_QUERY;
  }

  /**
   * Obtiene el nombre de la alerta basado en el ID proporcionado.
   *
   * Verifica si el valor de typeAlert es válido (dentro del rango de las alertas definidas).
   * Si es válido, retorna el nombre de la alerta correspondiente. Si no es válido, lanza un error.
   *
   * @param typeAlert - El ID de la alerta, debe ser un número entero mayor que 0 y menor o igual al número total de alertas.
   * @returns El nombre de la alerta correspondiente al ID.
   * @throws Error si typeAlert está fuera de los límites válidos.
   */

  public static getAlertNameByID(typeAlert: number): string {
    if (typeAlert < 1 || typeAlert > Object.values(Alerts).length) {
      throw new Error('El valor de typeAlert no es válido');
    }
    const ALERTS: Alerts[] = Object.values(Alerts) as Alerts[];
    return ALERTS[typeAlert - 1];
  }

  /**
   * Esta función reemplaza los operadores 'in:', 'gte:', 'lte:' y 'eq:' en una cadena de texto.
   * Para cada coincidencia de la forma 'operador:parametro=valor', reemplaza el ':' de adelante por "=" y
   * cambia el signo '=' por un dos puntos ':' en la parte del valor.
   * Ejemplo: 'in:operationId=1' se convierte en 'in=operationId:1'.
   *
   * @param input - Cadena de texto que contiene la URL o consulta a modificar.
   * @returns Nueva cadena con los operadores y valores transformados.
   */
  public static replaceOperators(input: string): string {
    return input.replace(
      /(in:|gte:|lte:|eq:)([\w\d]+)=([\w\d,]+)/g,
      (match, operator, param, value) => {
        return `${operator.slice(0, -1)}=${param}:${value}`;
      }
    );
  }
}
