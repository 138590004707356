export class QRButton {
  id?: number | string;
  hierarchy!: string;
  size!: number;
  value?: string;
  icon?: string;
  iconOnly?: boolean = false;
  iconTooltipOnly?: string; // Para botones redondos en donde solo se ve el icono sin texto
  ariaLabel?: string;
  type?: string = QRButton.TYPE_BUTTON;

  // Hierarchy
  public static readonly HIERARCHY_PRIMARY_SOLID: string =
    'button--primary-solid';

  public static readonly HIERARCHY_PRIMARY_BORDER: string =
    'button--primary-border';

  public static readonly HIERARCHY_TONAL_BORDER: string =
    'button--tonal-border';

  public static readonly HIERARCHY_TONAL: string = 'button--tonal';
  public static readonly HIERARCHY_GREY_BORDER: string = 'button--grey-border';
  public static readonly HIERARCHY_GREY: string = 'button--grey';
  public static readonly HIERARCHY_GREEN_SOLID: string = 'button--green-solid';

  // Size
  public static readonly SIZE_48_PX: number = 48;
  public static readonly SIZE_40_PX: number = 40;
  public static readonly SIZE_32_PX: number = 32;

  // Type
  public static readonly TYPE_BUTTON: string = 'button';
  public static readonly TYPE_SUBMIT: string = 'submit';
}
