import { Component, Input, OnInit } from '@angular/core';
import { PictureSource } from '@app/core/models/picture-source';
import { environment } from '@base/environments/environment';
import { imageFix } from '@app/core/models/image-fix.model';
import { Constants } from '@app/core/models/constants.model';

@Component({
  selector: 'picture-source',
  templateUrl: './picture-source.component.html',
  styleUrls: ['./picture-source.component.scss'],
  standalone: true,
  imports: [],
})
export class PictureSourceComponent implements OnInit {
  @Input() config!: PictureSource;
  @Input() src!: string;
  @Input() alt!: string;

  imageHasError = false;
  readonly imageCloudfront: string = environment.imageCloudfront;
  public isLazyLoading: any;

  ngOnInit(): void {
    this.isLazyLoading = this.config.loading === PictureSource.LOADING_LAZY;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  errorImg(event: any): void {
    switch (this.config.text) {
      case Constants.FOLDER_LISTINGS:
      case Constants.FOLDER_OFFICES:
        this.imageHasError = true;
        event.target.src = imageFix.property; //'/assets/images/listings/property-photo-not-found.svg';
        break;

      default:
        this.imageHasError = true;
        event.target.src = imageFix.person; //'/assets/images/listings/property-photo-not-found.svg';
        break;
    }
  }
}
