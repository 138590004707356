import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { QRInputAutocomplete } from '@app/core/models/qr-input-autocomplete.model';
import { QRInputComponent } from '../qr-input/qr-input.component';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'qr-input-autocomplete',
  templateUrl: './qr-input-autocomplete.component.html',
  styleUrls: ['./qr-input-autocomplete.component.scss'],
  standalone: true,
  imports: [QRInputComponent, MatRippleModule],
})
export class QRInputAutocompleteComponent implements OnInit {
  @Input() config?: QRInputAutocomplete;
  @Input() isDisabled?: boolean = false;
  @Input() hasError?: boolean = false;
  @Output() selectedChange: EventEmitter<string | undefined> = new EventEmitter<
    string | undefined
  >();

  filteredOptions: string[] = [];
  isOptionsVisible: boolean = false;
  selectedOption: string | number | undefined;

  constructor(private _eref: ElementRef) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @HostListener('document:click', ['$event', '$event.target'])
  onDocumentClicked(event: MouseEvent, targetElement: HTMLElement) {
    if (
      targetElement &&
      document.body.contains(targetElement) &&
      !this._eref.nativeElement.contains(targetElement)
    ) {
      this.isOptionsVisible = false;
    }
  }

  ngOnInit(): void {
    this.isOptionsVisible = false;
    this.filteredOptions = this.config?.options ?? [];
  }

  public inputChange(e: string | number | undefined): void {
    const VALUE: string | undefined = e?.toString();
    if (VALUE != undefined) {
      const FILTERED: string[] | undefined = this.config?.options.filter(o =>
        o.toLowerCase().includes(VALUE.toString().toLowerCase())
      );
      if (FILTERED) {
        this.filteredOptions = FILTERED;
      }
    } else {
      this.filteredOptions = this.config?.options ?? [];
      this.selectedChange.emit(undefined);
    }
  }

  public showOptions(): void {
    if (this.isDisabled == false) {
      this.isOptionsVisible = true;
    }
  }

  public select(option: string): void {
    this.isOptionsVisible = false;
    this.selectedOption = option;
    this.selectedChange.emit(this.selectedOption);
  }
}
