<div class="section">
  <div class="cover">
    <img
      class="cover__icon"
      src="../../../assets/icons/email-outlined.svg"
      alt="Email" />
    <img
      class="cover__icon"
      src="../../../assets/icons/toggle-off.svg"
      alt="Toggle off" />
  </div>
  <div class="unsubscribe">
    <div class="unsubscribe__text">
      <p class="unsubscribe__title">
        Darse de baja de tu <span>alerta</span> de búsqueda @if (searchName) {
        <span>“{{ searchName }}”</span>. }
      </p>
      <p class="unsubscribe__subtitle">
        Al hacer clic en “Sí, dar de baja”, dejarás de recibir correos
        electrónicos relacionadas con esta búsqueda.
      </p>
    </div>
    <qr-button
      class="unsubscribe__button-unsubscribe"
      [config]="BUTTON_UNSUBSCRIBE"
      (click)="unsubscribe()"></qr-button>
  </div>
</div>
