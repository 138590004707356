<div class="search-card">
  <div class="search-card__top">
    <div class="search-card__info">
      <h3 class="search-card__title">{{ savedSearch.name }}</h3>
      <p class="search-card__date">
        Guardado el
        {{ savedSearch.createdAt | date: 'dd/MM/yyyy' }}
      </p>
    </div>
    <div class="search-card__actions">
      <ng-container *ngTemplateOutlet="buttonGoToSearch"></ng-container>
    </div>
  </div>
  <div class="search-card__content">
    <div class="search-card__tags">
      @for (tag of savedSearch.tags; track tag) {
        <qr-tag-x [config]="TAG_PRIMARY_BORDER">{{ tag }}</qr-tag-x>
      }
    </div>
    <div class="search-card__buttons">
      <div class="search-card__row">
        <div class="search-card__column">
          <p class="search-card__label">Frecuencia alerta</p>
          <qr-dropdown
            class="search-card__dropdown-alert"
            [config]="DROPDOWN_ALERT"
            [selected]="
              (savedSearch.typeAlert | alertName) ?? dropdownAlertSelected
            "
            (onChange)="dropdownAlertChange($event)"></qr-dropdown>
        </div>
        <div class="search-card__edit-and-delete">
          <qr-button [config]="BUTTON_EDIT" (click)="edit()"></qr-button>
          <qr-button [config]="BUTTON_DELETE" (click)="delete()"></qr-button>
        </div>
      </div>
      <div class="search-card__row">
        <ng-container
          *ngTemplateOutlet="
            buttonGoToSearch;
            context: { class: 'search-card__button-go-to-search-link--mobile' }
          "></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #buttonGoToSearch let-className="class">
  <a
    class="search-card__button-go-to-search-link {{ className }}"
    [href]="savedSearch.formattedUrl">
    <qr-button
      [config]="BUTTON_GO_TO_SEARCH"
      class="search-card__button-go-to-search"></qr-button> </a
></ng-template>
