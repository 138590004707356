import { Pipe, PipeTransform } from '@angular/core';
import { Alerts } from '@base/src/app/core/enum/Alerts';

@Pipe({
  name: 'alertName',
  standalone: true,
})
export class AlertNamePipe implements PipeTransform {
  transform(index: number): string {
    const ALERTS: Alerts[] = Object.values(Alerts);
    const ALERT: Alerts = ALERTS[index - 1];
    return ALERT;
  }
}
