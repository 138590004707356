<div class="sell">
  @if (!isFormCorrectlySent) {
    <div class="form" [formGroup]="formGroup">
      <div class="form__title">
        <h2 class="form__heading">
          {{ 'sell.title' | translate: locale.language }}
        </h2>
        <p class="form__description">
          {{ 'sell.subtitle' | translate: locale.language }}
        </p>
      </div>
      <form class="form__wrapper" (ngSubmit)="submit()">
        <qr-input
          class="form__input"
          [config]="TEXT_FULLNAME"
          (blurChange)="formGroup.get('fullName')?.markAsTouched()"
          [hasError]="
            formGroup.get('fullName')?.touched &&
            formGroup.get('fullName')?.invalid
              ? true
              : false
          "
          (valueChange)="
            formGroup.get('fullName')?.setValue($event)
          "></qr-input>
        <qr-input
          class="form__input"
          [config]="TEXT_EMAIL"
          (blurChange)="formGroup.get('email')?.markAsTouched()"
          [hasError]="
            formGroup.get('email')?.touched && formGroup.get('email')?.invalid
              ? true
              : false
          "
          (valueChange)="formGroup.get('email')?.setValue($event)"></qr-input>
        <qr-input
          class="form__input"
          [config]="TEXT_PHONE"
          (blurChange)="formGroup.get('phone')?.markAsTouched()"
          [hasError]="
            formGroup.get('phone')?.touched && formGroup.get('phone')?.invalid
              ? true
              : false
          "
          (valueChange)="formGroup.get('phone')?.setValue($event)"></qr-input>
        @defer (on idle) {
          <div class="form__field">
            <label class="form__label" for="location">
              {{ 'sell.location-field' | translate: locale.language }}</label
            >
            <qr-google-autocomplete
              [required]="true"
              (blurChange)="formGroup.get('location')?.markAsTouched()"
              (onChange)="formGroup.get('location')?.setValue($event)"
              [hasError]="
                (formGroup.get('location')?.touched &&
                  formGroup.get('location')?.invalid) ??
                false
              "></qr-google-autocomplete>
          </div>
        } @placeholder {
          <div class="form__field">
            <skeleton-loader class="form__loader-label"></skeleton-loader>
            <skeleton-loader class="form__loader-input"></skeleton-loader>
          </div>
        }

        <div class="form__field">
          <label class="form__label" for="propertyType"
            >Tipo de propiedad</label
          >
          <qr-dropdown
            class="form__dropdown"
            [config]="dropdownPropertyType"
            (selectedChange)="formGroup.get('propertyType')?.setValue($event)"
            (blurChange)="formGroup.get('propertyType')?.markAsTouched()"
            [hasError]="
              (formGroup.get('propertyType')?.touched &&
                formGroup.get('propertyType')?.invalid) ??
              false
            "
            (onChange)="dropdownPropertyTypeChange($event)"></qr-dropdown>
          @if (
            (formGroup.get('propertyType')?.touched &&
              formGroup.get('propertyType')?.invalid) ??
            false
          ) {
            <small class="form__error">Seleccione un tipo de propiedad</small>
          }
        </div>
        @if (isRoomsDropdownVisible) {
          <div class="form__field">
            <label class="form__label" for="propertyType">Ambientes</label>
            <qr-dropdown
              class="form__dropdown"
              [config]="DROPDOWN_ROOMS"
              (selectedChange)="formGroup.get('rooms')?.setValue($event)"
              (blurChange)="formGroup.get('rooms')?.markAsTouched()"
              [hasError]="
                (formGroup.get('rooms')?.touched &&
                  formGroup.get('rooms')?.invalid) ??
                false
              "
              (onChange)="dropdownRoomsChange($event)"></qr-dropdown>
            @if (
              (formGroup.get('rooms')?.touched &&
                formGroup.get('rooms')?.invalid) ??
              false
            ) {
              <small class="form__error">Seleccione ambientes</small>
            }
          </div>
        }

        <qr-textarea
          class="form__textarea"
          [config]="textareaComments"
          (blurChange)="formGroup.get('comments')?.markAsTouched()"
          [hasError]="
            (formGroup.get('comments')?.touched &&
              formGroup.get('comments')?.invalid) ??
            false
          "
          (textareaChange)="textareaCommentsChange($event)"></qr-textarea>

        @if (!isSubmitLoading) {
          <qr-button
            class="form__button-submit"
            [config]="BUTTON_SUBMIT"
            (click)="submit()"
            [isDisabled]="!formGroup.valid"></qr-button>
        } @else {
          <div class="form__loader-button">
            <mat-spinner diameter="24" color="white"></mat-spinner>
          </div>
        }

        <p class="form__footer">
          Al enviar se están aceptando los
          <a href="/terminos-y-condiciones" target="blank"
            >Términos y Condiciones de Uso</a
          >
          y la
          <a href="/politica-de-privacidad" target="blank"
            >Política de Privacidad.</a
          >
        </p>
      </form>
    </div>
  } @else {
    <div class="form form--sent">
      <div class="form__check">
        <qr-icon [config]="ICON_CHECK" class="form__check-icon"></qr-icon>
      </div>
      <div class="form__title">
        <h2 class="form__heading">
          {{ 'sell.form-correctly-sent-title' | translate: locale.language }}
        </h2>
        <p class="form__description">
          {{ 'sell.form-correctly-sent' | translate: locale.language }}
        </p>
      </div>
    </div>
  }
  <div class="sell__image sell__image--{{ NODE }}"></div>
</div>
