<div class="unsubscribe">
  <div class="unsubscribe__content">
    <div class="unsubscribe__icon">
      <div class="unsubscribe__icon-check"></div>
    </div>
    <p class="unsubscribe__message">
      Te has <span>dado de baja exitosamente</span> de tu alerta de búsqueda. Ya
      no recibirás notificaciones relacionadas con esta búsqueda.
    </p>
  </div>
  <div class="unsubscribe__cta">
    <h2 class="unsubscribe__cta-title">
      {{ 'unsubscribe-alert.title' | translate: locale.language }}
    </h2>
    <p class="unsubscribe__cta-text">
      {{ 'unsubscribe-alert.text' | translate: locale.language }}
    </p>
    <a href="/comprar-propiedades">
      <qr-button
        class="unsubscribe__cta-button"
        [config]="BUTTON_SEARCH"></qr-button>
    </a>
  </div>
</div>
