import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   * Muestra un mensaje en la parte inferior de la pantalla utilizando MatSnackBar.
   *
   * @param message - El mensaje que se mostrará en el SnackBar.
   * @param duration - La duración en milisegundos por la cual se debe mostrar el mensaje. El valor por defecto es 3000 ms.
   * @param actionButtonText - El texto que se mostrará en el botón. Por defecto es 'Cerrar'.
   * @param onActionCallback - Una función opcional que se ejecutará si se presiona el botón de acción.
   */
  public showSnackBar(
    message: string,
    duration: number = 3000,
    actionButtonText: string = 'Cerrar',
    onActionCallback?: () => void
  ): void {
    const snackBarRef: MatSnackBarRef<any> = this.snackBar.open(
      message,
      actionButtonText,
      {
        duration: duration,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      }
    );

    // Detecta cuando el botón de acción es presionado y se desuscribe automáticamente
    if (onActionCallback && actionButtonText) {
      snackBarRef
        .onAction()
        .pipe(take(1))
        .subscribe(() => {
          onActionCallback();
        });
    }
  }
}
