import { Component, Inject } from '@angular/core';
import { QRButtonComponent } from '@base/src/app/components/ui/qr-button/qr-button.component';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRIcon } from '@base/src/app/core/models/qr-icon.model';
import { L10N_LOCALE, L10nLocale, L10nTranslationModule } from 'angular-l10n';

@Component({
  selector: 'app-unsubscribed-successfully',
  templateUrl: './unsubscribed-successfully.page.html',
  styleUrls: ['./unsubscribed-successfully.page.scss'],
  standalone: true,
  imports: [QRButtonComponent, L10nTranslationModule],
})
export class UnsubscribedSucessfullyPage {
  public readonly BUTTON_SEARCH: QRButton = {
    id: 'button-search',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Buscar propiedades',
    icon: QRIcon.NAME_SEARCH,
  };

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}
}
