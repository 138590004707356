import { Component } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-menu-qr-comps',
  standalone: true,
  imports: [MatRippleModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuQRCompsComponent {
  public scrollToSection(sectionId: string): void {
    const SECTION: HTMLElement | null = document.getElementById(sectionId);
    if (SECTION) {
      const top = SECTION.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: top,
        behavior: 'smooth',
      });
    }
  }
}
