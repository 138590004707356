<footer [ngClass]="{ 'footer-collection': isCollection }">
  <div id="footer-wrapper">
    <!-- Columna 1 -->
    <div class="footer-column" [ngClass]="{ 'flex-footer': node === 'ec' }">
      <qr-media-img id="logo" [config]="logoREMAXBalloonSVG"></qr-media-img>
      <ul [ngClass]="{ 'horizontal-footer': node === 'ec' }">
        <li>
          <a
            href="/listings/buy?page=0&pageSize=21&sort=%2Bid&in:operationId=1,2"
            role="button"
            [title]="'footer.properties' | translate: locale.language"
            >{{ 'footer.properties' | translate: locale.language }}</a
          >
        </li>
        <li>
          <a
            href="/comprar-propiedades"
            role="button"
            [title]="'footer.buy' | translate: locale.language"
            >{{ 'footer.buy' | translate: locale.language }}</a
          >
        </li>
        <li>
          <a
            href="/vender"
            role="button"
            [title]="'footer.sell' | translate: locale.language"
            >{{ 'footer.sell' | translate: locale.language }}</a
          >
        </li>
        <li>
          <a
            href="/alquilar-propiedades"
            role="button"
            [title]="'footer.rent' | translate: locale.language"
            >{{ 'footer.rent' | translate: locale.language }}</a
          >
        </li>

        <li>
          <a
            [href]="this.urlFranchiseGlobal"
            target="_blank"
            role="button"
            [title]="'footer.franchise' | translate: locale.language"
            >{{ 'footer.franchise' | translate: locale.language }}</a
          >
        </li>

        @if (node === 'ar' || node === 'uy') {
          <li>
            <a
              href="/inmuebles"
              target="_blank"
              role="button"
              [title]="'footer.estate' | translate: locale.language"
              >{{ 'footer.estate' | translate: locale.language }}</a
            >
          </li>
        }
        @if (node === 'ar') {
          <li>
            <a
              href="/oficinas"
              target="_blank"
              role="button"
              [title]="'footer.offices' | translate: locale.language"
              >{{ 'footer.offices' | translate: locale.language }}</a
            >
          </li>
        }
        @if (node === 'uy') {
          <li>
            <a
              href="/locales-comerciales"
              target="_blank"
              role="button"
              [title]="'footer.commercial_store' | translate: locale.language"
              >{{ 'footer.commercial_store' | translate: locale.language }}</a
            >
          </li>
        }
        <li>
          <a
            href="/be-agent"
            role="button"
            [title]="'footer.be_remax_agent' | translate: locale.language"
            >{{ 'footer.be_remax_agent' | translate: locale.language }}</a
          >
        </li>
        @if (this.node === 'ar' || this.node === 'uy') {
          <li>
            <a
              href="https://blog.remax.com.ar/"
              target="_blank"
              role="button"
              [title]="'footer.blog' | translate: locale.language"
              >{{ 'footer.blog' | translate: locale.language }}</a
            >
          </li>
        }
        @if (this.node === 'ar') {
          <li>
            <a
              href="/indice-remax"
              role="button"
              [title]="'footer.index' | translate: locale.language"
              >{{ 'footer.index' | translate: locale.language }}</a
            >
          </li>
        }
        <li>
          <a
            href="/contacto"
            role="button"
            [title]="'footer.contact' | translate: locale.language"
            >{{ 'footer.contact' | translate: locale.language }}</a
          >
        </li>
        <li>
          <a
            href="/terminos-y-condiciones"
            role="button"
            [title]="'footer.terms' | translate: locale.language"
            >{{ 'footer.terms' | translate: locale.language }}</a
          >
        </li>
        <li>
          <a
            href="/politica-de-privacidad"
            role="button"
            [title]="'footer.policy' | translate: locale.language"
            >{{ 'footer.policy' | translate: locale.language }}</a
          >
        </li>
      </ul>
    </div>
    <!-- Encontrá más propiedades -->
    @if (node !== 'ec') {
      <div class="footer-column">
        <div class="title-column-container">
          <span class="title-column">
            {{ 'footer.find_more_properties' | translate: locale.language }}
          </span>
        </div>
        @if (node === 'ar') {
          <div id="find-more-properties" class="links-list">
            <ul>
              <li>
                <a
                  href="/propiedades-en-la-plata"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} La Plata"
                  >{{ 'footer.properties_on' | translate: locale.language }} La
                  Plata</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-santa-fe"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Santa Fé"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Santa Fé</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-mendoza"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Mendoza"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Mendoza</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-bariloche"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Bariloche"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Bariloche</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-salta"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Salta"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Salta</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-pilar"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Pilar"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Pilar</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-mar-del-plata"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Mar del Plata"
                  >{{ 'footer.properties_on' | translate: locale.language }} Mar
                  del Plata</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-palermo"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Palermo"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Palermo</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-rosario"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Rosario"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Rosario</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-nordelta"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Nordelta"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Nordelta</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-caballito"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Caballito"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Caballito</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-belgrano"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Belgrano"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Belgrano</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-san-isidro"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} San Isidro"
                  >{{ 'footer.properties_on' | translate: locale.language }} San
                  Isidro</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-tigre"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Tigre"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Tigre</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-ciudad-de-buenos-aires"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} CABA"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  CABA</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-vicente-lopez"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Vicente López"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Vicente López</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-cordoba"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Córdoba"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Córdoba</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-neuquen"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Neuquén"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Neuquén</a
                >
              </li>
              <!-- <li> ***** ⛔ COMENTADO HASTA QUE PASEN LAS IMÁGENES - NO BORRAR ⛔ *****
                                                                              <a
                                                                                href="/propiedades-en-posadas"
                                                                                role="button"
							title="{{
								'footer.properties_on'
									| translate: locale.language
							}} Posadas"
                                                                                >{{
                                                                                'footer.properties_on'
                                                                                | translate: locale.language
                                                                                }}
                                                                                Posadas</a
                                                                                >
                                                                              </li> -->
            </ul>
          </div>
        }
        <!-- Botón: Ver más -->
        @if (node === 'ar') {
          <qr-button-with-icon
            [config]="viewMoreFindMoreProperties"
            class="view-more-button"
            (click)="toggleViewLinksList('find-more-properties')">
          </qr-button-with-icon>
        }
        <!-- Uruguay: Encontrá más propiedades + terrenos + alquileres temporarios -->
        @if (node === 'uy') {
          <div id="find-more-properties" class="links-list">
            <ul>
              <li>
                <a
                  href="/propiedades-en-punta-del-este"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Punta del Este"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Punta del Este</a
                >
              </li>
              <li>
                <a
                  href="/propiedades-en-montevideo"
                  role="button"
                  title="{{
                    'footer.properties_on' | translate: locale.language
                  }} Montevideo"
                  >{{
                    'footer.properties_on' | translate: locale.language
                  }}
                  Montevideo</a
                >
              </li>
              <li>
                <a
                  href="/monoambiente"
                  title="{{
                    'footer.properties' | translate: locale.language
                  }} Monoambiente"
                  >{{
                    'footer.properties' | translate: locale.language
                  }}
                  Monoambiente</a
                >
              </li>
            </ul>
          </div>
          <div
            class="title-column-container title-column-container--margin-top">
            <span id="lands-uy-title" class="title-column">
              {{ 'footer.lands' | translate: locale.language }}
            </span>
          </div>
          <div class="links-list">
            <ul>
              <li>
                <a
                  href="/terrenos-en-uruguay"
                  role="button"
                  title="{{
                    'footer.lands_on' | translate: locale.language
                  }} Uruguay"
                  >{{
                    'footer.lands_on' | translate: locale.language
                  }}
                  Uruguay</a
                >
              </li>
              <li>
                <a
                  href="/terrenos-en-maldonado"
                  role="button"
                  title="{{
                    'footer.lands_on' | translate: locale.language
                  }} Maldonado"
                  >{{
                    'footer.lands_on' | translate: locale.language
                  }}
                  Maldonado</a
                >
              </li>
              <li>
                <a
                  href="/terrenos-en-rocha"
                  role="button"
                  title="{{
                    'footer.lands_on' | translate: locale.language
                  }} Rocha"
                  >{{ 'footer.lands_on' | translate: locale.language }} Rocha</a
                >
              </li>
              <li>
                <a
                  href="/terrenos-en-canelones"
                  role="button"
                  title="{{
                    'footer.lands_on' | translate: locale.language
                  }} Canelones"
                  >{{
                    'footer.lands_on' | translate: locale.language
                  }}
                  Canelones</a
                >
              </li>
            </ul>
          </div>
          <div
            class="title-column-container title-column-container--margin-top">
            <span class="title-column">
              {{ 'footer.temporary-rentals' | translate: locale.language }}
            </span>
          </div>
          <div class="links-list">
            <ul>
              <li>
                <a
                  href="/alquileres-temporarios"
                  [title]="
                    ('footer.temporary_rentals_on'
                      | translate: locale.language) + ' Uruguay'
                  "
                  >{{
                    'footer.temporary_rentals_on' | translate: locale.language
                  }}
                  Uruguay</a
                >
              </li>
              <li>
                <a
                  href="/alquileres-temporarios-en-montevideo"
                  [title]="
                    ('footer.temporary_rentals_on'
                      | translate: locale.language) + ' Montevideo'
                  "
                  >{{
                    'footer.temporary_rentals_on' | translate: locale.language
                  }}
                  Montevideo</a
                >
              </li>
              <li>
                <a
                  href="/alquileres-temporarios-en-punta-del-este"
                  [title]="
                    ('footer.seasonal_rentals_on'
                      | translate: locale.language) + ' Punta del Este'
                  "
                  >{{
                    'footer.seasonal_rentals_on' | translate: locale.language
                  }}
                  Punta del Este</a
                >
              </li>
              <li>
                <a
                  href="/alquileres-temporarios-en-piriapolis"
                  [title]="
                    ('footer.seasonal_rentals_on'
                      | translate: locale.language) + ' Piriápolis'
                  "
                  >{{
                    'footer.seasonal_rentals_on' | translate: locale.language
                  }}
                  Piriápolis</a
                >
              </li>
            </ul>
          </div>
        }
      </div>
    }
    <!-- Alquilar -->
    @if (node !== 'ec') {
      <div class="footer-column">
        <div class="title-column-container">
          <span class="title-column">
            {{ 'footer.rent' | translate: locale.language }}
          </span>
        </div>
        @if (node === 'ar') {
          <div id="rent" class="links-list">
            <ul>
              <li>
                <a
                  href="/departamentos-en-alquiler-en-tandil"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} Tandil"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  Tandil</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-alquiler-en-posadas"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} Posadas"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  Posadas</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-alquiler-en-jujuy"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} Jujuy"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  Jujuy</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-alquiler-en-san-juan"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} San Juan"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  San Juan</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-alquiler-en-cordoba"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} Córdoba"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  Córdoba</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-alquiler-en-bariloche"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} Bariloche"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  Bariloche</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-alquiler-en-palermo"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} Palermo"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  Palermo</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-alquiler-en-ciudad-de-buenos-aires"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} CABA"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  CABA</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-alquiler-en-bariloche"
                  role="button"
                  title="{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }} Bariloche"
                  >{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }}
                  Bariloche</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-alquiler-en-tandil"
                  role="button"
                  title="{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }} Tandil"
                  >{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }}
                  Tandil</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-alquiler-en-posadas"
                  role="button"
                  title="{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }} Posadas"
                  >{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }}
                  Posadas</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-alquiler-en-jujuy"
                  role="button"
                  title="{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }} Jujuy"
                  >{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }}
                  Jujuy</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-alquiler-en-san-juan"
                  role="button"
                  title="{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }} San Juan"
                  >{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }}
                  San Juan</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-alquiler-en-cordoba"
                  role="button"
                  title="{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }} Córdoba"
                  >{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }}
                  Córdoba</a
                >
              </li>
            </ul>
          </div>
        }
        <!-- Botón: Ver más -->
        @if (node === 'ar') {
          <qr-button-with-icon
            [config]="viewMoreRent"
            class="view-more-button"
            (click)="toggleViewLinksList('rent')">
          </qr-button-with-icon>
        }
        <!-- Uruguay: Alquilar -->
        @if (node === 'uy') {
          <div id="rent" class="links-list">
            <ul>
              <li>
                <a
                  href="/apartamentos-en-alquiler-en-punta-del-este"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} Punta del Este"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  Punta del Este</a
                >
              </li>
              <li>
                <a
                  href="/apartamentos-en-alquiler-en-montevideo"
                  role="button"
                  title="{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }} Montevideo"
                  >{{
                    'footer.appartments_for_rent_on'
                      | translate: locale.language
                  }}
                  Montevideo</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-alquiler-en-punta-del-este"
                  role="button"
                  title="{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }} Punta del Este"
                  >{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }}
                  Punta del Este</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-alquiler-en-montevideo"
                  role="button"
                  title="{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }} Montevideo"
                  >{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }}
                  Montevideo</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-alquiler-en-maldonado"
                  role="button"
                  title="{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }} Maldonado"
                  >{{
                    'footer.house_for_rent_on' | translate: locale.language
                  }}
                  Maldonado</a
                >
              </li>
            </ul>
          </div>
        }
      </div>
    }
    <!-- Comprar -->
    @if (node !== 'ec') {
      <div class="footer-column">
        <div class="title-column-container">
          <span class="title-column">
            {{ 'footer.buy' | translate: locale.language }}
          </span>
        </div>
        @if (node === 'ar') {
          <div id="buy" class="links-list">
            <ul>
              <li>
                <a
                  href="/departamentos-en-venta-en-tandil"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Tandil"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Tandil</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-posadas"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Posadas"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Posadas</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-jujuy"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Jujuy"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Jujuy</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-san-juan"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} San Juan"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  San Juan</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-cordoba"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Córdoba"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Córdoba</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-santa-fe"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Santa Fé"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Santa Fé</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-la-plata"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} La Plata"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  La Plata</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-bariloche"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Bariloche"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Bariloche</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-recoleta"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Recoleta"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Recoleta</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-mar-del-plata"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Mar del Plata"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Mar del Plata</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-palermo"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Palermo"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Palermo</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-belgrano"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Belgrano"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Belgrano</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-ciudad-de-buenos-aires"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} CABA"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  CABA</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-la-plata"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} La Plata"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  La Plata</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-rosario"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Rosario"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Rosario</a
                >
              </li>
              <li>
                <a
                  href="/departamentos-en-venta-en-mendoza"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Mendoza"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Mendoza</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-ciudad-de-buenos-aires"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} CABA"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  CABA</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-mar-del-plata"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Mar del Plata"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Mar del Plata</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-mendoza"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Mendoza"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Mendoza</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-vicente-lopez"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Vicente López"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Vicente López</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-san-isidro"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} San Isidro"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  San Isidro</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-salta"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Salta"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Salta</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-tandil"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Tandil"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Tandil</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-posadas"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Posadas"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Posadas</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-jujuy"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Jujuy"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Jujuy</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-san-juan"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} San Juan"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  San Juan</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-cordoba"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Córdoba"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Córdoba</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-rosario"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Rosario"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Rosario</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-santa-fe"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Santa Fé"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Santa Fé</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-bariloche"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Bariloche"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Bariloche</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-la-plata"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} La Plata"
                  >{{ 'footer.house_for_sell' | translate: locale.language }} La
                  Plata</a
                >
              </li>
              <li>
                <a
                  href="/terrenos-en-venta-en-cordoba
                                                                      "
                  role="button"
                  title="{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }} Córdoba"
                  >{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }}
                  Córdoba</a
                >
              </li>
              <li>
                <a
                  href="/terrenos-en-venta-en-mendoza"
                  role="button"
                  title="{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }} Mendoza"
                  >{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }}
                  Mendoza</a
                >
              </li>
              <li>
                <a
                  href="/terrenos-en-venta-en-jujuy"
                  role="button"
                  title="{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }} Jujuy"
                  >{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }}
                  Jujuy</a
                >
              </li>
              <li>
                <a
                  href="/terrenos-en-venta-en-rosario"
                  role="button"
                  title="{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }} Rosario"
                  >{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }}
                  Rosario</a
                >
              </li>
              <li>
                <a
                  href="/terrenos-en-venta-en-santa-fe"
                  role="button"
                  title="{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }} Santa Fé"
                  >{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }}
                  Santa Fé</a
                >
              </li>
              <li>
                <a
                  href="/terrenos-en-venta-en-mar-del-plata"
                  role="button"
                  title="{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }} Mar del Plata"
                  >{{
                    'footer.lands_for_sell_on' | translate: locale.language
                  }}
                  Mar del Plata</a
                >
              </li>
            </ul>
          </div>
        }
        <!-- Botón: Ver más -->
        @if (node === 'ar') {
          <qr-button-with-icon
            [config]="viewMoreBuy"
            class="view-more-button"
            (click)="toggleViewLinksList('buy')">
          </qr-button-with-icon>
        }
        <!-- Uruguay: Comprar -->
        @if (node === 'uy') {
          <div id="buy" class="links-list">
            <ul>
              <li>
                <a
                  href="/apartamentos-en-venta-en-punta-del-este"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Punta del Este"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Punta del Este</a
                >
              </li>
              <li>
                <a
                  href="/apartamentos-en-venta-en-montevideo"
                  role="button"
                  title="{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }} Montevideo"
                  >{{
                    'footer.appartments_for_sell_on'
                      | translate: locale.language
                  }}
                  Montevideo</a
                >
              </li>
              <li>
                <a
                  href="casas-en-venta-en-punta-del-este"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Punta del Este"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Punta del Este</a
                >
              </li>
              <li>
                <a
                  href="/casas-en-venta-en-montevideo"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Montevideo"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Montevideo</a
                >
              </li>
              <li>
                <a
                  href="casas-en-venta-en-maldonado"
                  role="button"
                  title="{{
                    'footer.house_for_sell' | translate: locale.language
                  }} Maldonado"
                  >{{
                    'footer.house_for_sell' | translate: locale.language
                  }}
                  Maldonado</a
                >
              </li>
            </ul>
          </div>
        }
      </div>
    }
  </div>
  <div id="footer-line"></div>
  <div id="footer-bottom">
    @if (node === 'ar') {
      <a href="/consumer" role="button" id="consumer-information">
        {{ 'footer.consumer_information' | translate: locale.language }}
      </a>
    }

    <ul id="social">
      @if (node === 'ar') {
        <li>
          <a
            href="https://www.facebook.com/remaxargoficial"
            target="_blank"
            aria-label="Facebook RE/MAX Argentina">
            <qr-icon-button
              matTooltip="Facebook"
              class="expand-button"
              icon="facebook"
              [config]="this.configButtonSocialMedia"
              [ariaLabel]="'Facebook RE/MAX Argentina'">
            </qr-icon-button>
          </a>
        </li>
      }
      @if (node === 'uy') {
        <li>
          <a
            href="https://www.facebook.com/remaxuruguayoficial/"
            target="_blank"
            aria-label="Facebook RE/MAX Uruguay">
            <qr-icon-button
              matTooltip="Facebook"
              class="expand-button"
              icon="facebook"
              [config]="this.configButtonSocialMedia"
              [ariaLabel]="'Facebook RE/MAX Uruguay'">
            </qr-icon-button>
          </a>
        </li>
      }
      @if (node === 'ar') {
        <li>
          <a
            href="https://www.instagram.com/remaxargoficial/"
            target="_blank"
            aria-label="Instagram RE/MAX Instagram">
            <qr-icon-button
              matTooltip="Instagram"
              class="expand-button"
              icon="instagram"
              [config]="this.configButtonSocialMedia"
              [ariaLabel]="'Instagram RE/MAX Argentina'">
            </qr-icon-button>
          </a>
        </li>
      }
      @if (node === 'ar') {
        <li>
          <a
            href="https://twitter.com/remaxargoficial"
            target="_blank"
            aria-label="Twitter RE/MAX Argentina">
            <qr-icon-button
              matTooltip="Twitter"
              class="expand-button"
              icon="twitter-x"
              [config]="this.configButtonSocialMedia"
              [ariaLabel]="'Twitter RE/MAX Argentina'">
            </qr-icon-button>
          </a>
        </li>
      }
      @if (node === 'ar') {
        <li>
          <a
            href="https://www.youtube.com/user/remaxargentina"
            target="_blank"
            aria-label="YouTube RE/MAX Argentina">
            <qr-icon-button
              matTooltip="YouTube"
              class="expand-button"
              icon="youtube"
              [config]="this.configButtonSocialMedia"
              [ariaLabel]="'YouTube RE/MAX Argentina'">
            </qr-icon-button>
          </a>
        </li>
      }
      <!-- Redes ecuador -->
      @if (node === 'ec') {
        <li>
          <a
            href="https://www.instagram.com/remax_ecuador/"
            target="_blank"
            aria-label="Instagram RE/MAX Ecuador">
            <qr-icon-button
              matTooltip="Instagram"
              class="expand-button"
              icon="instagram"
              [config]="this.configButtonSocialMedia"
              [ariaLabel]="'Instagram RE/MAX Ecuador'">
            </qr-icon-button>
          </a>
        </li>
      }
      @if (node === 'ec') {
        <li>
          <a
            href="https://www.facebook.com/remax.ecuador"
            target="_blank"
            aria-label="Facebook RE/MAX Ecuador">
            <qr-icon-button
              matTooltip="Facebook"
              class="expand-button"
              icon="facebook"
              [config]="this.configButtonSocialMedia"
              [ariaLabel]="'Facebook RE/MAX Ecuador'">
            </qr-icon-button>
          </a>
        </li>
      }
      @if (node === 'ec') {
        <li>
          <a
            href="https://twitter.com/remaxecuador"
            target="_blank"
            aria-label="Twitter RE/MAX Ecuador">
            <qr-icon-button
              matTooltip="Twitter"
              class="expand-button"
              icon="twitter-x"
              [config]="this.configButtonSocialMedia"
              [ariaLabel]="'Twitter RE/MAX Ecuador'">
            </qr-icon-button>
          </a>
        </li>
      }
      @if (node === 'ec') {
        <li>
          <a
            href="https://www.youtube.com/user/REMAXEcuador"
            target="_blank"
            aria-label="YouTube RE/MAX Ecuador">
            <qr-icon-button
              matTooltip="YouTube"
              class="expand-button"
              icon="youtube"
              [config]="this.configButtonSocialMedia"
              [ariaLabel]="'YouTubeRE/MAX Ecuador'">
            </qr-icon-button>
          </a>
        </li>
      }
    </ul>
    <p id="powered-by">
      Powered by
      <a href="https://qrsolutions.com.ar/" target="_blank"
        ><b>QR Solutions</b></a
      >
    </p>
  </div>
</footer>
