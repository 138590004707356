import { Component, OnInit, Input, Inject } from '@angular/core';
import { Listing } from '@app/core/models/listing.model';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { UtilsService } from '../../services/utils.service';
import { Entrepreneurship } from '@app/core/models/entrepreneurship.model';
import { Constants } from '@base/src/app/core/constants/constants';
import { QrNumberPipe } from '../../pipes/qr-number.pipe';
import { QrTagComponent } from '../qr-tag/qr-tag.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MetersToHectarePipe } from '../../pipes/meters-to-hectare.pipe';
@Component({
  selector: 'qr-card-info-prop',
  templateUrl: './qr-card-info-prop.component.html',
  styleUrls: ['./qr-card-info-prop.component.scss'],
  standalone: true,
  imports: [
    QrNumberPipe,
    L10nTranslationModule,
    QrTagComponent,
    ReactiveFormsModule,
    MetersToHectarePipe,
  ],
})
export class QrCardInfoPropComponent implements OnInit {
  @Input() data!: Listing | Entrepreneurship;
  @Input() isEntrepreneurship: boolean = false;
  i18n = {};
  public yearBuiltCalc: number | null = 0;

  readonly PROPERTY_TYPE_CAMPO = Constants.PROPERTY_TYPE_CAMPO;
  readonly PROPERTY_TYPE_CHACRA = Constants.PROPERTY_TYPE_CHACRA;
  readonly HECTARES = Constants.HECTARES;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    if (!this.isEntrepreneurship) {
      this.data = this.data as Listing; // Pasamos la data como LISTING
      if (this.data.yearBuilt !== null && this.data.yearBuilt !== undefined) {
        this.yearBuiltCalc = this.utilsService.getAntiquity(
          this.data.yearBuilt
        );
      } else {
        this.yearBuiltCalc = null;
      }
    } else {
      this.data = this.data as Entrepreneurship; // Pasamos la data como ENTREPRENEURSHIP
    }
  }
}
