import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { QRInput } from '@base/src/app/core/models/qr-input.model';
import { QRInputComponent } from '../../../../../../components/ui/qr-input/qr-input.component';
import { QRDropdown } from '@base/src/app/core/models/qr-dropdown.model';
import { QRDropdownComponent } from '../../../../../../components/ui/qr-dropdown/qr-dropdown.component';
import { Alerts } from '@base/src/app/core/enum/Alerts';
import { SaveSearchValues } from '@base/src/app/pages/public/user-web/saved-search/models/saved-search-selected.model';
import { SaveSearchsUtilsService } from '@base/src/app/pages/public/user-web/saved-search/services/save-searchs-utils.service';

@Component({
  selector: 'app-search-save',
  templateUrl: './search-save.component.html',
  styleUrls: ['./search-save.component.scss'],
  standalone: true,
  imports: [QRInputComponent, QRDropdownComponent],
})
export class SaveSearchComponent implements OnChanges {
  @Input() saveSearch: SaveSearchValues = { name: undefined, alert: 1 };

  @Output() saveSearchChange: EventEmitter<SaveSearchValues> =
    new EventEmitter<SaveSearchValues>();

  public INPUT_NAME: QRInput = {
    label: '',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'save-search',
    id: 'save-search',
    placeholder: 'Ej. Departamentos en Belgrano',
    hint: 'Ingresá hasta 120 caracteres.',
    error: 'Ingrese un nombre valido',
    limitNumberOfCharacters: 120,
    isAutocompleteSuggestionsHidden: true,
    isFocus: true,
  };

  public inputNameValue: string | undefined;

  public DROPDOWN_ALERT: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Alerta',
    options: [Alerts.never, Alerts.inmediatly, Alerts.daily, Alerts.weekly],
    label: 'Alerta',
  };

  public dropdownAlertSelected: string = Alerts.never;

  private alertValue: number = 1;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['saveSearch']) {
      this.inputNameValue = changes['saveSearch'].currentValue.name;
      this.dropdownAlertSelected = SaveSearchsUtilsService.getAlertNameByID(
        changes['saveSearch'].currentValue.alert
      );
    }
  }

  public inputNameChange(event: string | number | undefined): void {
    this.inputNameValue = event?.toString() ?? undefined;
    if (event) {
      this.saveSearchChange.emit({
        name: this.inputNameValue ?? undefined,
        alert: this.alertValue,
      });
    } else {
      this.saveSearchChange.emit({
        name: undefined,
        alert: this.alertValue,
      });
    }
  }

  public dropdownAlertChange(e: string | string[] | undefined): void {
    if (e) {
      this.alertValue = SaveSearchsUtilsService.getAlertID(e?.toString());
      this.saveSearchChange.emit({
        name: this.inputNameValue ?? undefined,
        alert: this.alertValue,
      });
    }
  }
}
