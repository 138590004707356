import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { Overlay } from '@base/src/app/core/models/overlay.model';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRIcon } from '@base/src/app/core/models/qr-icon.model';
import { QRButtonComponent } from '../qr-button/qr-button.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'qr-overlay',
  standalone: true,
  imports: [QRButtonComponent],
  templateUrl: './overlay.component.html',
  styleUrl: './overlay.component.scss',
})
export class OverlayComponent implements OnChanges, OnDestroy {
  @Input() public isOverlayVisible: boolean = true;
  @Input() public config: Overlay = new Overlay();

  public readonly BUTTON_CLOSE: QRButton = {
    id: 'button-close',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_32_PX,
    icon: QRIcon.NAME_CLOSE,
    iconOnly: true,
    iconTooltipOnly: 'Cerrar',
  };

  public screenSize!: { width: number; height: number };

  @Output() private isOverlayVisibleChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private originalScrollPosition: number | undefined;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Escape' && this.isOverlayVisible) {
      this.close(); // Tecla ESC: Cierra overlay
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOverlayVisible']) {
      if (changes['isOverlayVisible'].currentValue) {
        this.getScreenSize();
      }
      this.handleParentScroll();
    }
  }

  ngOnDestroy(): void {
    this.handleParentScroll();
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('resize', this.updateScreenSize.bind(this));
    }
  }

  public close(): void {
    this.isOverlayVisible = false;
    this.handleParentScroll();
    this.isOverlayVisibleChange.emit(this.isOverlayVisible);
  }

  private handleParentScroll(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isOverlayVisible) {
        this.removeDisabledScroll();
      } else {
        document.body.classList.remove('ng-scope', 'disable-scroll');
        document.body.style.top = '0px';
        window.scrollTo({ top: this.originalScrollPosition });
      }
    }
  }

  private removeDisabledScroll(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.originalScrollPosition = window.scrollY;
      document.body.classList.add('disable-scroll');
      document.body.style.top = `-${this.originalScrollPosition}px`;
    }
  }

  private getScreenSize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.updateScreenSize();
      window.addEventListener('resize', this.updateScreenSize.bind(this));
    }
  }

  private updateScreenSize(): void {
    this.screenSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
}
