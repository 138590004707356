import { Component, Input, OnDestroy } from '@angular/core';
import { QRButtonComponent } from '../../../../../components/ui/qr-button/qr-button.component';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRIcon } from '@base/src/app/core/models/qr-icon.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { OverlayComponent } from '../../../../../components/ui/overlay/overlay.component';
import { Overlay } from '@base/src/app/core/models/overlay.model';
import { OverlayTopbarComponent } from '../../../../../components/ui/overlay-topbar/overlay-topbar.component';
import { SaveSearchComponent } from '../../../user-web/saved-search/components/search-save/search-save.component';
import { UserSearchSaveService } from '@base/src/app/services/user-search.service';
import { Subject, takeUntil } from 'rxjs';
import { InvitationToRegisterComponent } from '../../../../../components/dialogs-content/invitation-to-register/invitation-to-register.component';
import { SaveSearch } from '../../../user-web/saved-search/models/save-search-body.model';
import { SaveSearchValues } from '../../../user-web/saved-search/models/saved-search-selected.model';
import { IQrResponse } from '@base/src/app/core/models/IQrResponse';
import { SnackBarService } from '@base/src/app/services/snackbar.service';
import { SaveSearchsUtilsService } from '../../../user-web/saved-search/services/save-searchs-utils.service';
import { NotSaveSearchComponent } from '../../../user-web/saved-search/components/not-save-search/not-save-search.component';

@Component({
  selector: 'button-save-search',
  standalone: true,
  imports: [
    QRButtonComponent,
    OverlayComponent,
    OverlayTopbarComponent,
    SaveSearchComponent,
    NotSaveSearchComponent,
    InvitationToRegisterComponent,
  ],
  templateUrl: './button-save-search.component.html',
  styleUrl: './button-save-search.component.scss',
})
export class ButtonSaveSearchComponent implements OnDestroy {
  @Input() isButtonSaveSearchAbbreviated: boolean = false;
  public readonly BUTTON_SAVE_SEARCH: QRButton = {
    id: 'button-save-search',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'list.saveSearch',
    icon: QRIcon.NAME_BOOKMARK,
  };

  public readonly BUTTON_SAVE_SEARCH_ABBR: QRButton = {
    id: 'button-save-search',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'list.saveSearchAbbr',
    icon: QRIcon.NAME_BOOKMARK,
  };

  public isOverlayCreateAccountVisible: boolean = false;
  public readonly OVERLAY_CREATE_ACCOUNT: Overlay = {
    isButtonCloseVisible: false,
    isFullHeight: false,
    doesClickingOutsideContentClose: true,
  };

  public isOverlayCantSaveVisible: boolean = false;
  public readonly OVERLAY_CANT_SAVE: Overlay = {
    isButtonCloseVisible: false,
    isFullHeight: false,
    doesClickingOutsideContentClose: true,
    fixedContentHeight: 500,
  };

  public readonly BUTTON_ACCEPT: QRButton = {
    id: 'button-accept',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Aceptar',
  };

  public isOverlaySaveSearchVisible: boolean = false;

  public readonly OVERLAY_SAVE_SEARCH: Overlay = {
    isButtonCloseVisible: false,
    // isFullHeight: true,
    isFullHeight: false,
    doesClickingOutsideContentClose: true,
  };

  public readonly BUTTON_CANCEL: QRButton = {
    id: 'button-cancel',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Cancelar',
  };

  public readonly BUTTON_SAVE_SEARCH_OVERLAY: QRButton = {
    id: 'button-save-search',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'list.saveSearch',
    icon: QRIcon.NAME_BOOKMARK,
  };

  public saveSearchValues: SaveSearchValues = { name: undefined, alert: 1 };

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private userSearchSaveService: UserSearchSaveService,
    private snackBarService: SnackBarService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public openSaveSearch(): void {
    const PARAMS: Params = this.route.snapshot.queryParams;
    const HAS_PROPERTY_TYPE_SELECTED: number = PARAMS['in:typeId'];
    const HAS_LOCATION_SELECTED: string = PARAMS['locations'];

    Auth.currentAuthenticatedUser()
      .then(() => {
        if (HAS_PROPERTY_TYPE_SELECTED && HAS_LOCATION_SELECTED) {
          this.isOverlaySaveSearchVisible = true;
        } else {
          this.isOverlayCantSaveVisible = true;
        }
      })
      .catch(() => {
        this.isOverlayCreateAccountVisible = true;
      });
  }

  public saveSearchChange(e: SaveSearchValues): void {
    this.saveSearchValues = e;
  }

  public saveSearch(): void {
    if (this.saveSearchValues.name !== undefined) {
      Auth.currentAuthenticatedUser()
        .then(() => {
          const URL: string = window.location.href;
          const URL_SEARCH: string = window.location.search;
          const QUERY: string = URL.includes('emprendimientos')
            ? `${URL_SEARCH + '&in:operationId=1'}`
            : URL_SEARCH;
          console.log('QUERY', QUERY);
          const FORMAT_QUERY: string = SaveSearchsUtilsService.replaceOperators(
            QUERY
          ).replace(/\?/, '');
          const BASE_URL: string = URL.split('?')[0];
          const BODY: SaveSearch = {
            name: this.saveSearchValues.name ?? '-',
            url: URL,
            moduleType: BASE_URL,
            typeAlert: this.saveSearchValues.alert,
          };
          this.userSearchSaveService
            .postParams<string, SaveSearch, IQrResponse<null>>(
              FORMAT_QUERY,
              BODY
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (response: IQrResponse<null>) => {
                if (response.code == 200) {
                  this.snackBarService.showSnackBar(
                    'Tu búsqueda y alerta se crearon correctamente',
                    6000,
                    'Ir a búsquedas y alertas',
                    () => {
                      this.router.navigate(['/busquedas']);
                    }
                  );
                } else {
                  this.snackBarService.showSnackBar(
                    'Ocurrió un error al guardar la búsqueda.'
                  );
                }
              },
            });
        })
        .finally(() => {
          this.isOverlaySaveSearchVisible = false;
        })
        .catch(error => {
          console.error('Error retrieving authenticated user:', error);
        });
    }
  }
}
