@if (isOverlayVisible) {
  <div class="overlay">
    <div class="overlay__content">
      <div
        class="overlay__wrapper"
        [class.overlay__wrapper--full-height]="config.isFullHeight"
        [class.overlay__wrapper--full-width]="config.isFullWidth"
        [style.height]="
          config.fixedContentHeight &&
          screenSize.width >= 1000 &&
          screenSize.height >= config.fixedContentHeight
            ? config.fixedContentHeight
              ? config.fixedContentHeight + 'px'
              : 'fit-content'
            : null
        ">
        <ng-content></ng-content>
      </div>
      <div
        class="overlay__shadow"
        (click)="config.doesClickingOutsideContentClose ? close() : null"></div>
    </div>
    @if (config.isButtonCloseVisible) {
      <qr-button
        class="overlay__btn-close"
        (click)="close()"
        [config]="BUTTON_CLOSE"></qr-button>
    }
  </div>
}
