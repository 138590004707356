import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';

import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { QrFilterRange } from '@app/core/models/QrFilterRange';
import { QRInput } from '../../core/models/qr-input.model';
import { QRInputComponent } from '../ui/qr-input/qr-input.component';

@Component({
  selector: 'qr-filter-number-range',
  templateUrl: './qr-filter-number-range.component.html',
  styleUrls: ['./qr-filter-number-range.component.scss'],
  standalone: true,
  imports: [QRInputComponent],
})
export class QrFilterNumberRangeComponent implements OnDestroy {
  @Output()
  onchange: EventEmitter<QrFilterRange> = new EventEmitter<QrFilterRange>();

  dataSelected: QrFilterRange = new QrFilterRange();

  public readonly INPUT_MIN: QRInput = {
    label: 'Desde',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'from',
    id: 'from',
    placeholder: '0',
    format: QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS,
    hint: 'm²',
  };

  public readonly INPUT_MAX: QRInput = {
    label: 'Hasta',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'to',
    id: 'to',
    placeholder: '0',
    format: QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS,
    hint: 'm²',
  };

  private subscription: Subscription | undefined;

  @Input() set selected$(value: Observable<QrFilterRange>) {
    if (this.subscription === undefined) {
      this.subscription = value?.subscribe(data => {
        if (
          data != undefined &&
          data != null &&
          !Object.is(data, this.dataSelected)
        ) {
          this.dataSelected = data.clone();
        }
      });
    }
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  public inputMinChange(value: string | number | undefined): void {
    this.dataSelected.min =
      value !== null && value !== undefined ? parseFloat(value.toString()) : 0;
    this.dispatchEvent();
  }

  public inputMaxChange(value: string | number | undefined): void {
    this.dataSelected.max =
      value !== null && value !== undefined ? parseFloat(value.toString()) : 0;
    this.dispatchEvent();
  }

  private dispatchEvent(): void {
    this.onchange.emit(this.dataSelected.clone());
  }
}
