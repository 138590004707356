import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  DOCUMENT,
  Location,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { environment } from '@base/environments/environment';
import { Constants } from '@base/src/app/core/constants/constants';
import { Meta, Title } from '@angular/platform-browser';
@Injectable({ providedIn: 'root' })
export class SEOUtilsService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  setCanonicalTag(): void {
    let countryDomain = '';
    switch (environment.node) {
      case Constants.NODE_URUGUAY:
        countryDomain = 'uy';
        break;
      case Constants.NODE_ECUADOR:
        countryDomain = 'ec';
        break;
      default:
        countryDomain = 'ar';
        break;
    }
    const URL = 'https://www.remax.com.' + countryDomain + this.location.path();
    if (isPlatformServer(this.platformId)) {
      const head = this.document.getElementsByTagName('head')[0];
      let element: HTMLLinkElement | null =
        this.document.querySelector(`link[rel='canonical']`) || null;
      if (element == null) {
        element = this.document.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel', 'canonical');
      element.setAttribute('href', URL);
    } else if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const existingLink = document.querySelector('link[rel="canonical"]');
        if (existingLink) {
          existingLink.setAttribute('href', URL);
        }
      }, 1000); // Con este timeout lo actualiza
    }
  }

  public setMetatags(
    title: string,
    description: string,
    keywords?: string
  ): void {
    this.updateCanonicalURL(this.getURL());
    Promise.resolve().then(() => {
      this.titleService.setTitle(title);
      this.metaTagService.updateTag({
        name: 'description',
        content: description,
      });
      this.metaTagService.updateTag({
        property: 'og:title',
        content: title,
      });
      this.metaTagService.updateTag({
        property: 'og:description',
        content: description,
      });
      if (keywords) {
        this.metaTagService.updateTag({
          name: 'keywords',
          content: keywords,
        });
      }
      this.updateImage();
    });
  }

  private getURL(): string {
    return this.document.location.href;
  }

  private getURLOrigin(): string {
    return this.document.location.origin;
  }

  private updateCanonicalURL(url: string): void {
    const HEAD: HTMLHeadElement = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      HEAD.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
    this.metaTagService.updateTag({ property: 'og:url', content: url });
  }

  private updateImage(): void {
    const IMAGE_URL: string =
      this.getURLOrigin() + '/assets/images/seo/remax-balloon.jpg';
    const IMAGE_WIDTH: string = '1200';
    const IMAGE_HEIGHT: string = '600';
    this.metaTagService.updateTag({ property: 'og:image', content: IMAGE_URL });
    this.metaTagService.updateTag({
      property: 'og:image:width',
      content: IMAGE_WIDTH,
    });
    this.metaTagService.updateTag({
      property: 'og:image:height',
      content: IMAGE_HEIGHT,
    });
    this.metaTagService.updateTag({
      property: 'og:image:alt',
      content: 'Logo of RE/MAX',
    });
  }
}
