<div
  id="container"
  class="flex-col flex-start-start"
  itemscope
  itemtype="https://schema.org/Product">
  @if (data && !this.isEntrepreneurship) {
    <div>
      <!-- Tags -->
      <div id="tags">
        <!-- Tipo de operación -->
        <qr-tag
          text="{{ 'operations.' + data.operation?.value }}"
          color="grey"></qr-tag>
        <!-- Estado de captación (Reservada o En negociación)-->
        @if (
          data.listingStatus?.value === 'reserved' ||
          data.listingStatus?.value === 'negotiation'
        ) {
          <qr-tag
            id="listing-status"
            text="{{ 'list.' + data.listingStatus?.value }}"
            color="primary"></qr-tag>
        }
      </div>
      @if (data.title) {
        <div id="title-container" class="hide-gt-md">
          <h2 itemprop="name">{{ data.title }}</h2>
        </div>
      }
      <!-- Solicitar precio -->
      @if (data.priceExposure === false) {
        <div id="price-container" class="flex-start-center">
          <p>
            {{ 'listing-detail.request-price' | translate: locale.language }}
          </p>
        </div>
      } @else {
        <div id="price-container" class="flex-start-center">
          <p>{{ data.price || 0 | qrNumber }} {{ data.currency?.value }}</p>
          @if (data.billingFrequency) {
            <p id="billing-frequency-inprice" class="weight">
              /
              {{
                'billingFrequencies.' + data.billingFrequency.value
                  | translate: locale.language
              }}
            </p>
          }
        </div>
      }
      <!-- Precio -->
      @if (data.expensesPrice) {
        <div id="expenses-container" class="flex-start-center">
          <!-- Expensas -->
          <p>
            {{ 'list.expenses' | translate: locale.language }}
            :
            {{ data.expensesPrice | qrNumber }}
            {{ data.expensesCurrency ? data.expensesCurrency.value : null }}
          </p>
          @if (data.billingFrequency) {
            <p class="weight">
              /
              {{
                'billingFrequencies.' + data.billingFrequency.value
                  | translate: locale.language
              }}
            </p>
          }
        </div>
      }
      <div id="details-container" class="flex-center-center">
        <div id="details">
          <!-- Superficie total -->
          @if (data.dimensionTotalBuilt) {
            <div class="feature" data-info="dimensionTotalBuilt">
              <div class="icon-container">
                <div id="total-surface" class="icon"></div>
              </div>
              @if (
                data.type !== PROPERTY_TYPE_CAMPO &&
                data.type !== PROPERTY_TYPE_CHACRA
              ) {
                <span class="bold">{{
                  data.dimensionTotalBuilt | qrNumber
                }}</span>
                <span>{{
                  'listing-detail.metric_total_built'
                    | translate: locale.language
                }}</span>
              }
              @if (
                data.type === PROPERTY_TYPE_CAMPO ||
                data.type === PROPERTY_TYPE_CHACRA
              ) {
                <span class="bold">{{
                  data.dimensionLand || 0 | metersToHectare: false | qrNumber
                }}</span>
                <span>{{ HECTARES + ' totales' }}</span>
              }
            </div>
          }
          <!-- Superficie cubierta -->
          @if (data.dimensionCovered) {
            <div class="feature" data-info="dimensionCovered">
              <div class="icon-container">
                <div id="cover-surface" class="icon"></div>
              </div>

              <div>
                <span class="bold">{{ data.dimensionCovered | qrNumber }}</span>
                <span>{{
                  'listing-detail.metric_covered' | translate: locale.language
                }}</span>
              </div>
            </div>
          }
          <!-- Superficie terreno -->
          @if (data.dimensionLand) {
            <div class="feature" data-info="dimensionLand">
              <div class="icon-container">
                <div id="lot-surface" class="icon"></div>
              </div>

              <div>
                @if (
                  data.type !== PROPERTY_TYPE_CAMPO &&
                  data.type !== PROPERTY_TYPE_CHACRA
                ) {
                  <span class="bold">{{ data.dimensionLand | qrNumber }}</span>
                  <span>{{
                    'listing-detail.metric_land' | translate: locale.language
                  }}</span>
                }
                @if (
                  data.type === PROPERTY_TYPE_CAMPO ||
                  data.type === PROPERTY_TYPE_CHACRA
                ) {
                  <span class="bold">{{
                    data.dimensionLand | metersToHectare: false | qrNumber
                  }}</span>
                  <span>{{ HECTARES + ' terreno' }}</span>
                }
              </div>
            </div>
          }
          <!-- Ambientes -->
          @if (data.totalRooms) {
            <div class="feature" data-info="totalRooms">
              <div class="icon-container">
                <div id="rooms" class="icon"></div>
              </div>
              <span class="bold">{{ data.totalRooms }}</span>
              <span>{{
                'listing-detail.feature_total_rooms'
                  | translate: locale.language
              }}</span>
            </div>
          }
          <!-- Baños -->
          @if (data.bathrooms) {
            <div class="feature" data-info="bathrooms">
              <div class="icon-container">
                <div id="bathrooms" class="icon"></div>
              </div>
              <span class="bold">{{ data.bathrooms }}</span>
              @if (data.bathrooms > 1) {
                <span>{{
                  'listing-detail.feature_bathrooms'
                    | translate: locale.language
                }}</span>
              } @else {
                <span>{{
                  'listing-detail.feature_bathrooms_single'
                    | translate: locale.language
                }}</span>
              }
            </div>
          }
          <!-- Toilettes -->
          @if (data.toilets) {
            <div class="feature" data-info="toilets">
              <div class="icon-container">
                <div id="toilettes" class="icon"></div>
              </div>
              <span class="bold">{{ data.toilets }}</span>
              @if (data.toilets > 1) {
                <span>{{
                  'listing-detail.feature_toilets' | translate: locale.language
                }}</span>
              } @else {
                <span>{{
                  'listing-detail.feature_toilets_single'
                    | translate: locale.language
                }}</span>
              }
            </div>
          }
          <!-- Cochera -->
          @if (data.parkingSpaces) {
            <div class="feature" data-info="parkingSpaces">
              <div class="icon-container">
                <div id="garage" class="icon"></div>
              </div>
              <span class="bold">{{ data.parkingSpaces }}</span>
              @if (data.parkingSpaces > 1) {
                <span>{{
                  'listing-detail.feature_parking' | translate: locale.language
                }}</span>
              } @else {
                <span>{{
                  'listing-detail.feature_parking_single'
                    | translate: locale.language
                }}</span>
              }
            </div>
          }
          <!-- Dormitorios -->
          @if (data.bedrooms) {
            <div class="feature" data-info="bedrooms">
              <div class="icon-container">
                <div id="bedrooms" class="icon"></div>
              </div>
              <span class="bold">{{ data.bedrooms }}</span>
              @if (data.bedrooms > 1) {
                <span>{{
                  'listing-detail.feature_bedrooms' | translate: locale.language
                }}</span>
              } @else {
                <span>{{
                  'listing-detail.feature_bedrooms_single'
                    | translate: locale.language
                }}</span>
              }
            </div>
          }
          <!-- Antigüedad -->
          @if (
            data.type !== '18' && data.type !== '19' && yearBuiltCalc !== null
          ) {
            <div class="feature" data-info="antiquity">
              <div class="icon-container">
                <div id="antiquity" class="icon"></div>
              </div>

              <div>
                <span class="bold">{{
                  yearBuiltCalc >= 0
                    ? yearBuiltCalc
                    : ('list.brandNew' | translate: locale.language)
                }}</span>
                @if (yearBuiltCalc >= 0) {
                  <span>{{
                    'listing-detail.feature_year_built'
                      | translate: locale.language
                  }}</span>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }

  @if (this.isEntrepreneurship) {
    <div id="emprendimientos-details">
      <div id="price-container" class="price-entre flex-start-center">
        <span>Desde</span>
        <p>{{ data.minPrice || 0 | qrNumber }}{{ data.minCurrency.value }}</p>
        @if (data.minPrice !== data.maxPrice) {
          <span>hasta</span>
          <p>{{ data.maxPrice || 0 | qrNumber }}{{ data.maxCurrency.value }}</p>
        }
      </div>
      @if (data.totalUnits !== 0) {
        <div class="item-info-emprendimientos">
          <div class="icon-container">
            <div class="icon" id="units"></div>
          </div>
          <span class="bold">{{ data.totalUnits }}</span>
          <span>Unidades</span>
        </div>
      }
      @if (data.minDimension !== 0 && data.maxDimension !== 0) {
        <div class="item-info-emprendimientos">
          @if (data.minDimension !== data.maxDimension) {
            <div class="icon-container">
              <div class="icon" id="m2totals"></div>
            </div>
            <span class="bold">{{ data.minDimension }}</span>
            <span class="spaces"> a </span>
            <span class="bold">{{ data.maxDimension }}</span>
            <span>m2 Totales</span>
          } @else {
            <div class="icon-container">
              <div class="icon" id="m2totals"></div>
            </div>
            <span class="bold">{{ data.minDimension }}</span>
            <span>m2 Totales</span>
          }
        </div>
      }
      @if (data.minDimensionCovered !== 0 && data.maxDimensionCovered !== 0) {
        <div class="item-info-emprendimientos">
          @if (data.minDimensionCovered !== data.maxDimensionCovered) {
            <div class="icon-container">
              <div class="icon" id="m2cubiertos"></div>
            </div>
            <span class="bold">{{ data.minDimensionCovered }}</span>
            <span class="spaces"> a </span>
            <span class="bold">{{ data.maxDimensionCovered }}</span>
            <span>m2 Cubiertos</span>
          } @else {
            <div class="icon-container">
              <div class="icon" id="m2cubiertos"></div>
            </div>
            <span class="bold">{{ data.minDimensionCovered }}</span>
            <span> m2 Cubiertos</span>
          }
        </div>
      }
      @if (data.minTotalRooms !== 0 && data.maxTotalRooms !== 0) {
        <div class="item-info-emprendimientos">
          @if (data.minTotalRooms !== data.maxTotalRooms) {
            <div class="icon-container">
              <div class="icon" id="rooms"></div>
            </div>
            <span class="bold">{{ data.minTotalRooms }}</span>
            <span class="spaces"> a </span>
            <span class="bold">{{ data.maxTotalRooms }}</span>
            <span>Ambientes</span>
          } @else {
            <div class="icon-container">
              <div class="icon" id="rooms"></div>
            </div>
            <span class="bold">{{ data.minTotalRooms }}</span>
            <span>Ambientes</span>
          }
        </div>
      }
      @if (data.minParkingSpaces !== 0 && data.maxParkingSpaces !== 0) {
        <div class="item-info-emprendimientos desktop-mb-0">
          @if (data.minParkingSpaces !== data.maxParkingSpaces) {
            <div class="icon-container">
              <div class="icon" id="parking"></div>
            </div>
            <span class="bold">{{ data.minParkingSpaces }}</span>
            <span class="spaces"> a </span>
            <span class="bold">{{ data.maxParkingSpaces }}</span>
            <span>Cocheras</span>
          } @else {
            <div class="icon-container">
              <div class="icon" id="parking"></div>
            </div>
            <span class="bold">{{ data.minParkingSpaces }}</span>
            <span>Cocheras</span>
          }
        </div>
      }
      @if (data.minBathrooms !== 0 && data.maxBathrooms !== 0) {
        <div class="item-info-emprendimientos mb-0">
          @if (data.minBathrooms !== data.maxBathrooms) {
            <div class="icon-container">
              <div class="icon" id="bathroom"></div>
            </div>
            <span class="bold">{{ data.minBathrooms }}</span>
            <span class="spaces"> a </span>
            <span class="bold">{{ data.maxBathrooms }}</span>
            <span>Baños</span>
          } @else {
            <div class="icon-container">
              <div class="icon" id="bathroom"></div>
            </div>
            <span class="bold">{{ data.minBathrooms }}</span>
            <span>Baños</span>
          }
        </div>
      }
    </div>
  }

  <!-- CAMPOS PARA SEO  -->

  @if (data) {
    <div class="hidden">
      <div itemprop="name">{{ data.title }}</div>
      <div itemprop="description">{{ data.description }}</div>
      <div itemprop="brand">RE/MAX</div>
      @if (data.photos && data.photos.length > 0) {
        <div itemprop="image">
          {{ data.photos[0].value }}
        </div>
      }
      <div itemprop="productId">{{ data.internalId }}</div>
    </div>
  }
  @if (!this.isEntrepreneurship) {
    <div
      class="hidden"
      itemscope
      itemprop="offers"
      itemtype="https://schema.org/Offer">
      <div itemprop="price">{{ data.price }}</div>
      <div itemprop="priceCurrency">{{ data.currency?.value }}</div>
    </div>
  }

  <!-- CAMPOS PARA SEO  -->
</div>
