import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@base/environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.page.html',
  styleUrls: ['./terms-and-conditions.page.scss'],
  standalone: true,
  imports: [],
})
export class TermsAndConditionsPage implements OnInit {
  isBrowser: boolean;
  getURLLocation: Location | null = null;
  node = '';
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.node = environment.node;
    this.titleService.setTitle('Terminos y Condiciones | RE/MAX');
    this.getURLLocation = this.document.location;
    const url =
      this.getURLLocation.protocol +
      '//' +
      this.getURLLocation.host +
      '/' +
      this.route.snapshot.url[0]?.path;
    this.updateCanonicalUrl(url);
  }

  updateCanonicalUrl(url: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
    this.metaTagService.updateTag({
      property: 'og:title',
      content: 'Terminos y Condiciones | RE/MAX',
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: url,
    });
  }
}
