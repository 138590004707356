import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRIcon } from '@base/src/app/core/models/qr-icon.model';
import { QRButtonComponent } from '../qr-button/qr-button.component';

@Component({
  selector: 'qr-overlay-topbar',
  standalone: true,
  imports: [QRButtonComponent],
  templateUrl: './overlay-topbar.component.html',
  styleUrl: './overlay-topbar.component.scss',
})
export class OverlayTopbarComponent {
  @Input() isFullscreen: boolean = false;
  @Output() closeChange: EventEmitter<null> = new EventEmitter();
  public readonly BUTTON_CLOSE: QRButton = {
    id: 'button-close',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_40_PX,
    iconOnly: true,
    icon: QRIcon.NAME_CLOSE,
    iconTooltipOnly: 'Cerrar',
  };
}
