import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SaveSearchComponent } from '@base/src/app/pages/public/user-web/saved-search/components/search-save/search-save.component';
import { SaveFilter } from '@base/src/app/core/interfaces/save-filters.interface';
import { UserSaveSearch } from '@base/src/app/core/interfaces/user-save-search.interface';
import { Filter } from '@app/core/models/filters.model';
import { IQrPage } from '@base/src/app/core/models/IQrPage';
import { IQrResponse } from '@base/src/app/core/models/IQrResponse';
import { ItemParam } from '@base/src/app/core/models/ItemParam';
import { ParamsDomain } from '@base/src/app/domain/params.domain';
import { L10nTranslationService } from 'angular-l10n';
import { Auth } from 'aws-amplify';
import { Subject, Observable, takeUntil } from 'rxjs';
import { QrPaginationComponent } from '@base/src/app/components/qr-pagination/qr-pagination.component';
import { UserSearchSaveService } from '@base/src/app/services/user-search.service';
import { OverlayComponent } from '../../../../components/ui/overlay/overlay.component';
import { OverlayTopbarComponent } from '../../../../components/ui/overlay-topbar/overlay-topbar.component';
import { Overlay } from '@base/src/app/core/models/overlay.model';
import { QRButtonComponent } from '../../../../components/ui/qr-button/qr-button.component';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { SaveSearch } from './models/save-search-body.model';
import { SavedSearchItemComponent } from './components/saved-search-item/saved-search-item.component';
import { SavedSearchAction } from './models/saved-search-action.model';
import { SaveSearchValues } from './models/saved-search-selected.model';
import { SnackBarService } from '@base/src/app/services/snackbar.service';
import { DeleteSaveSearchComponent } from './components/delete-save/delete-save.component';
import { SkeletonLoaderComponent } from '../../../../components/ui/skeleton-loader/skeleton-loader.component';
import { SaveSearchsUtilsService } from './services/save-searchs-utils.service';
import { QRIcon } from '@base/src/app/core/models/qr-icon.model';

@Component({
  selector: 'app-saved-search',
  templateUrl: './saved-search.component.html',
  styleUrls: ['./saved-search.component.scss'],
  standalone: true,
  imports: [
    QrPaginationComponent,
    OverlayComponent,
    OverlayTopbarComponent,
    SaveSearchComponent,
    QRButtonComponent,
    SavedSearchItemComponent,
    DeleteSaveSearchComponent,
    SkeletonLoaderComponent,
  ],
})
export class SavedSearchPage implements OnInit, OnDestroy {
  public userSaveSearch: UserSaveSearch[] = [];
  public formattedUrl: string = '';
  public page: number = 0;
  public totalItems: number = 0;
  public pageSize: number = 10;
  public isLoading: boolean = true;
  public visiblePagination: boolean = false;

  @ViewChild(SaveSearchComponent) saveSearchComponent!: SaveSearchComponent;

  public isOverlayEditSearchVisible: boolean = false;

  public readonly OVERLAY_EDIT_SEARCH: Overlay = {
    isButtonCloseVisible: false,
    isFullHeight: false,
    doesClickingOutsideContentClose: true,
  };

  public isOverlayDeleteSearchVisible: boolean = false;

  public readonly OVERLAY_DELETE_SEARCH: Overlay = {
    isButtonCloseVisible: false,
    isFullHeight: false,
    doesClickingOutsideContentClose: true,
  };

  public readonly BUTTON_CANCEL: QRButton = {
    id: 'button-cancel',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Cancelar',
  };

  public readonly BUTTON_SAVE_CHANGES: QRButton = {
    id: 'button-save-changes',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Guardar cambios',
    icon: QRIcon.NAME_SAVE,
  };

  public readonly BUTTON_DELETE_SEARCH: QRButton = {
    id: 'button-delete-search',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Eliminar búsqueda',
    icon: QRIcon.NAME_DELETE,
  };

  public saveSearchValues: SaveSearchValues = { name: undefined, alert: 1 };
  public saveSearchSelected: SaveSearchValues = { name: undefined, alert: 1 };

  private readonly destroy$: Subject<void> = new Subject<void>();
  private currentSearch: UserSaveSearch | null = null;
  private readonly listingTypes$: Promise<ItemParam[]>;
  private readonly dataOperation$: Promise<ItemParam[]>;
  private readonly dataFeatures$: Promise<ItemParam[]>;
  private readonly currencyTypes$: Promise<ItemParam[]>;
  private readonly yearBuild$: Promise<ItemParam[]>;
  private readonly roomsTypes$: Promise<ItemParam[]>;
  private readonly bathroomsTypes$: Promise<ItemParam[]>;
  private readonly bedroomsTypes$: Promise<ItemParam[]>;
  private readonly parkingSpacesType$: Promise<ItemParam[]>;
  private readonly dataStage$: Promise<ItemParam[]>;

  constructor(
    private userSearchSaveService: UserSearchSaveService,
    private paramsDomain: ParamsDomain,
    private translation: L10nTranslationService,
    private titleService: Title,
    private snackBarService: SnackBarService
  ) {
    this.listingTypes$ = this.paramsDomain.getListingsTypes();
    this.dataOperation$ = this.paramsDomain.getOperationsTypes();
    this.dataFeatures$ = this.paramsDomain.getFeaturesTypes();
    this.currencyTypes$ = this.paramsDomain.getCurrenciesTypes();
    this.roomsTypes$ = this.paramsDomain.getRoomsTypes();
    this.bathroomsTypes$ = this.paramsDomain.getBathRoomsTypes();
    this.bedroomsTypes$ = this.paramsDomain.getBedroomsTypes();
    this.parkingSpacesType$ = this.paramsDomain.getParkingSpacesTypes();
    this.yearBuild$ = this.paramsDomain.getYearsBuildTypes();
    this.dataStage$ = this.paramsDomain.getStage();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Búsquedas Guardadas | RE/MAX');
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.fetchUserSaveSearch();
        this.loadUserSaveSearches();
      })
      .catch(error => {
        console.error('Error fetching authenticated user:', error);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public formatUrl(): void {
    this.userSaveSearch.forEach((search: UserSaveSearch) => {
      search.formattedUrl = `${search.moduleType}${SaveSearchsUtilsService.correctQueryFormat(
        search.url
      )}`;
    });
  }

  async pagination($event: number): Promise<void> {
    const ELMT = document.getElementById('save-searches');
    ELMT?.scrollIntoView({ behavior: 'smooth' });

    this.page = $event;
    await this.loadUserSaveSearches();
    if (this.userSaveSearch.length == 0) {
      this.page = 0;
      this.loadUserSaveSearches();
    }
  }

  public saveSearchChange(e: SaveSearchValues): void {
    this.saveSearchValues = e;
  }

  public saveSearch(): void {
    if (this.saveSearchValues.name !== undefined) {
      this.update(this.saveSearchValues.name, this.saveSearchValues.alert);
    }
  }

  public itemDispatchedAction(e: SavedSearchAction) {
    switch (e.action) {
      case SavedSearchAction.ACTION_OPEN_EDIT_DIALOG:
        this.currentSearch = e.savedSearch;
        this.saveSearchSelected = {
          name: e.savedSearch.name,
          alert: e.savedSearch.typeAlert,
        };
        this.isOverlayEditSearchVisible = true;
        break;
      case SavedSearchAction.ACTION_EDIT:
        this.currentSearch = e.savedSearch;
        this.update(e.savedSearch.name, e.savedSearch.typeAlert);
        break;
      case SavedSearchAction.ACTION_OPEN_DELETE_DIALOG:
        this.currentSearch = e.savedSearch;
        this.isOverlayDeleteSearchVisible = true;
        break;
    }
  }

  public delete(): void {
    if (this.currentSearch) {
      this.userSearchSaveService
        .deleteData<string>(this.currentSearch.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.userSaveSearch = this.userSaveSearch.filter(
              search => search.id !== this.currentSearch?.id
            );
            if (this.userSaveSearch.length == 0) {
              this.page = this.page - 1 < 0 ? 0 : this.page - 1;
              this.loadUserSaveSearches();
            }
            this.snackBarService.showSnackBar('Se ha eliminado la búsqueda');
          },
          error: err => {
            console.error('Error al eliminar la búsqueda', err);
          },
          complete: () => {
            this.isOverlayDeleteSearchVisible = false;
          },
        });
    }
  }

  private update(name?: string, typeAlert?: number): void {
    if (this.currentSearch) {
      const BODY: SaveSearch = {
        name,
        typeAlert,
      };
      this.userSearchSaveService
        .updateData<string, SaveSearch>(`${this.currentSearch.id}`, BODY)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            if (BODY.name) {
              this.userSaveSearch = this.userSaveSearch.map(s => {
                if (s.id === this.currentSearch?.id) {
                  return { ...s, name: BODY.name ?? '-' };
                }
                return s;
              });
            }
            if (BODY.typeAlert) {
              this.userSaveSearch = this.userSaveSearch.map(s => {
                if (s.id === this.currentSearch?.id) {
                  return { ...s, typeAlert: BODY.typeAlert ?? 1 };
                }
                return s;
              });
            }
            this.snackBarService.showSnackBar('Se ha modificado la búsqueda');
          },
          error: err => {
            console.error('Error al editar la búsqueda', err);
          },
          complete: () => {
            this.isOverlayEditSearchVisible = false;
          },
        });
    }
  }

  private fetchUserSaveSearch(): Observable<IQrResponse<IQrPage>> {
    const FILTER = new Filter();
    FILTER.addFilter('size', this.pageSize);
    FILTER.addFilter('page', this.page);

    const QUERY: string = FILTER.toQueryString();
    return this.userSearchSaveService
      .getParams<string, IQrResponse<IQrPage>>(QUERY)
      .pipe(takeUntil(this.destroy$));
  }

  private loadUserSaveSearches(): void {
    this.fetchUserSaveSearch().subscribe({
      next: (response: IQrResponse<IQrPage>) => {
        this.totalItems = 0;
        if (response.code === 200) {
          this.userSaveSearch = response.data.data;
          this.totalItems = response.data.totalItems;
          this.visiblePagination = this.totalItems > 10;
          this.userSaveSearch.forEach(search => (search.tags = []));
          this.loadTags();
          this.formatUrl();
        }
        if (response.code === 500) {
          this.isLoading = false;
        }
      },
      error: error => {
        console.error('Error occurred:', error);
      },
    });
  }

  private async loadTags(): Promise<void> {
    try {
      const REPONSE: IQrResponse<IQrPage> | undefined =
        await this.fetchUserSaveSearch().toPromise();
      if (REPONSE?.code === 200) {
        await Promise.all([
          this.loadTypes(),
          this.loadStages(),
          this.loadOperationTypes(),
          this.loadBathroom(),
          this.loadParkingSpaces(),
          this.loadRooms(),
          this.loadBedrooms(),
          this.loadAptCredit(),
          this.loadLocation(),
          this.loadPrice(),
          this.loadEntrepreneurshipTag(),
        ]);
        this.isLoading = false;
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }

  private async loadTypes() {
    const TYPES: ItemParam[] = await this.listingTypes$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'typeId' && typeof filter.value === 'string') {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type =>
              this.translation.translate('property-type.' + type.value)
            );
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadStages() {
    const TYPES: ItemParam[] = await this.dataStage$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'eStageId' && typeof filter.value === 'string') {
            const ESTAGE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              ESTAGE_IDS.includes(type.id)
            ).map(type => {
              let typeOperation: string;

              switch (type.value) {
                case 'sale':
                  typeOperation = 'Venta';
                  break;
                case 'rent':
                  typeOperation = 'Alquiler';
                  break;
                case 'presale':
                  typeOperation = 'Pre venta';
                  break;
                case 'finished':
                  typeOperation = 'Terminado';
                  break;
                case 'in_construction':
                  typeOperation = 'En construcción';
                  break;
                case 'pozo':
                  typeOperation = 'Pozo';
                  break;
                default:
                  typeOperation = 'alquiler-temporario';
              }

              return this.translation.translate(`${typeOperation}`);
            });
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadOperationTypes() {
    const TYPES: ItemParam[] = await this.dataOperation$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === 'operationId' &&
            typeof filter.value === 'string'
          ) {
            const OPERATIONS_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              OPERATIONS_IDS.includes(type.id)
            ).map(type => {
              let typeOperation: string;
              switch (type.value) {
                case 'sale':
                  typeOperation = 'Venta';
                  break;
                case 'rent':
                  typeOperation = 'Alquiler';
                  break;
                default:
                  typeOperation = 'Alquiler temporario';
              }

              return this.translation.translate(`${typeOperation}`);
            });

            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadBathroom() {
    const TYPES: ItemParam[] = await this.parkingSpacesType$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'bathrooms' && typeof filter.value === 'string') {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type => `${type.value} Baño`);
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadParkingSpaces() {
    const TYPES: ItemParam[] = await this.bathroomsTypes$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === 'parkingSpaces' &&
            typeof filter.value === 'string'
          ) {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type => `${type.value} Cochera`);
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadRooms() {
    const TYPES: ItemParam[] = await this.roomsTypes$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'totalRooms' && typeof filter.value === 'string') {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type => `${type.value} Ambientes`);
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadBedrooms() {
    const TYPES: ItemParam[] = await this.bedroomsTypes$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'totalRooms' && typeof filter.value === 'string') {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type => `${type.value} Dromitorio`);
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadAptCredit() {
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'aptCredit' && filter.value === true) {
            const NEW_TAG: string = 'Apto crédito';
            search.tags = [...(search.tags || []), NEW_TAG];
          }
        });
      }
    });
  }

  private async loadLocation() {
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === 'in' &&
            filter.operation === 'locations' &&
            typeof filter.value === 'string'
          ) {
            // Limpia el valor de ubicación y agrega el tag
            const CLEANED_LOCATION: string = this.cleanLocation(filter.value);
            const NEW_TAG: string = CLEANED_LOCATION;
            search.tags = [...(search.tags || []), NEW_TAG];
          }
        });
      }
    });
  }

  private cleanLocation(value: string): string {
    const CLEANED_VALUE: string = value
      .replace(/<\/?[^>]+>/gi, '')
      .replace(/:::/g, '')
      .replace(/::/g, '')
      .replace(/:/g, '')
      .replace(/#/g, ' ')
      .replace(/@/g, '')
      .replace(/[0-9]/g, '')
      .trim();

    return CLEANED_VALUE;
  }

  private async loadPrice() {
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === '1' &&
            filter.operation === 'pricein' &&
            typeof filter.value === 'string'
          ) {
            const FORMATTED_PRICE_RANGE: string = this.formatPriceRange(
              filter.value
            );
            const NEW_TAG: string = FORMATTED_PRICE_RANGE;
            search.tags = [...(search.tags || []), NEW_TAG];
          }
        });
      }
    });
  }

  private async loadEntrepreneurshipTag() {
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === 'entrepreneurship' &&
            filter.operation === 'eq' &&
            filter.value === true
          ) {
            const NEW_TAG: string =
              this.translation.translate('Emprendimiento');
            search.tags = [...(search.tags || []), NEW_TAG];
          }
        });
      }
    });
  }

  private formatPriceRange(value: string): string {
    const [min, max] = value.split(':');
    return `${min} - ${max}`;
  }
}
