<div class="menu">
  <a class="menu__link" (click)="scrollToSection('button')" matRipple>Button</a>
  <a class="menu__link" (click)="scrollToSection('icon')" matRipple>Icon</a>
  <a class="menu__link" (click)="scrollToSection('input')" matRipple>Input</a>
  <a class="menu__link" (click)="scrollToSection('textarea')" matRipple
    >Textarea</a
  >
  <a class="menu__link" (click)="scrollToSection('dropdown')" matRipple
    >Dropdown</a
  >
  <a class="menu__link" (click)="scrollToSection('autocomplete')" matRipple
    >Autocomplete</a
  >
  <a class="menu__link" (click)="scrollToSection('checkbox')" matRipple
    >Checkbox</a
  >
  <a class="menu__link" (click)="scrollToSection('radio-button')" matRipple
    >Radio button</a
  >
  <a class="menu__link" (click)="scrollToSection('switch')" matRipple>Switch</a>
  <a class="menu__link" (click)="scrollToSection('chip')" matRipple>Chip</a>
  <a class="menu__link" (click)="scrollToSection('picture-source')" matRipple
    >Picture source</a
  >
  <a class="menu__link" (click)="scrollToSection('toggle')" matRipple>Toggle</a>
  <a class="menu__link" (click)="scrollToSection('tab')" matRipple>Tab</a>
  <a class="menu__link" (click)="scrollToSection('overlay')" matRipple
    >Overlay</a
  >
</div>
