import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@app/core/models/constants.model';
import { QRInput } from '@app/core/models/qr-input.model';
import { Tab } from '@app/core/models/qr-tab.model';
import { QRIcon } from '@app/core/models/qr-icon.model';
import { environment } from '@base/environments/environment';
import { QRInputAutocomplete } from '@app/core/models/qr-input-autocomplete.model';
import { QRButton } from '@app/core/models/qr-button.model';
import { QRDropdown } from '@app/core/models/qr-dropdown.model';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { QRTextarea } from '@app/core/models/qr-textarea.model';
import { QRChip } from '@app/core/models/qr-chip.model';
import { PictureSource } from '@app/core/models/picture-source.model';
import { QRToggle } from '@app/core/models/qr-toggle.model';
import { QRInputComponent } from '@app/components/ui/qr-input/qr-input.component';
import { QRTextareaComponent } from '@app/components/ui/qr-textarea/qr-textarea.component';
import { QRInputAutocompleteComponent } from '@app/components/ui/qr-input-autocomplete/qr-input-autocomplete.component';
import { QRButtonComponent } from '@app/components/ui/qr-button/qr-button.component';
import { QRDropdownComponent } from '@app/components/ui/qr-dropdown/qr-dropdown.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { QRChipComponent } from '@app/components/ui/qr-chip/qr-chip.component';
import { QRToggleComponent } from '@app/components/ui/qr-toggle/qr-toggle.component';
import { PictureSourceComponent } from '@app/components/ui/picture-source/picture-source.component';
import { Overlay } from '@base/src/app/core/models/overlay.model';
import { OverlayComponent } from '@base/src/app/components/ui/overlay/overlay.component';
import { OverlayTopbarComponent } from '../../../components/ui/overlay-topbar/overlay-topbar.component';
import { QRIconComponent } from '@base/src/app/components/ui/qr-icon/qr-icon.component';
import { QRTabsComponent } from '../../../components/ui/qr-tabs/qr-tabs.component';
import { MenuQRCompsComponent } from './components/menu/menu.component';

@Component({
  selector: 'app-qr-comps',
  templateUrl: './qrcomps.component.html',
  styleUrls: ['./qrcomps.component.scss'],
  standalone: true,
  imports: [
    QRIconComponent,
    QRInputComponent,
    QRTextareaComponent,
    QRInputAutocompleteComponent,
    QRButtonComponent,
    QRDropdownComponent,
    QRChipComponent,
    QRToggleComponent,
    PictureSourceComponent,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    OverlayComponent,
    OverlayTopbarComponent,
    QRTabsComponent,
    MenuQRCompsComponent,
  ],
})
export class QRCompsPage implements OnInit {
  public readonly ICON_PLUS: QRIcon = {
    name: QRIcon.NAME_PLUS,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly ICON_CHECK: QRIcon = {
    name: QRIcon.NAME_CHECK,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly ICON_ERROR: QRIcon = {
    name: QRIcon.NAME_ERROR,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly ICON_LEFT: QRIcon = {
    name: QRIcon.NAME_ARROW_LEFT,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly ICON_RIGHT: QRIcon = {
    name: QRIcon.NAME_ARROW_RIGHT,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly ICON_MODE_VIEW_LIST: QRIcon = {
    name: QRIcon.NAME_MODE_VIEW_LIST,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly ICON_MODE_VIEW_GRID: QRIcon = {
    name: QRIcon.NAME_MODE_VIEW_GRID,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly ICON_MODE_VIEW_MAP: QRIcon = {
    name: QRIcon.NAME_MODE_VIEW_MAP,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly ICON_OFFICE: QRIcon = {
    name: QRIcon.NAME_OFFICE,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly TAB: Tab = {
    tabs: [
      { text: 'Casa' },
      { text: 'Departamento' },
      { text: 'Terrenos y lotes' },
      { text: 'PH' },
      { text: 'Campo' },
      { text: 'Cochera' },
    ],
    size: Tab.SIZE_48_PX,
    tabSelected: 2,
  };

  // Input: Texto
  public readonly INPUT: QRInput = {
    label: 'Texto',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'text',
    id: 'text',
    placeholder: 'Texto',
    hint: 'Ingrese nombre completo',
    error: 'Ingrese un nombre valido',
  };

  public readonly INPUT_WITH_VALUE: QRInput = {
    label: 'Texto',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'text',
    id: 'text',
    placeholder: 'Texto',
    hint: 'Ingrese nombre completo',
    error: 'Ingrese un nombre valido',
  };

  public textInputValue: string | undefined = 'Leonardo DiCaprio';

  // Input: Números enteros
  public readonly INPUT_NUMBER_INTEGER: QRInput = {
    label: 'Número entero',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'integer number',
    id: 'numberInteger',
    placeholder: 'Número entero',
    hint: 'Ingrese el precio',
    error: 'Ingrese un precio valido',
    format: QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS,
  };

  public readonly INPUT_NUMBER_INTEGER_WITH_VALUE: QRInput = {
    label: 'Número entero',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'integer number',
    id: 'numberInteger',
    placeholder: 'Número entero',
    hint: 'Ingrese el precio',
    error: 'Ingrese un precio valido',
    format: QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS,
  };

  public numberIntegerInputValue: number | undefined = 1632;

  // Input: Con limite de caracteres
  public readonly INPUT_LIMIT_CHARACTERS: QRInput = {
    label: 'Limite de carácteres',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'limitCharacter',
    id: 'numberInteger',
    limitNumberOfCharacters: 4,
  };

  // Input Autocomplete
  public readonly INPUT_AUTOCOMPLETE: QRInputAutocomplete = {
    label: 'Color',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'color',
    id: 'color',
    placeholder: 'Buscar colores',
    options: [
      'Azul',
      'Rojo',
      'Verde',
      'Amarillo',
      'Naranja',
      'Negro',
      'Blanco',
      'Violeta',
      'Marron',
      'Rosa',
      'Celeste',
      'Gris',
    ],
  };

  public readonly BUTTON_PRIMARY_SOLID: QRButton = {
    id: 'button-primary-solid',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  public readonly BUTTON_PRIMARY_SOLID_WITH_ICON: QRButton = {
    id: 'button-primary-solid',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  public readonly BUTTON_PRIMARY_SOLID_ONLY_ICON: QRButton = {
    id: 'button-primary-solid',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  public readonly BUTTON_PRIMARY_BORDER: QRButton = {
    id: 'button-primary-border',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  public readonly BUTTON_PRIMARY_BORDER_WITH_ICON: QRButton = {
    id: 'button-primary-border',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  public readonly BUTTON_PRIMARY_BORDER_ONLY_ICON: QRButton = {
    id: 'button-primary-border',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  public readonly BUTTON_TONAL_BORDER: QRButton = {
    id: 'button-tonal-border',
    hierarchy: QRButton.HIERARCHY_TONAL_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  public readonly BUTTON_TONAL_BORDER_WITH_ICON: QRButton = {
    id: 'button-tonal-border',
    hierarchy: QRButton.HIERARCHY_TONAL_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  public readonly BUTTON_TONAL_BORDER_ONLY_ICON: QRButton = {
    id: 'button-tonal-border',
    hierarchy: QRButton.HIERARCHY_TONAL_BORDER,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  public readonly BUTTON_TONAL: QRButton = {
    id: 'button-tonal',
    hierarchy: QRButton.HIERARCHY_TONAL,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  public readonly BUTTON_TONAL_WITH_ICON: QRButton = {
    id: 'button-tonal',
    hierarchy: QRButton.HIERARCHY_TONAL,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  public readonly BUTTON_TONAL_ONLY_ICON: QRButton = {
    id: 'button-tonal',
    hierarchy: QRButton.HIERARCHY_TONAL,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  public readonly BUTTON_GREY_BORDER: QRButton = {
    id: 'button-grey-border',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  public readonly BUTTON_GREY_BORDER_WITH_ICON: QRButton = {
    id: 'button-grey-border',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  public readonly BUTTON_GREY_BORDER_ONLY_ICON: QRButton = {
    id: 'button-grey-border',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  public readonly BUTTON_GREY: QRButton = {
    id: 'button-grey',
    hierarchy: QRButton.HIERARCHY_GREY,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  public readonly BUTTON_GREY_WITH_ICON: QRButton = {
    id: 'button-grey',
    hierarchy: QRButton.HIERARCHY_GREY,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  public readonly BUTTON_GREY_ONLY_ICON: QRButton = {
    id: 'button-grey',
    hierarchy: QRButton.HIERARCHY_GREY,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  public readonly BUTTON_GREEN_SOLID: QRButton = {
    id: 'button-green-solid',
    hierarchy: QRButton.HIERARCHY_GREEN_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  public readonly BUTTON_GREEN_SOLID_WITH_ICON: QRButton = {
    id: 'button-green-solid',
    hierarchy: QRButton.HIERARCHY_GREEN_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  public readonly BUTTON_GREEN_SOLID_ONLY_ICON: QRButton = {
    id: 'button-green-solid',
    hierarchy: QRButton.HIERARCHY_GREEN_SOLID,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
  };

  // Dropdown

  public readonly DROPDOWN_001: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
  };

  public dropdown001Selected: string | undefined = 'Verde';

  public readonly DROPDOWN_002: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
    icon: this.ICON_OFFICE,
  };

  public dropdown002Selected: string | undefined = 'Verde';

  public readonly DROPDOWN_003: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Colores',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
    icon: this.ICON_OFFICE,
    isMultiple: true,
    label: 'Colores',
  };

  public dropdown003Selected: string[] | undefined = ['Verde', 'Gris'];

  public readonly DROPDOWN_004: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
    icon: this.ICON_OFFICE,
    label: 'Color',
  };

  public dropdown004Selected: string = 'Gris';

  public readonly DROPDOWN_005: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
    icon: this.ICON_OFFICE,
    label: 'Color',
  };

  public dropdown005Selected: string = 'Gris';

  public readonly DROPDOWN_006: QRDropdown = {
    size: QRDropdown.SIZE_48_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
  };

  public dropdown006Selected: string | undefined = 'Verde';

  public readonly colors: string[] = ['Azul', 'Rojo', 'Verde', 'Amarillo'];

  // Textarea

  public readonly TEXTAREA: QRTextarea = {
    id: 'textarea',
    label: 'Descripción',
    hint: 'Ingrese una descripción',
    error: 'Ingrese una descripción',
  };

  public textareaValue: string | number | undefined = 'Hola, que tal.';

  // Chip

  public readonly CHIP_CAN_BE_HOVERED: QRChip = {
    canBeHovered: true,
  };

  public readonly CHIP_CAN_BE_SELECTED: QRChip = {
    canBeHovered: true,
    canBeSelected: true,
  };

  // Picture and Source
  public readonly PICTURE_BASE: PictureSource = {
    text: 'Imagen de prueba',
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  public readonly TOGGLE_01: QRToggle = {
    options: [{ value: 'Comprar' }, { value: 'Alquilar' }, { value: 'Vender' }],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  public toggle001OptionSelected: number = 0;

  public readonly ICON_PHONE: QRIcon = {
    name: QRIcon.NAME_PHONE,
    color: QRIcon.COLOR_GREY_WOLF,
  };

  public readonly ICON_EMAIL: QRIcon = {
    name: QRIcon.NAME_EMAIL,
    color: QRIcon.COLOR_GREY_WOLF,
  };

  public readonly ICON_WHATSAPP: QRIcon = {
    name: QRIcon.NAME_WHATSAPP,
    color: QRIcon.COLOR_GREY_WOLF,
  };

  public readonly TOGGLE_02: QRToggle = {
    options: [
      { value: 'Teléfono', icon: this.ICON_PHONE },
      { value: 'Email', icon: this.ICON_EMAIL },
      { value: 'WhatsApp', icon: this.ICON_WHATSAPP },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  public toggle002OptionSelected: number = 1;

  public readonly TOGGLE_03: QRToggle = {
    options: [
      { icon: this.ICON_PHONE },
      { icon: this.ICON_EMAIL },
      { icon: this.ICON_WHATSAPP },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  public toggle003OptionSelected: number = 1;

  public readonly TOGGLE_05: QRToggle = {
    options: [
      { value: 'Izquierda', icon: this.ICON_LEFT },
      { value: 'Derecha', icon: this.ICON_RIGHT },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  public toggle005OptionSelected: number = 1;

  public readonly TOGGLE_06: QRToggle = {
    options: [
      { value: 'Lista', icon: this.ICON_MODE_VIEW_LIST },
      { value: 'Grilla', icon: this.ICON_MODE_VIEW_GRID },
      { value: 'Mapa', icon: this.ICON_MODE_VIEW_MAP },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  public toggle006OptionSelected: number = 2;

  public readonly TOGGLE_07: QRToggle = {
    options: [
      { value: 'Teléfono', icon: this.ICON_PHONE },
      { value: 'Email', icon: this.ICON_EMAIL },
      { value: 'WhatsApp', icon: this.ICON_WHATSAPP },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_BORDER,
  };

  public toggle007OptionSelected: number = 1;

  public readonly TOGGLE_08: QRToggle = {
    options: [
      { value: 'Lista', icon: this.ICON_MODE_VIEW_LIST },
      { value: 'Grilla', icon: this.ICON_MODE_VIEW_GRID },
      { value: 'Mapa', icon: this.ICON_MODE_VIEW_MAP },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_BORDER,
  };

  public toggle008OptionSelected: number = 1;

  public readonly BUTTON_OVERLAY_001: QRButton = {
    id: 'button-overlay-001',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Overlay 001',
  };

  public readonly OVERLAY_001: Overlay = {
    isFullWidth: false,
    isFullHeight: true,
    isButtonCloseVisible: true,
    doesClickingOutsideContentClose: true,
  };

  public isOverlay001Visible: boolean = false;

  public readonly BUTTON_OVERLAY_002: QRButton = {
    id: 'button-overlay-002',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Overlay 002',
  };

  public readonly OVERLAY_002: Overlay = {
    isButtonCloseVisible: false,
    isFullHeight: false,
    doesClickingOutsideContentClose: true,
  };

  public isOverlay002Visible: boolean = false;

  public readonly BUTTON_OVERLAY_003: QRButton = {
    id: 'button-overlay-003',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Overlay 003',
  };

  public readonly OVERLAY_003: Overlay = {
    isButtonCloseVisible: false,
    isFullHeight: false,
    doesClickingOutsideContentClose: true,
    fixedContentHeight: 500,
  };

  public isOverlay003Visible: boolean = false;

  public readonly BUTTON_OVERLAY_004: QRButton = {
    id: 'button-overlay-004',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Overlay 004',
  };

  public readonly OVERLAY_004: Overlay = {
    isButtonCloseVisible: false,
    isFullHeight: true,
    isFullWidth: true,
    doesClickingOutsideContentClose: false,
  };

  public isOverlay004Visible: boolean = false;

  public readonly BUTTON_CLOSE_OVERLAY: QRButton = {
    id: 'button-close-overlay',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Cerrar',
  };

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (environment.production == true) {
      this.router.navigate(['/']); // QR Comps solo habilitado en DEV
    }
  }

  public tabChange(tabSelected: number): void {
    console.log('Tab seleccionada: ', tabSelected);
  }

  public inputChange(e: string | number | undefined): void {
    if (e) {
      console.log('Input VALUE change:', e);
    } else {
      console.log('Input VALUE change: undefined');
    }
  }

  public inputAutocompleteChange(e: string | undefined): void {
    console.log('Seleccionado: ', e);
  }

  public dropdownChange(e: string | string[] | undefined): void {
    console.log(e);
  }

  public checkboxChange(isChecked: boolean): void {
    console.log(isChecked);
  }

  public switchChange(value: MatSlideToggleChange): void {
    console.log(value.checked);
  }

  public textareaChange(e: string | number | undefined): void {
    console.log(e);
  }

  public chipChange(isSelected: boolean): void {
    console.log(isSelected);
  }

  public toggleChange(optionSelected: number): void {
    console.log('Opción seleccionada: ', optionSelected);
  }

  public viewOverlay001(): void {
    this.isOverlay001Visible = true;
  }

  public viewOverlay002(): void {
    this.isOverlay002Visible = true;
  }

  public viewOverlay003(): void {
    this.isOverlay003Visible = true;
  }

  public viewOverlay004(): void {
    this.isOverlay004Visible = true;
  }
}
