import { Component } from '@angular/core';

@Component({
  selector: 'app-remax-no-se-va',
  templateUrl: './remax-no-se-va.page.html',
  styleUrls: ['./remax-no-se-va.page.scss'],
})
export class RemaxNoSeVaPage {
  //constructor() { }
}
