import { Injectable } from '@angular/core';
import { SearchDomain } from '@app/domain/search.domain';
import { GeoSearch } from '@app/components/geo-search/geo-search.model';
import { environment } from '@base/environments/environment';
import { Location } from '@angular/common';
import { L10nTranslationService } from 'angular-l10n';
import { Constants } from '../core/constants/constants';
import { BreadcrumbURLs } from '../core/models/breadcrumb-urls.model';
import { Entrepreneurship } from '../core/models/entrepreneurship.model';
import { Listing } from '../core/models/listing.model';
import {
  SeoBreadcrumbConfig,
  SeoBreadcrumb,
} from '../core/models/seo-breadcrumb.model';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  public static readonly SEO_BREADCRUMB_SCRIPT_ID = 'seo-breadcrumb';
  public publication: Listing | Entrepreneurship | null = null; // Publicación ya sea Propiedad o Emprendimiento
  public publicationType: string = Constants.PUBLICATION_TYPE_LISTINGS; // Tipo de publicación, propiedad o emprendimiento
  public urls: BreadcrumbURLs = new BreadcrumbURLs(); // URLs para el breadcrumb
  public scriptCode: string = ''; // Código para el script de SEO
  private seoBreadcrumbConfig: any = new SeoBreadcrumbConfig();
  private readonly BASE_URL: string = environment.host;
  // ID del script en el template

  constructor(
    public searchDomain: SearchDomain,
    private location: Location,
    private translation: L10nTranslationService
  ) {}

  /*
  generateAllURLs: Basandose en el listing de la publicación define el atributo urls
  donde esta operationType, propertyType, rootLabel, countie,  citie, neighborhood y
  privateCommunitie.
  */
  public async generateAllURLs(): Promise<void> {
    this.searchDomain.resetAll();
    this.setSeoBreadcrumbConfig();
    this.urls.base = await this.searchDomain.getURLLocation(
      this.publicationType
    );

    if (this.isListingType()) {
      const publication: Listing = this.publication as Listing;
      if (publication.operation?.value == Constants.OPERATION_TYPE_SALE) {
        this.searchDomain.filterOperation = [Constants.OPERATION_ID_SALE];
      } else {
        this.searchDomain.filterOperation = [Constants.OPERATION_ID_RENT];
      }
    }

    const geoSearch: GeoSearch = new GeoSearch();

    if (this.isListingType()) {
      const publication: Listing = this.publication as Listing;
      // Tipo de operación
      this.searchDomain.filterOperation = [publication.operation?.id];
      this.urls.operationType = await this.searchDomain.getURLLocation(
        this.publicationType
      );
      // Tipo de propiedad
      this.handlePropertyTypeIDs();
      this.urls.propertyType = await this.searchDomain.getURLLocation(
        this.publicationType
      );
    }

    // RootLabel
    if (this.publication) {
      this.handleRootLabel(this.publication, geoSearch);
      this.urls.rootLabel = await this.searchDomain.getURLLocation(
        this.publicationType
      );
      // County
      this.handleCounty(this.publication, geoSearch);
      this.urls.county = await this.searchDomain.getURLLocation(
        this.publicationType
      );
      // City
      this.handleCity(this.publication, geoSearch);
      this.urls.city = await this.searchDomain.getURLLocation(
        this.publicationType
      );
      // Neighborhood
      this.handleNeighborhood(this.publication, geoSearch);
      this.urls.neighborhood = await this.searchDomain.getURLLocation(
        this.publicationType
      );
      // Private community
      this.handlePrivateCommunity(this.publication, geoSearch);
      this.buildScript();
    }
  }

  /*
  handlePropertyTypeIDs: Se utiliza para cuando una propiedad es tipo de propiedad Casa o Departamento
  obtener todos los IDs subtipos de ese tipo de propiedad, solo para propiedades/unidades
  */
  handlePropertyTypeIDs(): void {
    if (this.isListingType()) {
      const publication: Listing = this.publication as Listing;
      const propertyTypeID = publication.type?.id;
      let ids: number[] = [];
      if (propertyTypeID >= 9 && propertyTypeID <= 11) {
        // Casa
        ids = [9, 10, 11];
      } else if (propertyTypeID >= 1 && propertyTypeID <= 8) {
        // Departamentos
        ids = [1, 2, 3, 4, 5, 6, 7, 8];
      } else {
        ids.push(propertyTypeID);
      }
      this.searchDomain.filterListingType = ids;
    }
  }

  handleRootLabel(
    publication: Listing | Entrepreneurship,
    geoSearch: GeoSearch
  ): void {
    this.handlePropertyTypeIDs();
    if (
      publication.geo?.stateId !== null &&
      publication.geo?.stateId !== undefined
    ) {
      geoSearch.stateId = publication.geo.stateId;
      geoSearch.label = publication.geo.state;
    } else if (publication.geo?.subregionId) {
      geoSearch.subregionId = publication.geo.subregionId;
      geoSearch.label = publication.geo.subregion;
    }
    this.searchDomain.filterGeo = geoSearch;
  }

  handleCounty(
    publication: Listing | Entrepreneurship,
    geoSearch: GeoSearch
  ): void {
    this.handlePropertyTypeIDs();
    if (
      publication.geo?.stateId !== null &&
      publication.geo?.stateId !== undefined
    ) {
      geoSearch.stateId = publication.geo.stateId;
      geoSearch.label = publication.geo.state;
    } else if (publication.geo?.subregionId) {
      geoSearch.subregionId = publication.geo.subregionId;
      geoSearch.label = publication.geo.subregion;
    }
    if (
      publication.geo?.countyId !== null &&
      publication.geo?.countyId !== undefined &&
      publication.geo?.countyId > 0
    ) {
      geoSearch.countieId = publication.geo.countyId;
      geoSearch.label = publication.geo.countie + ', ' + geoSearch.label;
    }
    this.searchDomain.filterGeo = geoSearch;
  }

  handleCity(
    publication: Listing | Entrepreneurship,
    geoSearch: GeoSearch
  ): void {
    this.handlePropertyTypeIDs();
    if (
      publication.geo?.stateId !== null &&
      publication.geo?.stateId !== undefined
    ) {
      geoSearch.stateId = publication.geo.stateId;
      geoSearch.label = publication.geo.state;
    } else if (publication.geo?.subregionId) {
      geoSearch.subregionId = publication.geo.subregionId;
      geoSearch.label = publication.geo.subregion;
    }
    if (
      publication.geo?.countyId !== null &&
      publication.geo?.countyId !== undefined &&
      publication.geo?.countyId > 0
    ) {
      geoSearch.countieId = publication.geo.countyId;
      geoSearch.label = publication.geo.countie + ', ' + geoSearch.label;
    }
    if (
      publication.geo?.cityId !== null &&
      publication.geo?.cityId !== undefined &&
      publication.geo?.cityId > 0
    ) {
      geoSearch.citieId = publication.geo.cityId;
      geoSearch.label = publication.geo.citie + ', ' + geoSearch.label;
    }
    this.searchDomain.filterGeo = geoSearch;
  }

  handleNeighborhood(
    publication: Listing | Entrepreneurship,
    geoSearch: GeoSearch
  ): void {
    this.handlePropertyTypeIDs();
    if (
      publication.geo?.stateId !== null &&
      publication.geo?.stateId !== undefined
    ) {
      geoSearch.stateId = publication.geo.stateId;
      geoSearch.label = publication.geo.state;
    } else if (publication.geo?.subregionId) {
      geoSearch.subregionId = publication.geo.subregionId;
      geoSearch.label = publication.geo.subregion;
    }
    if (
      publication.geo?.countyId !== null &&
      publication.geo?.countyId !== undefined &&
      publication.geo?.countyId > 0
    ) {
      geoSearch.countieId = publication.geo.countyId;
      geoSearch.label = publication.geo.countie + ', ' + geoSearch.label;
    }
    if (
      publication.geo?.cityId !== null &&
      publication.geo?.cityId !== undefined &&
      publication.geo?.cityId > 0
    ) {
      geoSearch.citieId = publication.geo.cityId;
      geoSearch.label = publication.geo.citie + ', ' + geoSearch.label;
    }
    if (
      publication.geo &&
      publication.geo['neighborhoodId'] !== null &&
      publication.geo['neighborhoodId'] !== undefined &&
      publication.geo['neighborhoodId'] > 0
    ) {
      geoSearch.neighborhoodsId = publication.geo['neighborhoodId'];
      geoSearch.label = publication.geo.neighborhood + ', ' + geoSearch.label;
    }
    this.searchDomain.filterGeo = geoSearch;
  }

  async handlePrivateCommunity(
    publication: Listing | Entrepreneurship,
    geoSearch: GeoSearch
  ): Promise<void> {
    this.handlePropertyTypeIDs();
    if (
      publication.geo?.stateId !== null &&
      publication.geo?.stateId !== undefined
    ) {
      geoSearch.stateId = publication.geo.stateId;
      geoSearch.label = publication.geo.state;
    } else if (
      publication.geo?.subregionId !== null &&
      publication.geo?.subregionId !== undefined
    ) {
      geoSearch.subregionId = publication.geo.subregionId;
      geoSearch.label = publication.geo.subregion;
    }
    if (
      publication.geo?.countyId !== null &&
      publication.geo?.countyId !== undefined &&
      publication.geo?.countyId > 0
    ) {
      geoSearch.countieId = publication.geo.countyId;
      geoSearch.label = publication.geo.countie + ', ' + geoSearch.label;
    }
    if (
      publication.geo?.cityId !== null &&
      publication.geo?.cityId !== undefined &&
      publication.geo?.cityId > 0
    ) {
      geoSearch.citieId = publication.geo.cityId;
      geoSearch.label = publication.geo.citie + ', ' + geoSearch.label;
    }

    if (
      publication.geo?.privatecommunityId !== null &&
      publication.geo?.privatecommunityId !== undefined &&
      publication.geo?.privatecommunityId > 0
    ) {
      geoSearch.privatecommunityId = publication.geo.privatecommunityId;
      geoSearch.label =
        publication.geo.privatecommunitie + ', ' + geoSearch.label;
    }
    this.searchDomain.filterGeo = geoSearch;
    if (
      publication.geo?.privatecommunityId !== null &&
      publication.geo?.privatecommunityId !== undefined &&
      publication.geo?.privatecommunityId > 0
    ) {
      // Solo si existe privatecommunityid y es mayor a 0 se define la URL
      this.urls.privateCommunity = await this.searchDomain.getURLLocation(
        this.publicationType
      );
    } else {
      this.urls.privateCommunity = undefined;
    }
  }

  setSeoBreadcrumbConfig(): void {
    if (
      this.publicationType == Constants.PUBLICATION_TYPE_LISTINGS &&
      this.publication instanceof Listing
    ) {
      if (this.publication?.operation?.value) {
        this.seoBreadcrumbConfig.operationType = this.translation.translate(
          'list.' + this.publication.operation.value
        );
      }
      if (this.publication.type.value) {
        this.seoBreadcrumbConfig.propertyType = this.translation.translate(
          this.publication.type.value
        );
      }
    }

    if (this.publication?.geo?.rootLabel) {
      this.seoBreadcrumbConfig.rootLabel = this.translation.translate(
        this.publication?.geo?.rootLabel
      );
    }
    if (this.publication?.geo?.countie) {
      this.seoBreadcrumbConfig.countie = this.translation.translate(
        this.publication?.geo?.countie
      );
    }
    if (this.publication?.geo?.citie) {
      this.seoBreadcrumbConfig.citie = this.translation.translate(
        this.publication?.geo?.citie
      );
    }
    if (this.publication?.geo?.neighborhood) {
      this.seoBreadcrumbConfig.neighborhood = this.translation.translate(
        this.publication?.geo?.neighborhood
      );
    }
    if (this.publication?.geo?.privatecommunitie) {
      this.seoBreadcrumbConfig.privateCommunitie = this.translation.translate(
        this.publication?.geo?.privatecommunitie
      );
    }
    if (this.publication?.title) {
      this.seoBreadcrumbConfig.title = this.translation.translate(
        this.publication?.title
      );
    }
  }

  buildScript(): void {
    let position = 2; // Empezamos en la posición 2 porque el 0 y el 1 ya están tomados
    const list: SeoBreadcrumb[] = [
      {
        '@type': SeoBreadcrumb.TYPE_LIST_ITEM,
        position: 1,
        name: 'Home',
        item: this.BASE_URL,
      },
    ];
    [
      Constants.FILTER_OPERATION_TYPE,
      Constants.FILTER_PROPERTY_TYPE,
      Constants.FILTER_ROOT_LABEL,
      Constants.FILTER_COUNTY,
      Constants.FILTER_CITY,
      Constants.FILTER_NEIGHBORHOOD,
      Constants.FILTER_PRIVATE_COMMUNITY,
      Constants.FILTER_TITLE,
    ].forEach(seoBreadcrumbsName => {
      const SEO_BREADCRUMB_NAME = this.seoBreadcrumbConfig[seoBreadcrumbsName];
      if (SEO_BREADCRUMB_NAME) {
        list.push({
          '@type': SeoBreadcrumb.TYPE_LIST_ITEM,
          position: position,
          name: SEO_BREADCRUMB_NAME,
          item: this.getUrl(seoBreadcrumbsName) || '',
        });
        position += 1;
      }
    });
    const breadcrumbData = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: list,
    };
    this.scriptCode = `${JSON.stringify(breadcrumbData)}`;
  }

  getUrl(name: string): string {
    let url: string = '';
    switch (name) {
      case Constants.FILTER_OPERATION_TYPE:
        url = this.urls.operationType || '';
        break;
      case Constants.FILTER_PROPERTY_TYPE:
        url = this.urls.propertyType || '';
        break;
      case Constants.FILTER_ROOT_LABEL:
        url = this.urls.rootLabel || '';
        break;
      case Constants.FILTER_COUNTY:
        url = this.urls.county || '';
        break;
      case Constants.FILTER_CITY:
        url = this.urls.city || '';
        break;
      case Constants.FILTER_NEIGHBORHOOD:
        url = this.urls.neighborhood || '';
        break;
      case Constants.FILTER_PRIVATE_COMMUNITY:
        url = this.urls.privateCommunity || '';
        break;
      default:
        url = `${this.BASE_URL}${this.location.path()}` || '';
        break;
    }
    return url;
  }

  /*
  isListingType: Determina si es propiedad o emprendimiento
  */
  isListingType(): boolean {
    let isListingType: boolean;
    const listing: Listing = this.publication as Listing;
    if (
      listing.operation &&
      this.publicationType == Constants.PUBLICATION_TYPE_LISTINGS
    ) {
      isListingType = true;
    } else {
      isListingType = false;
    }
    return isListingType;
  }
}
