import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { environment } from '@base/environments/environment';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'qr-three-buttons-group',
  templateUrl: './qr-three-buttons-group.component.html',
  styleUrls: ['./qr-three-buttons-group.component.scss'],
  standalone: true,
  imports: [L10nTranslationModule, CommonModule, MatRippleModule],
})
export class QrThreeButtonsGroupComponent implements OnInit /*, OnDestroy */ {
  style: any[] = [];
  selected: any[] = [];
  dataForParent: any[] = [];
  //private subscription: Subscription;

  @Input() config: any; // Configuraciones del botón que llegan desde el componente padre
  @Output() eventClick = new EventEmitter<any>(); // Evento que se dispara al hacer click
  readonly country: string = environment.node;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}
  /*
	ngOnInit(): void {
		this.subscription = this.logicalService.filterOperation$.subscribe(
			(data) => {
				if (data.includes(1)) {
					this.config.selected[0] = true;
					this.config.selected[1] = false;
				} else if (data.includes(2)) {
					this.config.selected[0] = false;
					this.config.selected[1] = true;
				} else {
					//	this.config.selected[0] = false;
					//	this.config.selected[1] = false;
					//      this.config.selected[2] = true;
				}
				this.init();
			}
		);
	}

       
	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
		this.subscription = undefined;
	}
        */
  ngOnInit() {
    for (let index = 0; index < this.config.selected.length; index++) {
      this.style[index] = this.config.style;
      this.selected[index] = this.config.selected[index];
      if (this.config.selected[index] == true) {
        this.style[index] = this.config.styleOnSelected;
      }
    }
  }

  clickFirst(): void {
    // Si changeStyleOnSelected es true se cambia el style a styleOnSelected al clickearse
    if (this.config.changeStyleOnSelected == true) {
      if (this.selected[0] == true) {
        //this.dataForParent = [];
        this.style[0] = this.config.style;
        this.setFalse(this.selected);
      } else {
        this.dataForParent = [];
        this.style[0] = this.config.styleOnSelected;
        this.style[1] = this.config.style;
        this.style[2] = this.config.style;
        this.style[3] = this.config.style;
        this.setFalse(this.selected);
        this.selected[0] = true;
        this.dataForParent.push(this.config.text[0].small);
      }
    }

    this.eventClick.emit(this.dataForParent); // Envio al componente padre el evento de click
  }

  clickSecond(): void {
    // Si changeStyleOnSelected es true se cambia el style a styleOnSelected al clickearse
    if (this.config.changeStyleOnSelected == true) {
      if (this.selected[1] == true) {
        this.dataForParent = [];
        this.style[1] = this.config.style;
        this.setFalse(this.selected);
      } else {
        this.dataForParent = [];
        this.style[1] = this.config.styleOnSelected;
        this.style[0] = this.config.style;
        this.style[2] = this.config.style;
        this.style[3] = this.config.style;
        this.setFalse(this.selected);
        this.selected[1] = true;
        this.dataForParent.push(this.config.text[1].small);
      }
    }

    this.eventClick.emit(this.dataForParent); // Envio al componente padre el evento de click
  }

  clickThird(): void {
    // Si changeStyleOnSelected es true se cambia el style a styleOnSelected al clickearse
    if (this.config.changeStyleOnSelected == true) {
      if (this.selected[2] == true) {
        this.dataForParent = [];
        this.style[2] = this.config.style;
        this.setFalse(this.selected);
      } else {
        this.dataForParent = [];
        this.style[2] = this.config.styleOnSelected;
        this.style[0] = this.config.style;
        this.style[1] = this.config.style;
        this.style[3] = this.config.style;
        this.setFalse(this.selected);
        this.selected[2] = true;
        this.dataForParent.push(this.config.text[2].small);
      }
    }

    this.eventClick.emit(this.dataForParent); // Envio al componente padre el evento de click
  }

  clickFourth(): void {
    // Si changeStyleOnSelected es true se cambia el style a styleOnSelected al clickearse
    if (this.config.changeStyleOnSelected == true) {
      if (this.selected[3] == true) {
        this.dataForParent = [];
        this.style[3] = this.config.style;
        this.setFalse(this.selected);
      } else {
        this.dataForParent = [];
        this.style[3] = this.config.styleOnSelected;
        this.style[0] = this.config.style;
        this.style[1] = this.config.style;
        this.style[2] = this.config.style;
        this.setFalse(this.selected);
        this.selected[3] = true;
        this.dataForParent.push(this.config.text[3].small);
      }
    }

    this.eventClick.emit(this.dataForParent); // Envio al componente padre el evento de click
  }

  setFalse(array: any[]): void {
    for (let index = 0; index < array.length; index++) {
      array[index] = false;
    }
  }
}
