import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '@base/src/app/core/constants/constants';
import { IQrPage } from '@app/core/models/IQrPage';
import { ListingType } from '@app/core/models/listing-types.model';
import { ListingTypesSellService } from '@app/services/listing-types-sell.service';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { Subject } from 'rxjs';
import { environment } from '@base/environments/environment';
import { UtilsService } from '@app/services/utils.service';
import { QrFilter } from '@app/core/models/qr-filter';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { QrGoogleAutocompleteComponent } from '@app/components/qr-google-autocomplete/qr-google-autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { QRInputComponent } from '../../../components/ui/qr-input/qr-input.component';
import { QRInput } from '@base/src/app/core/models/qr-input.model';
import { QRDropdown } from '@base/src/app/core/models/qr-dropdown.model';
import { QRDropdownComponent } from '../../../components/ui/qr-dropdown/qr-dropdown.component';
import { QRTextarea } from '@base/src/app/core/models/qr-textarea.model';
import { QRTextareaComponent } from '../../../components/ui/qr-textarea/qr-textarea.component';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRButtonComponent } from '@base/src/app/components/ui/qr-button/qr-button.component';
import { ContactSell } from '@base/src/app/core/models/contact-sell.model';
import { ContactsSellService } from '@base/src/app/services/contacts-sell.service';
import { ItemParam } from '@base/src/app/core/models/ItemParam';
import { QRIconComponent } from '../../../components/ui/qr-icon/qr-icon.component';
import { QRIcon } from '@base/src/app/core/models/qr-icon.model';
import { SkeletonLoaderComponent } from '../../../components/ui/skeleton-loader/skeleton-loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SnackBarService } from '@base/src/app/services/snackbar.service';

@Component({
  selector: 'app-sell',
  standalone: true,
  imports: [
    L10nTranslatePipe,
    MatSelectModule,
    QrGoogleAutocompleteComponent,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    QRInputComponent,
    QRDropdownComponent,
    QRTextareaComponent,
    QRButtonComponent,
    QRIconComponent,
    SkeletonLoaderComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './sell.component.html',
  styleUrl: './sell.component.scss',
})
export class SellPage implements OnInit, OnDestroy {
  public readonly NODE: string = environment.node;
  public readonly NODE_ECUADOR: string = Constants.NODE_ECUADOR;

  public formGroup: FormGroup = new FormGroup({
    fullName: new FormControl(undefined, [
      Validators.required,
      Validators.minLength(3),
    ]),
    email: new FormControl(undefined, [Validators.required, Validators.email]),
    phone: new FormControl(undefined, [
      Validators.required,
      Validators.minLength(6),
    ]),
    location: new FormControl(undefined, [Validators.required]),
    propertyType: new FormControl(undefined, [Validators.required]),
    rooms: new FormControl(undefined),
    comments: new FormControl(undefined, [Validators.required]),
  });

  public isRoomsDropdownVisible?: boolean = false;
  public isFormCorrectlySent = false;

  public readonly TEXT_FULLNAME: QRInput = {
    label: 'Nombre y apellido',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'fullname',
    id: 'fullname',
    error: 'Ingrese un nombre y apellido',
  };

  public readonly TEXT_EMAIL: QRInput = {
    label: 'Email',
    type: QRInput.INPUT_TYPE_EMAIL,
    name: 'email',
    id: 'email',
    error: 'Ingrese un email',
  };

  public readonly TEXT_PHONE: QRInput = {
    label: 'Teléfono',
    type: QRInput.INPUT_TYPE_NUMBER,
    name: 'phone',
    id: 'phone',
    hint:
      this.NODE !== this.NODE_ECUADOR
        ? 'N° con código de área. Ej: 112345678(CABA)/3511234567 (Córdoba)'
        : 'N° completo con código de área. Ej: (4) 1234567',
    error: 'Ingrese un teléfono',
  };

  public readonly dropdownPropertyType: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Tipo de propiedad',
    options: [],
  };

  public readonly DROPDOWN_ROOMS: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Ambientes',
    options: [
      'rooms-type.one-room',
      'rooms-type.two-rooms',
      'rooms-type.three-rooms',
      'rooms-type.four-or-more-rooms',
    ],
  };

  public textareaComments: QRTextarea = {
    id: 'comments',
    label: 'Comentarios',
    hint: '0/250',
    error: 'Ingrese comentarios',
    limitNumberOfCharacters: 250,
    placeholder: '',
  };

  public readonly BUTTON_SUBMIT: QRButton = {
    id: 'button-submit',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Enviar',
  };

  public readonly ICON_CHECK: QRIcon = {
    name: QRIcon.NAME_CHECK,
    color: QRIcon.COLOR_WHITE,
  };

  public isSubmitLoading: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  private propertyTypes: ItemParam[] = []; // Listado de tipos de propiedad

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private propertyTypesSellService: ListingTypesSellService,
    private utils: UtilsService,
    private contactsSellService: ContactsSellService,
    private translationService: L10nTranslationService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.setMetaTags();
    this.loadPropertyTypes();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public dropdownPropertyTypeChange(e: string | string[] | undefined): void {
    const PROPERTY_TYPE: string | undefined = e
      ?.toString()
      .replace('property-type.', '');
    this.formGroup.get('propertyType')?.setValue(PROPERTY_TYPE);
    if (
      PROPERTY_TYPE == Constants.PROPERTY_TYPE_DEPARTAMENTO ||
      PROPERTY_TYPE == Constants.PROPERTY_TYPE_CASA ||
      PROPERTY_TYPE == Constants.PROPERTY_TYPE_PH
    ) {
      this.isRoomsDropdownVisible = true;
      this.changeRoomsValidators(true);
    } else {
      this.isRoomsDropdownVisible = false;
      this.changeRoomsValidators(false);
    }
  }

  public dropdownRoomsChange(e: string | string[] | undefined): void {
    if (e) {
      this.formGroup
        .get('rooms')
        ?.setValue(this.parseRoomsValue(e?.toString()));
    }
  }

  public textareaCommentsChange(e: string | number | undefined): void {
    this.textareaComments.hint = `${e?.toString().length}/250`;
    this.formGroup.get('comments')?.setValue(e);
  }

  public async submit(): Promise<void> {
    this.formGroup.markAllAsTouched(); // Marcar todos los campos como .touched para mostrar errores en caso de ser necesario.
    if (this.formGroup.valid) {
      this.isSubmitLoading = true;
      try {
        const CONTACT_SELL: ContactSell = new ContactSell();
        CONTACT_SELL.name = this.formGroup.get('fullName')?.value;
        CONTACT_SELL.phone = this.formGroup.get('phone')?.value;
        CONTACT_SELL.email = this.formGroup.get('email')?.value;
        CONTACT_SELL.ubication = this.formGroup.get('location')?.value;
        CONTACT_SELL.typeSell =
          this.getPropertyTypeID(this.formGroup.get('propertyType')?.value)
            ?.id ?? 0;
        CONTACT_SELL.rooms = this.formGroup.get('rooms')?.value ?? 0;
        CONTACT_SELL.description = this.formGroup.get('comments')?.value ?? '';
        const RESULT: IQrResponse<ContactSell> =
          await this.contactsSellService.addGet(CONTACT_SELL);
        if (RESULT.code != 200) {
          console.error('SellPage.onSubmit: ERROR ' + RESULT.message);
          this.snackBarService.showSnackBar(
            'Ocurrió un error al enviar el formulario.'
          );
        }
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        this.isFormCorrectlySent = true;
        this.isSubmitLoading = false;
      } catch (error) {
        this.isSubmitLoading = false;
        console.error('SellPage.onSubmit: ERROR ' + error);
        this.snackBarService.showSnackBar(
          'Ocurrió un error al enviar el formulario.'
        );
      }
    } else {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  }

  private setMetaTags(): void {
    const TITLE_TRANSLATED: string =
      this.translationService.translate('sell.title');
    const DESCRIPTION_TRANSLATED: string =
      this.translationService.translate('sell.subtitle');
    const TITLE: string = TITLE_TRANSLATED + ' | RE/MAX';
    const DESCRIPTION: string = DESCRIPTION_TRANSLATED;
    this.utils.setPageMetaTags(TITLE, DESCRIPTION);
  }

  private loadPropertyTypes(): void {
    const FILTER: QrFilter = new QrFilter();
    FILTER.sorts = ['+order'];
    const QUERY: string = FILTER.toQueryStringBe();
    this.propertyTypesSellService
      .getParams<string, IQrResponse<IQrPage>>(QUERY)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result: IQrResponse<IQrPage>) => {
          if (result.code === 200) {
            const DATA: IQrPage = result.data;
            DATA.data.forEach((listingType: ListingType) => {
              this.dropdownPropertyType.options.push(
                'property-type.' + listingType.value
              );
              const ITEM: ItemParam = {
                id: listingType.id,
                value: listingType.value,
                order: listingType.order,
                i18nKey: 'property-type.' + listingType.value,
                i18nValue: listingType.value,
              } as ItemParam;
              this.propertyTypes.push(ITEM);
            });
          } else {
            console.error(result);
          }
        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
      });
  }

  private getPropertyTypeID(i18nValue: string): ItemParam | undefined {
    const ITEM: ItemParam | undefined = this.propertyTypes.find(
      item => item.i18nValue === i18nValue
    );
    return ITEM;
  }

  private parseRoomsValue(value: string): number {
    let roomID: number;
    switch (value) {
      case this.DROPDOWN_ROOMS.options[0]: // Monoambiente
        roomID = 1;
        break;
      case this.DROPDOWN_ROOMS.options[1]: // 2 ambientes
        roomID = 2;
        break;
      case this.DROPDOWN_ROOMS.options[2]: // 3 ambientes
        roomID = 3;
        break;
      case this.DROPDOWN_ROOMS.options[3]: // 4 o más ambientes
        roomID = 4;
        break;
      default:
        roomID = 0;
        break;
    }
    return roomID;
  }

  private changeRoomsValidators(isRequired: boolean): void {
    const LOCATION_CONTROL: AbstractControl | null =
      this.formGroup.get('rooms');
    if (LOCATION_CONTROL) {
      if (isRequired) {
        LOCATION_CONTROL.setValidators([Validators.required]);
      } else {
        LOCATION_CONTROL.clearValidators();
      }
      LOCATION_CONTROL.updateValueAndValidity();
    }
  }
}
