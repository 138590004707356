<div class="saved-searches container" id="save-searches">
  <h3 class="saved-searches__title">Búsquedas guardadas</h3>
  @if (!isLoading) {
    <p class="saved-searches__results">{{ totalItems }} resultados</p>
  } @else {
    <skeleton-loader class="saved-searches__results-loader"></skeleton-loader>
  }

  @if (!isLoading) {
    @for (search of userSaveSearch; track search) {
      <app-saved-search-item
        class="saved-searches__item"
        [savedSearch]="search"
        (dispatchAction)="itemDispatchedAction($event)"></app-saved-search-item>
    }
  } @else {
    @for (item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]; track item) {
      <skeleton-loader class="saved-searches__item-loader"></skeleton-loader>
    }
  }

  @if (!isLoading && totalItems === 0) {
    <h1 class="saved-searches__no-results">No hay búsquedas guardadas</h1>
  }

  <!-- Paginación -->
  @if (this.visiblePagination) {
    <div id="pagination">
      <qr-pagination
        [actualPage]="this.page"
        [itemsArray]="this.totalItems"
        [pageSize]="this.pageSize"
        [dinamicComponent]="true"
        (onPagination)="pagination($event)">
      </qr-pagination>
    </div>
  }
</div>

<!-- Diálogo: Editá tu búsqueda y la alerta -->
<qr-overlay
  [(isOverlayVisible)]="isOverlayEditSearchVisible"
  [config]="OVERLAY_EDIT_SEARCH">
  <div class="overlay overlay--save-search">
    <div class="overlay__content">
      <qr-overlay-topbar (closeChange)="isOverlayEditSearchVisible = false">
        Editá tu búsqueda y la alerta
      </qr-overlay-topbar>
      <div class="overlay__wrapper">
        <app-search-save
          [saveSearch]="saveSearchSelected"
          (saveSearchChange)="saveSearchChange($event)"></app-search-save>
      </div>
    </div>
    <div class="botbar">
      <div class="botbar__button-cancel-wrapper">
        <qr-button
          class="botbar__button-cancel"
          [config]="BUTTON_CANCEL"
          (click)="isOverlayEditSearchVisible = false"></qr-button>
      </div>
      <div class="botbar__button-action-wrapper">
        <qr-button
          class="botbar__button-action"
          [config]="BUTTON_SAVE_CHANGES"
          (click)="saveSearch()"
          [isDisabled]="saveSearchValues.name === undefined"></qr-button>
      </div>
    </div>
  </div>
</qr-overlay>

<!-- Diálogo: Eliminar búsqueda -->
<qr-overlay
  [(isOverlayVisible)]="isOverlayDeleteSearchVisible"
  [config]="OVERLAY_DELETE_SEARCH">
  <div class="overlay">
    <div class="overlay__content">
      <qr-overlay-topbar (closeChange)="isOverlayDeleteSearchVisible = false">
        Eliminar búsqueda
      </qr-overlay-topbar>
      <div class="overlay__wrapper">
        <app-delete-save></app-delete-save>
      </div>
    </div>
    <div class="botbar">
      <div class="botbar__button-cancel-wrapper">
        <qr-button
          class="botbar__button-cancel"
          [config]="BUTTON_CANCEL"
          (click)="isOverlayDeleteSearchVisible = false"></qr-button>
      </div>
      <div class="botbar__button-action-wrapper">
        <qr-button
          class="botbar__button-action"
          [config]="BUTTON_DELETE_SEARCH"
          (click)="delete()"></qr-button>
      </div>
    </div>
  </div>
</qr-overlay>
