<div class="section">
  <app-menu-qr-comps></app-menu-qr-comps>
  <div class="section__list">
    <div class="comps">
      <!-- Button -->
      <h1 class="comps__title" id="button">Button</h1>
      <div class="comps__component">
        <div class="comps__row">
          <qr-button [config]="BUTTON_PRIMARY_SOLID"></qr-button>
          <qr-button [config]="BUTTON_PRIMARY_SOLID_WITH_ICON"></qr-button>
          <qr-button
            [config]="BUTTON_PRIMARY_SOLID_WITH_ICON"
            [isDisabled]="true"></qr-button>
          <qr-button [config]="BUTTON_PRIMARY_SOLID_ONLY_ICON"></qr-button>
        </div>
        <div class="comps__row">
          <qr-button [config]="BUTTON_TONAL_BORDER"></qr-button>
          <qr-button [config]="BUTTON_TONAL_BORDER_WITH_ICON"></qr-button>
          <qr-button
            [config]="BUTTON_TONAL_BORDER_WITH_ICON"
            [isDisabled]="true"></qr-button>
          <qr-button [config]="BUTTON_TONAL_BORDER_ONLY_ICON"></qr-button>
        </div>
        <div class="comps__row">
          <qr-button [config]="BUTTON_TONAL"></qr-button>
          <qr-button [config]="BUTTON_TONAL_WITH_ICON"></qr-button>
          <qr-button
            [config]="BUTTON_TONAL_WITH_ICON"
            [isDisabled]="true"></qr-button>
          <qr-button [config]="BUTTON_TONAL_ONLY_ICON"></qr-button>
        </div>
        <div class="comps__row">
          <qr-button [config]="BUTTON_PRIMARY_BORDER"></qr-button>
          <qr-button [config]="BUTTON_PRIMARY_BORDER_WITH_ICON"></qr-button>
          <qr-button
            [config]="BUTTON_PRIMARY_BORDER_WITH_ICON"
            [isDisabled]="true"></qr-button>
          <qr-button [config]="BUTTON_PRIMARY_BORDER_ONLY_ICON"></qr-button>
        </div>
        <div class="comps__row">
          <qr-button [config]="BUTTON_GREY_BORDER"></qr-button>
          <qr-button [config]="BUTTON_GREY_BORDER_WITH_ICON"></qr-button>
          <qr-button
            [config]="BUTTON_GREY_BORDER_WITH_ICON"
            [isDisabled]="true"></qr-button>
          <qr-button [config]="BUTTON_GREY_BORDER_ONLY_ICON"></qr-button>
        </div>
        <div class="comps__row">
          <qr-button [config]="BUTTON_GREY"></qr-button>
          <qr-button [config]="BUTTON_GREY_WITH_ICON"></qr-button>
          <qr-button
            [config]="BUTTON_GREY_WITH_ICON"
            [isDisabled]="true"></qr-button>
          <qr-button [config]="BUTTON_GREY_ONLY_ICON"></qr-button>
        </div>
        <div class="comps__row">
          <qr-button [config]="BUTTON_GREEN_SOLID"></qr-button>
          <qr-button [config]="BUTTON_GREEN_SOLID_WITH_ICON"></qr-button>
          <qr-button
            [config]="BUTTON_GREEN_SOLID_WITH_ICON"
            [isDisabled]="true"></qr-button>
          <qr-button [config]="BUTTON_GREEN_SOLID_ONLY_ICON"></qr-button>
        </div>
      </div>
    </div>
    <!-- Icon -->
    <h1 class="comps__title" id="icon">Icon</h1>
    <div class="comps__component">
      <div class="comps__row">
        <qr-icon [config]="ICON_PLUS"></qr-icon>
        <qr-icon [config]="ICON_CHECK"></qr-icon>
        <qr-icon [config]="ICON_CHECK"></qr-icon>
        <qr-icon [config]="ICON_LEFT"></qr-icon>
        <qr-icon [config]="ICON_RIGHT"></qr-icon>
        <qr-icon [config]="ICON_MODE_VIEW_LIST"></qr-icon>
        <qr-icon [config]="ICON_MODE_VIEW_GRID"></qr-icon>
        <qr-icon [config]="ICON_MODE_VIEW_MAP"></qr-icon>
        <qr-icon [config]="ICON_OFFICE"></qr-icon>
      </div>
    </div>
    <!-- Input -->
    <h1 class="comps__title" id="input">Input</h1>
    <div class="comps__component">
      <div class="comps__row">
        <qr-input
          class="comps__input"
          [config]="INPUT"
          (valueChange)="inputChange($event)"></qr-input>
        <qr-input
          class="comps__input"
          [config]="INPUT_WITH_VALUE"
          (valueChange)="inputChange($event)"></qr-input>
        <qr-input
          class="comps__input"
          [config]="INPUT_WITH_VALUE"
          (valueChange)="inputChange($event)"
          [(value)]="textInputValue"
          [isValid]="true"></qr-input>
        <qr-input
          class="comps__input"
          [config]="INPUT"
          (valueChange)="inputChange($event)"
          [hasError]="true"></qr-input>
        <qr-input
          class="comps__input"
          [config]="INPUT_WITH_VALUE"
          (valueChange)="inputChange($event)"
          [isDisabled]="true"></qr-input>
      </div>
      <div class="comps__row">
        <qr-input
          class="comps__input"
          [config]="INPUT_NUMBER_INTEGER"
          (valueChange)="inputChange($event)"></qr-input>
        <qr-input
          class="comps__input"
          [config]="INPUT_NUMBER_INTEGER_WITH_VALUE"
          (valueChange)="inputChange($event)"></qr-input>
        <qr-input
          class="comps__input"
          [config]="INPUT_NUMBER_INTEGER_WITH_VALUE"
          (valueChange)="inputChange($event)"
          [(value)]="numberIntegerInputValue"
          [isValid]="true"></qr-input>
        <qr-input
          class="comps__input"
          [config]="INPUT_NUMBER_INTEGER"
          (valueChange)="inputChange($event)"
          [hasError]="true"></qr-input>
        <qr-input
          class="comps__input"
          [config]="INPUT_NUMBER_INTEGER_WITH_VALUE"
          (valueChange)="inputChange($event)"
          [isDisabled]="true"></qr-input>
      </div>
      <div class="comps__row">
        <qr-input
          class="comps__input"
          [config]="INPUT_LIMIT_CHARACTERS"
          (valueChange)="inputChange($event)"></qr-input>
      </div>
      <div class="comps__row">
        <qr-input
          class="comps__input"
          [config]="INPUT_NUMBER_INTEGER"
          [(value)]="textInputValue"
          (valueChange)="inputChange($event)"></qr-input>
        <qr-input
          class="comps__input"
          [config]="INPUT_NUMBER_INTEGER_WITH_VALUE"
          [(value)]="numberIntegerInputValue"
          (valueChange)="inputChange($event)"></qr-input>
      </div>
    </div>
    <!-- Textarea -->
    <h1 class="comps__title" id="textarea">Textarea</h1>
    <div class="comps__component">
      <div class="comps__row">
        <qr-textarea
          class="comps__textarea"
          (textareaChange)="textareaChange($event)"></qr-textarea>
        <qr-textarea
          class="comps__textarea"
          [config]="TEXTAREA"
          (textareaChange)="textareaChange($event)"></qr-textarea>
        <qr-textarea
          class="comps__textarea"
          [config]="TEXTAREA"
          (textareaChange)="textareaChange($event)"
          [value]="textareaValue"
          [isValid]="true"></qr-textarea>
      </div>
      <div class="comps__row">
        <qr-textarea
          class="comps__textarea"
          [config]="TEXTAREA"
          [isDisabled]="true"
          (textareaChange)="textareaChange($event)"></qr-textarea>
        <qr-textarea
          class="comps__textarea"
          [config]="TEXTAREA"
          [hasError]="true"
          (textareaChange)="textareaChange($event)"></qr-textarea>
      </div>
    </div>
    <!-- Dropdown -->
    <h1 class="comps__title" id="dropdown">Dropdown</h1>
    <div class="comps__component">
      <div class="comps__row">
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_001"
          (onChange)="dropdownChange($event)"></qr-dropdown>
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_001"
          [selected]="dropdown001Selected"></qr-dropdown>
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_002"
          (onChange)="dropdownChange($event)"></qr-dropdown>
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_002"
          (onChange)="dropdownChange($event)"
          [selected]="dropdown002Selected"></qr-dropdown>
      </div>
      <div class="comps__row">
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_003"
          (onChange)="dropdownChange($event)"></qr-dropdown>
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_003"
          (onChange)="dropdownChange($event)"
          [selected]="dropdown003Selected"></qr-dropdown>
      </div>
      <div class="comps__row">
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_004"
          (onChange)="dropdownChange($event)"
          [selected]="dropdown004Selected"></qr-dropdown>
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_005"
          (onChange)="dropdownChange($event)"
          [selected]="dropdown005Selected"></qr-dropdown>
      </div>
      <div class="comps__row">
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_006"
          (onChange)="dropdownChange($event)"
          [selected]="dropdown006Selected"></qr-dropdown>
      </div>
      <div class="comps__row">
        <qr-dropdown
          class="comps__dropdown"
          [config]="DROPDOWN_001"
          (onChange)="dropdownChange($event)"
          [hasError]="true"></qr-dropdown>
      </div>
    </div>
    <!--  Autocomplete -->
    <h1 class="comps__title" id="autocomplete">Autocomplete</h1>
    <div class="comps__component">
      <div class="comps__row">
        <qr-input-autocomplete
          [config]="INPUT_AUTOCOMPLETE"
          (selectedChange)="
            inputAutocompleteChange($event)
          "></qr-input-autocomplete>
      </div>
    </div>
    <!-- Checkbox -->
    <h1 class="comps__title" id="checkbox">Checkbox</h1>
    <div class="comps__component">
      <div class="comps__row">
        <mat-checkbox
          class="checkbox--24px"
          [checked]="false"
          color="primary"
          (change)="checkboxChange($event.checked)"
          >Casa</mat-checkbox
        >
        <mat-checkbox
          class="checkbox--24px"
          [checked]="true"
          color="primary"
          (change)="checkboxChange($event.checked)"
          >Departamento</mat-checkbox
        >
        <mat-checkbox
          class="checkbox--24px"
          [checked]="false"
          color="primary"
          (change)="checkboxChange($event.checked)"
          >PH</mat-checkbox
        >
      </div>
    </div>
    <!-- Radio button -->
    <h1 class="comps__title" id="radio-button">Radio button</h1>
    <div class="comps__component">
      <div class="comps__row">
        @for (color of colors; track color) {
          <mat-radio-button
            class="example-radio-button"
            [value]="color"
            color="primary">
            {{ color }}
          </mat-radio-button>
        }
      </div>
    </div>
    <!-- Switch -->
    <h1 class="comps__title" id="switch">Switch</h1>
    <div class="comps__component">
      <div class="comps__row">
        <mat-slide-toggle
          [checked]="true"
          (change)="switchChange($event)"
          color="primary">
        </mat-slide-toggle>
      </div>
    </div>
    <!-- Chip -->
    <h1 class="comps__title" id="chip">Chip</h1>
    <div class="comps__component">
      <div class="comps__row">
        <qr-chip value="Solo lectura"></qr-chip>
        <qr-chip
          value="Hover activado"
          [config]="CHIP_CAN_BE_HOVERED"></qr-chip>
        <qr-chip
          value="Seleccionable"
          [config]="CHIP_CAN_BE_SELECTED"
          (onChange)="chipChange($event)"></qr-chip>
        <qr-chip
          value="Seleccionada"
          [config]="CHIP_CAN_BE_SELECTED"
          [isSelected]="true"
          (onChange)="chipChange($event)"></qr-chip>
      </div>
    </div>
    <!-- Picture Source -->
    <h1 class="comps__title" id="picture-source">Picture Source</h1>
    <div class="comps__component">
      <div class="comps__row">
        <picture-source
          class="comps__picture"
          [config]="PICTURE_BASE"
          [src]="'assets/media/webp/indice-remax/cover.webp'"></picture-source>
      </div>
    </div>
    <!-- Toggle -->
    <h1 class="comps__title" id="toggle">Toggle</h1>
    <div class="comps__component">
      <div class="comps__row">
        <qr-toggle
          class="comps__toggle"
          [config]="TOGGLE_01"
          [optionSelected]="toggle001OptionSelected"
          (optionSelectedChange)="toggleChange($event)"></qr-toggle>
      </div>
      <div class="comps__row">
        <qr-toggle
          class="comps__toggle"
          [config]="TOGGLE_01"
          [optionSelected]="toggle001OptionSelected"
          (optionSelectedChange)="toggleChange($event)"></qr-toggle>
        <qr-toggle
          class="comps__toggle"
          [config]="TOGGLE_02"
          [optionSelected]="toggle002OptionSelected"
          (optionSelectedChange)="toggleChange($event)"></qr-toggle>
        <qr-toggle
          class="comps__toggle"
          [config]="TOGGLE_03"
          [optionSelected]="toggle003OptionSelected"
          (optionSelectedChange)="toggleChange($event)"></qr-toggle>
      </div>
      <div class="comps__row">
        <qr-toggle
          class="comps__toggle"
          [config]="TOGGLE_05"
          [optionSelected]="toggle005OptionSelected"
          (optionSelectedChange)="toggleChange($event)"></qr-toggle>
        <qr-toggle
          class="comps__toggle"
          [config]="TOGGLE_06"
          [optionSelected]="toggle006OptionSelected"
          (optionSelectedChange)="toggleChange($event)"></qr-toggle>
      </div>
      <div class="comps__row">
        <qr-toggle
          class="comps__toggle"
          [config]="TOGGLE_07"
          [optionSelected]="toggle007OptionSelected"
          (optionSelectedChange)="toggleChange($event)"></qr-toggle>
        <qr-toggle
          class="comps__toggle"
          [config]="TOGGLE_08"
          [optionSelected]="toggle008OptionSelected"
          (optionSelectedChange)="toggleChange($event)"></qr-toggle>
      </div>
    </div>
    <!-- Tab -->
    <h1 class="comps__title" id="tab">Tab</h1>
    <div class="comps__component">
      <div class="comps__column">
        <qr-tabs [config]="TAB" (tabChange)="tabChange($event)"></qr-tabs>
        <qr-tabs
          [config]="TAB"
          (tabChange)="tabChange($event)"
          [isDisabled]="true"></qr-tabs>
      </div>
    </div>
    <!-- Overlay -->
    <h1 class="comps__title" id="overlay">Overlay</h1>
    <div class="comps__component">
      <div class="comps__row">
        <qr-button
          [config]="BUTTON_OVERLAY_001"
          (click)="viewOverlay001()"></qr-button>
        <qr-overlay
          [(isOverlayVisible)]="isOverlay001Visible"
          [config]="OVERLAY_001">
          <div class="overlay__content overlay__content--fixed-width">
            <qr-overlay-topbar (closeChange)="isOverlay001Visible = false"
              >Ejemplo 001 overlay</qr-overlay-topbar
            >
            <div class="overlay__wrapper">
              <h1 class="overlay__title">Título de prueba</h1>
              <p class="overlay__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                rutrum rhoncus ante a commodo. Aliquam vestibulum mauris odio,
                non volutpat nunc tincidunt et. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Fusce blandit, arcu ac porta congue, ante ligula
                gravida ligula, vel mattis metus felis vel lorem. Mauris eget
                imperdiet mi. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Donec ut leo felis. Duis eleifend risus non mauris finibus
                bibendum. Fusce semper aliquam lectus sit amet scelerisque. In
                hac habitasse platea dictumst. Maecenas sed ultricies enim. Duis
                urna quam, mollis vitae leo vel, egestas molestie massa.
                Phasellus turpis lacus, iaculis a condimentum quis, eleifend ut
                odio. Quisque scelerisque, tortor et tincidunt varius, mi augue
                lacinia elit, nec viverra erat augue sit amet nisi.
              </p>
              <qr-button
                [config]="BUTTON_CLOSE_OVERLAY"
                (click)="isOverlay001Visible = false"></qr-button>
              <p class="overlay__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                rutrum rhoncus ante a commodo. Aliquam vestibulum mauris odio,
                non volutpat nunc tincidunt et. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Fusce blandit, arcu ac porta congue, ante ligula
                gravida ligula, vel mattis metus felis vel lorem. Mauris eget
                imperdiet mi. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Donec ut leo felis. Duis eleifend risus non mauris finibus
                bibendum. Fusce semper aliquam lectus sit amet scelerisque. In
                hac habitasse platea dictumst. Maecenas sed ultricies enim. Duis
                urna quam, mollis vitae leo vel, egestas molestie massa.
                Phasellus turpis lacus, iaculis a condimentum quis, eleifend ut
                odio. Quisque scelerisque, tortor et tincidunt varius, mi augue
                lacinia elit, nec viverra erat augue sit amet nisi.
              </p>
            </div>
          </div></qr-overlay
        >

        <qr-button
          [config]="BUTTON_OVERLAY_002"
          (click)="viewOverlay002()"></qr-button>
        <qr-overlay
          [(isOverlayVisible)]="isOverlay002Visible"
          [config]="OVERLAY_002">
          <div class="overlay__content overlay__content--fixed-width">
            <qr-overlay-topbar (closeChange)="isOverlay002Visible = false"
              >Ejemplo 002 overlay</qr-overlay-topbar
            >
            <div class="overlay__wrapper">
              <h1 class="overlay__title">Título de prueba</h1>
              <p class="overlay__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                rutrum rhoncus ante a commodo. Aliquam vestibulum mauris odio,
                non volutpat nunc tincidunt et. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Fusce blandit, arcu ac porta congue, ante ligula
                gravida ligula, vel mattis metus felis vel lorem. Mauris eget
                imperdiet mi. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Donec ut leo felis. Duis eleifend risus non mauris finibus
                bibendum. Fusce semper aliquam lectus sit amet scelerisque. In
                hac habitasse platea dictumst. Maecenas sed ultricies enim. Duis
                urna quam, mollis vitae leo vel, egestas molestie massa.
                Phasellus turpis lacus, iaculis a condimentum quis, eleifend ut
                odio. Quisque scelerisque, tortor et tincidunt varius, mi augue
                lacinia elit, nec viverra erat augue sit amet nisi.
              </p>
              <qr-button
                [config]="BUTTON_CLOSE_OVERLAY"
                (click)="isOverlay002Visible = false"></qr-button>
            </div></div
        ></qr-overlay>

        <qr-button
          [config]="BUTTON_OVERLAY_003"
          (click)="viewOverlay003()"></qr-button>
        <qr-overlay
          [(isOverlayVisible)]="isOverlay003Visible"
          [config]="OVERLAY_003">
          <div class="overlay__content overlay__content--fixed-width">
            <qr-overlay-topbar (closeChange)="isOverlay003Visible = false"
              >Ejemplo 003 overlay</qr-overlay-topbar
            >
            <div class="overlay__wrapper">
              <h1 class="overlay__title">Título de prueba</h1>
              <p class="overlay__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                rutrum rhoncus ante a commodo. Aliquam vestibulum mauris odio,
                non volutpat nunc tincidunt et. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Fusce blandit, arcu ac porta congue, ante ligula
                gravida ligula, vel mattis metus felis vel lorem. Mauris eget
                imperdiet mi. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Donec ut leo felis. Duis eleifend risus non mauris finibus
                bibendum. Fusce semper aliquam lectus sit amet scelerisque. In
                hac habitasse platea dictumst. Maecenas sed ultricies enim. Duis
                urna quam, mollis vitae leo vel, egestas molestie massa.
                Phasellus turpis lacus, iaculis a condimentum quis, eleifend ut
                odio. Quisque scelerisque, tortor et tincidunt varius, mi augue
                lacinia elit, nec viverra erat augue sit amet nisi.
              </p>
              <qr-button
                [config]="BUTTON_CLOSE_OVERLAY"
                (click)="isOverlay003Visible = false"></qr-button>
              <p class="overlay__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                rutrum rhoncus ante a commodo. Aliquam vestibulum mauris odio,
                non volutpat nunc tincidunt et. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Fusce blandit, arcu ac porta congue, ante ligula
                gravida ligula, vel mattis metus felis vel lorem. Mauris eget
                imperdiet mi. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Donec ut leo felis. Duis eleifend risus non mauris finibus
                bibendum. Fusce semper aliquam lectus sit amet scelerisque. In
                hac habitasse platea dictumst. Maecenas sed ultricies enim. Duis
                urna quam, mollis vitae leo vel, egestas molestie massa.
                Phasellus turpis lacus, iaculis a condimentum quis, eleifend ut
                odio. Quisque scelerisque, tortor et tincidunt varius, mi augue
                lacinia elit, nec viverra erat augue sit amet nisi.
              </p>
            </div>
          </div></qr-overlay
        >

        <qr-button
          [config]="BUTTON_OVERLAY_004"
          (click)="viewOverlay004()"></qr-button>

        <qr-overlay
          [(isOverlayVisible)]="isOverlay004Visible"
          [config]="OVERLAY_004">
          <div class="overlay__content overlay__content--full-screen">
            <qr-overlay-topbar
              [isFullscreen]="true"
              (closeChange)="isOverlay004Visible = false"
              >Ejemplo 004 overlay</qr-overlay-topbar
            >
            <div class="overlay__wrapper">
              <h1 class="overlay__title">Título de prueba</h1>
              <p class="overlay__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                rutrum rhoncus ante a commodo. Aliquam vestibulum mauris odio,
                non volutpat nunc tincidunt et. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Fusce blandit, arcu ac porta congue, ante ligula
                gravida ligula, vel mattis metus felis vel lorem. Mauris eget
                imperdiet mi. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Donec ut leo felis. Duis eleifend risus non mauris finibus
                bibendum. Fusce semper aliquam lectus sit amet scelerisque. In
                hac habitasse platea dictumst. Maecenas sed ultricies enim. Duis
                urna quam, mollis vitae leo vel, egestas molestie massa.
                Phasellus turpis lacus, iaculis a condimentum quis, eleifend ut
                odio. Quisque scelerisque, tortor et tincidunt varius, mi augue
                lacinia elit, nec viverra erat augue sit amet nisi.
              </p>
              <qr-button
                [config]="BUTTON_CLOSE_OVERLAY"
                (click)="isOverlay004Visible = false"></qr-button>
            </div>
          </div>
        </qr-overlay>
      </div>
    </div>
  </div>
</div>
