import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IQrResponse } from '../../core/models/IQrResponse';
import { environment } from '@base/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilsService {
  constructor(
    protected http: HttpClient,
    @Inject('API_URL') private _url: string
  ) {}

  public getParams<P, R>(params: P): Observable<R> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.get<R>(`${environment.apiUrl}${this._url}?${params}`, {
          headers,
        })
      ),
      catchError(this.handleError)
    );
  }

  public getData<R>(endpoint: string): Observable<IQrResponse<R>> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.get<IQrResponse<R>>(
          `${environment.apiUrl}${this._url}/${endpoint}`,
          { headers }
        )
      ),
      catchError(this.handleError)
    );
  }

  /**
   * Método para realizar una solicitud HTTP POST con parámetros en la URL y un cuerpo en el payload.
   *
   * @template P - Tipo de los parámetros que se añadirán a la URL.
   * @template B - Tipo del cuerpo que se enviará en la solicitud.
   * @template R - Tipo de la respuesta esperada de la solicitud.
   *
   * @param params - Parámetros que se añadirán a la URL como cadena de consulta (query string).
   * @param body - Objeto que se enviará como cuerpo de la solicitud.
   *
   * @returns Observable<R> - Observable que emite la respuesta de tipo R o maneja errores.
   *
   */
  public postParams<P, B, R>(params: P, body: B): Observable<R> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.post<R>(`${environment.apiUrl}${this._url}?${params}`, body, {
          headers,
        })
      ),
      catchError(this.handleError)
    );
  }

  public deleteData<P>(objectId: P): Observable<void> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.delete<void>(
          `${environment.apiUrl}${this._url}/${objectId}`,
          { headers }
        )
      ),
      catchError(this.handleError)
    );
  }

  public updateData<P, R>(objectId: P, body?: R): Observable<void> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.put<void>(
          `${environment.apiUrl}${this._url}/${objectId}`,
          body,
          {
            headers,
          }
        )
      ),
      catchError(this.handleError)
    );
  }

  private setJwtToken(): Observable<HttpHeaders> {
    return from(Auth.currentSession()).pipe(
      switchMap(session => {
        const token = session.getAccessToken().getJwtToken();
        return new Observable<HttpHeaders>(observer => {
          observer.next(new HttpHeaders({ Authorization: `Bearer ${token}` }));
          observer.complete();
        });
      }),
      catchError(
        () =>
          new Observable<HttpHeaders>(observer => {
            observer.next(new HttpHeaders());
            observer.complete();
          })
      )
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error occurred
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Backend returned an unsuccessful response code
      errorMessage = `Server-side error: ${error.status} ${error.message}`;
    }
    console.error('Error occurred:', errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
