import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@base/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '@base/src/app/core/constants/constants';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  public static defaultGuid = '00000000-0000-0000-0000-000000000000';
  public static urlFranchiseGlobal(node: string) {
    // TODO: Esto corresponde a una variable de entorno
    let urlFrancshise: string = '';

    switch (node) {
      case Constants.NODE_ARGENTINA:
        urlFrancshise = 'https://remax-franquicias.com' + '.' + node;
        break;

      case Constants.NODE_URUGUAY:
        urlFrancshise = 'https://remax-franquicias.com' + '.' + node;
        break;

      case Constants.NODE_ECUADOR:
        urlFrancshise = 'https://franquiciasremax' + '.' + node;
        break;
    }

    return urlFrancshise;
  }

  public getURLLocation: Location | undefined;

  public truncateString = (string: string, maxlength: number) => {
    if (string.length > maxlength)
      return string.substring(0, maxlength) + '...';
    else return string;
  };

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute
  ) {}

  public getGuid(): any {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  public formatPriceOnMap(price: number): string {
    if (price >= 1_000_000) {
      const MILLIONS: number = price / 1_000_000;
      return Number.isInteger(MILLIONS)
        ? `${MILLIONS}M`
        : `${MILLIONS.toFixed(1)}M`;
    } else if (price >= 1_000) {
      const THOUSANDS: number = price / 1_000;
      return Number.isInteger(THOUSANDS)
        ? `${THOUSANDS}K`
        : `${THOUSANDS.toFixed(1)}K`;
    }
    return price.toString();
  }

  public formatPrice(cantidad: number): string {
    const innerCantidad = String(cantidad).replace(/\./g, '').replace(',', '.');
    let quantityString = innerCantidad.trim();
    const commaIndex = quantityString.indexOf('.');
    let commaString = '';
    if (commaIndex >= 0) {
      quantityString = quantityString.substr(0, commaIndex);
      if (quantityString.length == 0) {
        quantityString = '00';
      } else if (quantityString.length == 1) {
        quantityString = '0';
      }
    }
    quantityString = String(Number(quantityString.replace(/[^0-9]/g, '')));
    commaString = commaString.replace(/[^0-9]/g, '');

    quantityString = quantityString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (commaString.length > 0) quantityString += ',' + commaString;
    if (quantityString[quantityString.length - 1] == '.')
      quantityString = quantityString.substr(0, quantityString.length - 1);
    return quantityString;
  }

  public getAntiquity(year: number): number {
    return new Date().getFullYear() - year;
  }

  public getFrendlyLanguageName(name: string): string {
    switch (name) {
      case 'en-US':
        return 'EN';
      default:
        return 'ES';
    }
  }

  public isVisibleUserRegister(node: string): boolean {
    const regionsValidOptionsUserRegister = [
      Constants.NODE_ARGENTINA,
      Constants.NODE_URUGUAY,
    ];

    return regionsValidOptionsUserRegister.includes(node);
  }

  public isVisiblePrefooterSearchRelations(node: string): boolean {
    const regionsValidOptionsPrefooterSearchRelations = [
      Constants.NODE_ARGENTINA,
      Constants.NODE_URUGUAY,
      Constants.NODE_ECUADOR,
    ];

    return regionsValidOptionsPrefooterSearchRelations.includes(node);
  }

  public isVisibleAdvertisingBanners(node: string): boolean {
    return [Constants.NODE_ARGENTINA].includes(node);
  }

  public isVisibleAuctioneersOffice(node: string): boolean {
    return [Constants.NODE_ARGENTINA].includes(node);
  }

  public generateImageWithSize(
    url: string,
    size: string,
    format: string = Constants.DOCTYPE_WEBP,
    addWaterMark = false
  ): string {
    const [folder, idResource, ...resto] = url.split('/');

    const restoWithoutExtension = resto
      .join('/')
      .replace('.jpg', '')
      .replace('.JPG', '')
      .replace('.jpeg', '')
      .replace('.JPEG', '')
      .replace('.png', '')
      .replace('.PNG', '');

    let queryparams = '?';
    // Doble check para agregar marca de agua
    if (addWaterMark && environment.node == Constants.NODE_ECUADOR)
      queryparams += 'wm=true';

    queryparams = queryparams === '?' ? '' : queryparams;

    return (
      environment.imageBucketResizeUrl +
      folder +
      '/' +
      idResource +
      '/' +
      size +
      '/' +
      restoWithoutExtension +
      '.' +
      format +
      queryparams
    );
  }

  public setMetaTags(config: any): void {
    if (config.title) {
      this.metaTagService.addTag({ name: 'title', content: config.title });
      this.titleService.setTitle(config.title);
    }
    if (config.description) {
      this.metaTagService.updateTag({
        name: 'og:description',
        content: config.description,
      });
      this.metaTagService.updateTag({
        name: 'description',
        content: config.description,
      });
    }
    if (config.url) {
      this.updateCanonicalUrl(config.url);
      this.metaTagService.updateTag({ name: 'og:url', content: config.url });
    }
  }

  public setPageMetaTags(title: string, description: string): void {
    // TODO: Esto se esta seteando del lado del cliente? deberia hacerlo del lado del servidor
    if (isPlatformBrowser(this.platformId)) {
      this.getURLLocation = this.document.location;
      if (!this.getURLLocation) {
        return;
      }
      let url = this.getURLLocation.protocol + '//' + this.getURLLocation.host;

      if (
        this.route.snapshot.routeConfig &&
        this.route.snapshot.routeConfig.path
      ) {
        url = url + '/' + this.route.snapshot.routeConfig.path;
      }

      this.updateCanonicalUrl(url);

      this.metaTagService.updateTag({
        name: 'og:url',
        content: url,
      });
      this.titleService.setTitle(title);
      this.metaTagService.updateTag({
        name: 'og:description',
        content: description,
      });
      this.metaTagService.updateTag({
        name: 'description',
        content: description,
      });
    }
  }

  public updateCanonicalUrl(url: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null = this.document.querySelector(
      `link[rel='canonical']`
    );
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  updateURLWithSlug(slug: string, associateID?: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const URL_ORIGIN: string = window.location.origin;
      let updatedURL =
        URL_ORIGIN + '/' + Constants.PUBLICATION_TYPE_LISTINGS + '/' + slug;
      if (associateID) {
        updatedURL = updatedURL + '?associate=' + associateID;
      }
      history.pushState({}, '', updatedURL);
    }
  }
}
