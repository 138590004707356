@if (!isButtonSaveSearchAbbreviated) {
  <qr-button
    [config]="BUTTON_SAVE_SEARCH"
    (click)="openSaveSearch()"></qr-button>
} @else {
  <qr-button
    [config]="BUTTON_SAVE_SEARCH_ABBR"
    (click)="openSaveSearch()"></qr-button>
}

<!-- Diálogo: Crear cuenta -->
<qr-overlay
  [(isOverlayVisible)]="isOverlayCreateAccountVisible"
  [config]="OVERLAY_CREATE_ACCOUNT">
  <div class="overlay">
    <div class="overlay__content">
      <qr-overlay-topbar (closeChange)="isOverlayCreateAccountVisible = false">
        ¡Bienvenido!
      </qr-overlay-topbar>
      <app-invitation-to-register></app-invitation-to-register>
    </div>
  </div>
</qr-overlay>

<!-- Diálogo: No se ha podido guardar tu búsqueda -->
<qr-overlay
  [(isOverlayVisible)]="isOverlayCantSaveVisible"
  [config]="OVERLAY_SAVE_SEARCH">
  <div class="overlay overlay--cant-save">
    <div class="overlay__content">
      <qr-overlay-topbar (closeChange)="isOverlayCantSaveVisible = false">
      </qr-overlay-topbar>
      <div class="overlay__wrapper">
        <app-not-save-search></app-not-save-search>
      </div>
    </div>
    <div class="botbar">
      <qr-button
        class="botbar__button-apply"
        [config]="BUTTON_ACCEPT"
        (click)="isOverlayCantSaveVisible = false"></qr-button>
    </div>
  </div>
</qr-overlay>

<!-- Diálogo: Guardá tu búsqueda y creá la alerta -->
<qr-overlay
  [(isOverlayVisible)]="isOverlaySaveSearchVisible"
  [config]="OVERLAY_SAVE_SEARCH">
  <div class="overlay overlay--save-search">
    <div class="overlay__content">
      <qr-overlay-topbar (closeChange)="isOverlaySaveSearchVisible = false">
        Guardá tu búsqueda y creá la alerta
      </qr-overlay-topbar>
      <div class="overlay__wrapper">
        <app-search-save
          (saveSearchChange)="saveSearchChange($event)"></app-search-save>
      </div>
    </div>
    <div class="botbar">
      <div class="botbar__button-cancel-wrapper">
        <qr-button
          class="botbar__button-cancel"
          [config]="BUTTON_CANCEL"
          (click)="isOverlaySaveSearchVisible = false"></qr-button>
      </div>
      <div class="botbar__button-action-wrapper">
        <qr-button
          class="botbar__button-action"
          [config]="BUTTON_SAVE_SEARCH_OVERLAY"
          (click)="saveSearch()"
          [isDisabled]="saveSearchValues.name === undefined"></qr-button>
      </div>
    </div>
  </div>
</qr-overlay>
