export class QRIcon {
  name?: string;
  color: string = '';
  // Color
  public static readonly COLOR_BLACK: string = 'icon--black';
  public static readonly COLOR_WHITE: string = 'icon--white';
  public static readonly COLOR_PRIMARY: string = 'icon--primary';
  public static readonly COLOR_GREY_WOLF: string = 'icon--grey-wolf';
  public static readonly COLOR_GREY_IRON: string = 'icon--grey-iron';
  public static readonly COLOR_RED: string = 'icon--red';
  public static readonly COLOR_GREY_NERO: string = 'icon--grey-nero';
  public static readonly COLOR_GREY_UNICORN: string = 'icon--grey-unicorn';
  public static readonly COLOR_SECONDARY: string = 'icon--secondary';
  public static readonly COLOR_GREEN: string = 'icon--green';
  // Nombre
  public static readonly NAME_PLUS: string = 'plus';
  public static readonly NAME_CHECK: string = 'check';
  public static readonly NAME_ERROR: string = 'error';
  public static readonly NAME_ARROW_DROP_DOWN: string = 'arrow-drop-down';
  public static readonly NAME_FAVORITE: string = 'favorite-outlined';
  public static readonly NAME_WHATSAPP: string = 'whatsapp-outlined';
  public static readonly NAME_PHONE: string = 'phone-outlined';
  public static readonly NAME_EMAIL: string = 'email-outlined';
  public static readonly NAME_ARROW_LEFT: string = 'arrow-left';
  public static readonly NAME_ARROW_RIGHT: string = 'arrow-right';
  public static readonly NAME_CLOSE: string = 'close';
  public static readonly NAME_MODE_VIEW_LIST: string = 'mode-view-list';
  public static readonly NAME_MODE_VIEW_GRID: string = 'mode-view-grid';
  public static readonly NAME_MODE_VIEW_MAP: string = 'mode-view-map';
  public static readonly NAME_OFFICE: string = 'office';
  public static readonly NAME_BOOKMARK: string = 'bookmark';
  public static readonly NAME_FILTER: string = 'filter';
  public static readonly NAME_SEARCH: string = 'search';
  public static readonly NAME_DELETE: string = 'delete-outlined';
  public static readonly NAME_SAVE: string = 'save';
  public static readonly NAME_DRAW: string = 'draw-outlined';
  public static readonly NAME_HOUSE: string = 'home';
  public static readonly NAME_APARTMENTS_IN_BUILDING: string =
    'apartments-in-building';
}
