import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';

import { Constants } from '@base/src/app/core/constants/constants';
import { L10nLocale, L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { isPlatformBrowser } from '@angular/common';
import { GeoSearch } from '@app/components/geo-search/geo-search.model';
import { ParamsDomain } from '@app/domain/params.domain';
import { SearchDomain } from '@app/domain/search.domain';
import { environment } from '@base/environments/environment';
import { UtilsService } from '@app/services/utils.service';
import { QRBanner } from '@app/core/models/qr-banner.model';
import { QRMediaImage } from '@app/core/models/qr-media-img';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QrDisclaimerComponent } from '@app/components/qr-disclaimer/qr-disclaimer.component';
import { QrMediaImgComponent } from '@app/components/qr-media-img/qr-media-img.component';
import { QrBannerListingComponent } from '@app/components/qr-banner-listing/qr-banner-listing.component';
import { QrCardInformationComponent } from '@app/components/qr-card-information/qr-card-information.component';
import { QrSearchbarComponent } from '@app/components/qr-searchbar/qr-searchbar.component';
import { QrThreeButtonsGroupComponent } from '@app/components/qr-three-buttons-group/qr-three-buttons-group.component';
import { QrDialogComponent } from '@app/components/qr-dialog/qr-dialog.component';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/directive/swiper.directive';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRButtonComponent } from '@base/src/app/components/ui/qr-button/qr-button.component';

@Component({
  selector: 'app-home',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MatProgressSpinnerModule,
    QrDisclaimerComponent,
    QrMediaImgComponent,
    L10nTranslatePipe,
    QrBannerListingComponent,
    QrCardInformationComponent,
    QrSearchbarComponent,
    QrThreeButtonsGroupComponent,
    QrDialogComponent,
    SwiperDirective,
    QRButtonComponent,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
})
export class HomePage implements OnInit {
  // Lectura del Nodo del país
  readonly node = environment.node;

  // URL de las franquicias
  urlFranchiseGlobal = UtilsService.urlFranchiseGlobal(this.node);

  readonly imageCloudfront: string = environment.imageCloudfront;
  readonly imageCountry: string = environment.node;
  readonly imageCloudfrontAndCountry: string =
    this.imageCloudfront + '/' + this.imageCountry;

  // Botonera
  threeButtons = {
    style: Constants.BUTTON_COLOR_GREY_BASIC,
    height: Constants.BUTTON_HEIGHT_48PX,
    changeStyleOnSelected: true,
    styleOnSelected: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    selected: [true, false, false, false],
    text: [
      { small: 'buy', big: 'want-buy' },
      { small: 'rent', big: 'want-rent' },
      { small: 'sell', big: 'want-sell' },
      { small: 'entrepreneurships', big: 'entrepreneurships' },
    ],
  };

  styleSearchBar = Constants.BUTTON_COLOR_PRIMARY_SOLID;

  page = 1;
  itemsArray = 100;

  photosArray = [
    this.imageCloudfront + '/home/imperdibles-1.webp',
    this.imageCloudfront + '/home/imperdibles-2.webp',
    this.imageCloudfront + '/home/imperdibles-3.webp',
  ];

  // Buscador
  geoSearch: GeoSearch | undefined;

  // Diálogo: Tipo de propiedad
  dialogPropertyType: any;

  // Franquicias
  dataFranchises: any = {
    title: 'home.franchise',
    text: 'home.franchise-text',
    buttonText: 'home.know-more',
    buttonURL: this.urlFranchiseGlobal,
    backgroundImage:
      this.node == 'ec'
        ? this.imageCloudfrontAndCountry +
          '/assets/media/svg/home/franquicias.svg'
        : this.imageCloudfrontAndCountry +
          '/assets/media/svg/home/franquicias.svg',
    isHorizontalMode: this.node == 'ec' ? true : null,
  };

  // Unite a la Red RE/MAX
  dataBecomeREMAXAgent: any = {
    title: 'home.become-agent',
    text: 'home.become-agent-text',
    buttonText: 'home.know-more',
    buttonURL: '/be-agent',
    backgroundImage:
      this.node == 'ec'
        ? this.imageCloudfrontAndCountry +
          '/assets/media/svg/home/ser-agente-remax.svg'
        : this.imageCloudfrontAndCountry +
          '/assets/media/svg/home/ser-agente-remax.svg',
    isHorizontalMode: this.node == 'ec' ? true : null,
  };

  // Encuesta de calidad
  dataQualitySurvey: any = {
    title: 'home.survey',
    text: 'home.survey-text',
    buttonText: 'home.survey-btn',
    buttonURL: 'http://www.experienciaremax.com.ar',
    backgroundImage:
      this.imageCloudfrontAndCountry + '/assets/media/svg/home/encuesta.svg',
  };

  institutionalRelationsURLS: string[] = [
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/camara-de-comercio-de-espana.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/investba.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/aamf.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/endeavor-argentina.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/national-association-of-realtors.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/sepyme.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/enbraci-2017.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/fundacion-florencio-perez.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/embajada-eeuu-argentina.webp',
  ];

  public innerWidth: any;

  // BANNER LENDAR
  bannerLendarWEBP: QRBanner = {
    text: 'Publicidad',
    docFolder: this.imageCountry + '/assets/media/webp/home',
    docName: 'banner-lendar',
    docType: Constants.DOCTYPE_WEBP,
    link: 'https://www.lendar.com.ar/',
    alt: 'Banner Lendar',
    className: QRBanner.CLASSNAME_BANNER_LENDAR,
    width: 'auto',
    height: '125px',
  };

  // BANNER GARANTOR
  bannerGarantorWEBP: QRBanner = {
    text: 'Publicidad',
    docFolder: this.imageCountry + '/assets/media/webp/home',
    docName: 'banner-garantor',
    docType: Constants.DOCTYPE_WEBP,
    link: 'https://garantor.com.ar/',
    alt: 'Banner Garantor',
    className: QRBanner.CLASSNAME_BANNER_GARANTOR,
    width: 'auto',
    height: '156px',
  };

  // Logo RE/MAX Collection
  logoTheREMAXCollection: QRMediaImage = {
    text: 'The RE/MAX Collection',
    baseURL: '',
    docFolder: 'assets/logos',
    docName: 'logo-remax-collection',
    docType: Constants.DOCTYPE_SVG,
    alt: 'The RE/MAX Collection',
    width: 'auto',
    height: '88px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Relaciones institucionales IMG Cámara de comercio de España
  instRelationCamaraDeComercio: QRMediaImage = {
    text: 'Relaciones Institucionales',
    baseURL: QRMediaImage.BASEURL_CLOUDFRONT,
    docFolder:
      QRMediaImage.BASEURL_COUNTRY +
      '/assets/media/webp/home/relaciones-institucionales',
    docName: 'camara-de-comercio-de-espana',
    docType: Constants.DOCTYPE_WEBP,
    alt: 'Cámara de comercio de España',
    width: 'auto',
    height: '44px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Relaciones institucionales IMG Invest BA
  instRelationInvestBA: QRMediaImage = {
    text: 'Relaciones Institucionales',
    baseURL: QRMediaImage.BASEURL_CLOUDFRONT,
    docFolder:
      QRMediaImage.BASEURL_COUNTRY +
      '/assets/media/webp/home/relaciones-institucionales',
    docName: 'investba',
    docType: Constants.DOCTYPE_WEBP,
    alt: 'Invest BA',
    width: 'auto',
    height: '29px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Relaciones institucionales IMG Asoc. Arg  de Marcas y Franquicias
  instRelationAsocArgMarcasFranquicias: QRMediaImage = {
    text: 'Relaciones Institucionales',
    baseURL: QRMediaImage.BASEURL_CLOUDFRONT,
    docFolder:
      QRMediaImage.BASEURL_COUNTRY +
      '/assets/media/webp/home/relaciones-institucionales',
    docName: 'aamf',
    docType: Constants.DOCTYPE_WEBP,
    alt: 'Asociación Argentina de Marcas y Franquicias',
    width: 'auto',
    height: '64px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Relaciones institucionales IMG Endeavor Argentina
  instRelationEndeavorARG: QRMediaImage = {
    text: 'Relaciones Institucionales',
    baseURL: QRMediaImage.BASEURL_CLOUDFRONT,
    docFolder:
      QRMediaImage.BASEURL_COUNTRY +
      '/assets/media/webp/home/relaciones-institucionales',
    docName: 'endeavor-argentina',
    docType: Constants.DOCTYPE_WEBP,
    alt: 'Endeavor Argentina',
    width: 'auto',
    height: '32px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Relaciones institucionales IMG National Association of Realtors
  instRelationNationalAsocRealtors: QRMediaImage = {
    text: 'Relaciones Institucionales',
    baseURL: QRMediaImage.BASEURL_CLOUDFRONT,
    docFolder:
      QRMediaImage.BASEURL_COUNTRY +
      '/assets/media/webp/home/relaciones-institucionales',
    docName: 'national-association-of-realtors',
    docType: Constants.DOCTYPE_WEBP,
    alt: 'National Association of Realtors',
    width: 'auto',
    height: '63px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Relaciones institucionales IMG SEPYME
  instRelationSepyme: QRMediaImage = {
    text: 'Relaciones Institucionales',
    baseURL: QRMediaImage.BASEURL_CLOUDFRONT,
    docFolder:
      QRMediaImage.BASEURL_COUNTRY +
      '/assets/media/webp/home/relaciones-institucionales',
    docName: 'sepyme',
    docType: Constants.DOCTYPE_WEBP,
    alt: 'SEPYME',
    width: 'auto',
    height: '54px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Relaciones institucionales IMG Enbraci 2017
  instRelationEmbraci2017: QRMediaImage = {
    text: 'Relaciones Institucionales',
    baseURL: QRMediaImage.BASEURL_CLOUDFRONT,
    docFolder:
      QRMediaImage.BASEURL_COUNTRY +
      '/assets/media/webp/home/relaciones-institucionales',
    docName: 'enbraci-2017',
    docType: Constants.DOCTYPE_WEBP,
    alt: 'Enbraci 2017',
    width: 'auto',
    height: '58px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Relaciones institucionales IMG Fundación Florencio Perez
  instRelationFundacionFlorecioPerez: QRMediaImage = {
    text: 'Relaciones Institucionales',
    baseURL: QRMediaImage.BASEURL_CLOUDFRONT,
    docFolder:
      QRMediaImage.BASEURL_COUNTRY +
      '/assets/media/webp/home/relaciones-institucionales',
    docName: 'fundacion-florencio-perez',
    docType: Constants.DOCTYPE_WEBP,
    alt: 'Fundación Florencio Perez',
    width: 'auto',
    height: '36px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Relaciones institucionales IMG Embajada de los EEUU en Argentina
  instRelationEmbajadaEEUUenARG: QRMediaImage = {
    text: 'Relaciones Institucionales',
    baseURL: QRMediaImage.BASEURL_CLOUDFRONT,
    docFolder:
      QRMediaImage.BASEURL_COUNTRY +
      '/assets/media/webp/home/relaciones-institucionales',
    docName: 'embajada-eeuu-argentina',
    docType: Constants.DOCTYPE_WEBP,
    alt: 'Embajada de los EEUU en Argentina',
    width: 'auto',
    height: '82px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  swiperConfig: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-button-next2',
      prevEl: '.swiper-button-prev2',
    },
    watchSlidesProgress: true,
    slidesPerView: 'auto',
    spaceBetween: 56,
  };

  public readonly BUTTON_VIEW_PROPERTIES: QRButton = {
    id: 'button-view-properties',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'home.view-properties',
  };

  public readonly BUTTON_VIEW_ENTREPRENEURSHIPS: QRButton = {
    id: 'button-view-entrepreneurships',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'home.view-entrepreneurships',
  };

  public isPhone: boolean = this.checkIfIsPhone();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public paramDomain: ParamsDomain,
    public searchDomain: SearchDomain,
    private utils: UtilsService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
      this.isPhone = this.checkIfIsPhone();
    }
  }

  ngOnInit(): void {
    this.searchDomain.resetAll();
    switch (this.node) {
      case Constants.NODE_URUGUAY:
        this.dataQualitySurvey.buttonURL = 'http://www.experienciaremax.com.uy';
        break;
      case Constants.NODE_ARGENTINA:
      case Constants.NODE_ECUADOR:
      default:
        break;
    }

    this.utils.setMetaTags({
      title: 'Venta y Alquiler de Propiedades | RE/MAX',
      description:
        'Encontrá Casas y Departamentos en Venta y Alquiler. Tu próxima Propiedad está en RE/MAX: Líder Mundial en Servicios Inmobiliarios.',
    });
  }

  search(): void {
    if (this.isPhone && !this.searchDomain.filterOperation.includes(3)) {
      const data: any = {
        isDialogOpen: true,
        configButtonLeft: {
          style: Constants.BUTTON_COLOR_GREY_BASIC,
          height: Constants.BUTTON_HEIGHT_48PX,
          text: 'Cancelar',
        },
        configButtonRight: {
          style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
          height: Constants.BUTTON_HEIGHT_48PX,
          text: 'Buscar',
        },
        dinamicComponent: 'property-type-home',
      };
      this.dialogPropertyType = Object.assign({}, data);
    } else {
      this.applyFilterAndRedirect();
    }
  }

  dialogPropertyTypeButtonLeft(): void {
    this.searchDomain.cancelSelectedFilter();
  }

  dialogPropertyTypeButtonRight(): void {
    this.applyFilterAndRedirect();
  }

  async threeButtonsClick($event: any): Promise<void> {
    this.searchDomain.filterOperation[3];
    //1 sale, 2 rent, 3 temporal
    if ($event.includes('buy')) {
      this.searchDomain.filterOperation = [1];
    } else if ($event.includes('entrepreneurships')) {
      this.searchDomain.filterOperation = [3];
    } else if ($event.includes('rent')) {
      this.searchDomain.filterOperation = [2];
    } else if ($event.includes('sell')) {
      window.location.href = '/sell';
    }
  }

  onImperdibles(): void {
    const value: number[] = [1, 2]; //2 destacado, 1 muy destacado
    this.searchDomain.filterOperation = [1, 2]; // compra y alquilar
    this.searchDomain.filterOpportunitiesType = [...value];
    if (this.searchDomain.isFilterChange()) {
      this.searchDomain.page = 0;
      this.searchDomain.windowLocationHref();
    }
  }

  private applyFilterAndRedirect(): void {
    if (this.searchDomain.filterOperation.includes(3)) {
      this.searchDomain.windowLocationHrefEmpre();
    } else {
      this.searchDomain.windowLocationHref();
    }
  }

  private checkIfIsPhone(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return window.matchMedia('(max-width: 768px)').matches;
    }
    return false;
  }
}
