<div class="carousel">
  @if (hrefURL) {
    <a
      class="carousel__href"
      [href]="hrefURL"
      [target]="isDesktop ? '_blank' : ''"
      (click)="handleAnchorClick($event)">
      <ng-container *ngTemplateOutlet="carouselInner"></ng-container
    ></a>
  } @else {
    <ng-container *ngTemplateOutlet="carouselInner"></ng-container>
  }
  <qr-button
    class="carousel__button-navigation carousel__button-navigation--left"
    [class.carousel__button-navigation--hidden]="currentIndex === 0"
    [config]="BUTTON_NAVIGATION_LEFT"
    (click)="prevSlide()"
    [isDisabled]="currentIndex === 0"></qr-button>
  <qr-button
    class="carousel__button-navigation carousel__button-navigation--right"
    [class.carousel__button-navigation--hidden]="
      currentIndex >= photos.length - 1
    "
    [config]="BUTTON_NAVIGATION_RIGHT"
    (click)="nextSlide()"
    [isDisabled]="currentIndex >= photos.length - 1"></qr-button>
</div>

<ng-template #carouselInner>
  <div
    class="carousel__inner"
    (mousedown)="mouseDown($event)"
    (mousemove)="mouseMove($event)"
    (mouseup)="mouseUp()"
    (mouseleave)="mouseUp()"
    (touchstart)="touchStart($event)"
    (touchmove)="touchMove($event)"
    (touchend)="touchEnd()"
    [style.transform]="
      'translateX(' + (dragOffset + currentIndex * -slideWidth) + 'px)'
    "
    [style.transition]="isDragging ? 'none' : 'transform 0.2s ease-in-out'"
    [class.carousel__inner--dragging]="isDragging">
    @for (slide of resizedSlides; track $index) {
      <img
        [src]="slide"
        [alt]="'Foto ' + $index"
        class="carousel__slide"
        loading="lazy" />
    }</div
></ng-template>
