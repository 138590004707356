import { Component, OnDestroy, OnInit } from '@angular/core';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRButtonComponent } from '../../../../components/ui/qr-button/qr-button.component';
import { UserSearchSaveService } from '@base/src/app/services/user-search.service';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IQrResponse } from '@base/src/app/core/models/IQrResponse';
import { SearchName } from '@base/src/app/core/models/search-name.model';

@Component({
  selector: 'app-unsubscribe-alert',
  standalone: true,
  imports: [QRButtonComponent],
  templateUrl: './unsubscribe-alert.page.html',
  styleUrl: './unsubscribe-alert.page.scss',
})
export class UnsubscribeAlertPage implements OnInit, OnDestroy {
  public searchName: string = '';
  public readonly BUTTON_UNSUBSCRIBE: QRButton = {
    id: 'button-unsubscribe',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Sí, dar de baja',
  };

  private readonly destroy$: Subject<void> = new Subject<void>();
  private searchId!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userSearchSaveService: UserSearchSaveService
  ) {}

  ngOnInit(): void {
    const URL: Params = this.activatedRoute.snapshot.queryParams;
    const [ID]: string[] = Object.keys(URL);
    if (ID) {
      this.searchId = ID;
      this.setSearchName();
    } else {
      console.error('No se encontró el ID de búsqueda en la URL.');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public unsubscribe(): void {
    if (!this.searchId) {
      console.error('ID de búsqueda no disponible.');
      return;
    }

    const UNSUBSCRIBE_URL: string = `${this.searchId}/unsubscribe`;

    this.userSearchSaveService
      .updateData<string, null>(UNSUBSCRIBE_URL)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.router.navigate(['/darse-de-baja-confirmacion']);
        },
        error: err => {
          console.error('Error al desactivar la alerta:', err);
        },
      });
  }

  public setSearchName(): void {
    if (!this.searchId) {
      console.error('El ID de búsqueda no está definido.');
      return;
    }

    this.userSearchSaveService
      .getData<SearchName>(this.searchId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<SearchName>) => {
          if (response.code == 200) {
            this.searchName = response.data.name;
          }
        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
      });
  }
}
