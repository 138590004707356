export interface TotalResultsSEO {
  landing: string;
  message: string;
  registers: number;
  totalResult: number;
}

// Landing
export class LandingListSEO {
  public static MONTEVIDEO_TOTAL_RESULTS = 'en-montevideo';
  public static PROPIEDADES_CORDOBA_TOTAL_RESULTS = 'en-cordoba';
  public static PROPIEDADES_CABA_TOTAL_RESULTS = 'en-ciudad-de-buenos-aires';
  public static PROPIEDADES_ROSARIO_TOTAL_RESULTS = 'en-rosario';
  public static PROPIEDADES_LA_PLATA_TOTAL_RESULTS = 'en-la-plata';
  public static PROPIEDADES_MENDOZA_TOTAL_RESULTS = 'en-mendoza';
  public static PROPIEDADES_SANTA_FE_TOTAL_RESULTS = 'en-santa-fe';
  public static PROPIEDADES_PALERMO_TOTAL_RESULTS = 'en-palermo';
  public static PROPIEDADES_POSADAS_TOTAL_RESULTS = 'en-posadas';
  public static PROPIEDADES_SAN_ISIDRO_TOTAL_RESULTS = 'en-san-isidro';
  public static PROPIEDADES_TIGRE_TOTAL_RESULTS = 'en-tigre';
  public static PROPIEDADES_ARGENTINA_TOTAL_RESULTS = 'en-argentina'; // aún no está implementado en el BE, igual esta landing es inaccesible aún
  public static PROPIEDADES_URUGUAY_TOTAL_RESULTS = 'en-uruguay';
}

// Listing List
export class ListingListSEO {
  public static ALQUILERES_TEMPORARIOS = 'alquileres-temporarios';

  //  CABA
  public static DEPARTAMENTOS_ALQUILER_CABA_TOTAL_RESULTS =
    'departamentos-en-alquiler-en-ciudad-de-buenos-aires';

  public static DEPARTAMENTOS_VENTA_CABA_TOTAL_RESULTS =
    'departamentos-en-venta-en-ciudad-de-buenos-aires';

  public static CASAS_VENTA_CABA_TOTAL_RESULTS =
    'casas-en-venta-en-ciudad-de-buenos-aires';

  public static CASAS_ALQUILER_CABA_TOTAL_RESULTS =
    'casas-en-alquiler-en-ciudad-de-buenos-aires';

  // Córdoba
  public static CASAS_CORDOBA_TOTAL_RESULTS = 'casas-en-cordoba';
  public static CASAS_ALQUILER_CORDOBA_TOTAL_RESULTS =
    'casas-en-alquiler-en-cordoba';

  public static CASAS_VENTA_CORDOBA_TOTAL_RESULTS = 'casas-en-venta-en-cordoba';
  public static DEPARTAMENTOS_CORDOBA_TOTAL_RESULTS =
    'departamentos-en-cordoba';

  public static DEPARTAMENTOS_ALQUILER_CORDOBA_TOTAL_RESULTS =
    'departamentos-en-alquiler-en-cordoba';

  public static DEPARTAMENTOS_VENTA_CORDOBA_TOTAL_RESULTS =
    'departamentos-en-venta-en-cordoba';

  public static TERRENOS_VENTA_CORDOBA_TOTAL_RESULTS =
    'terrenos-en-venta-en-cordoba';

  // Rosario
  public static CASAS_ROSARIO_TOTAL_RESULTS = 'casas-en-rosario';
  public static CASAS_VENTA_ROSARIO_TOTAL_RESULTS = 'casas-en-venta-en-rosario';
  public static CASAS_ALQUILER_ROSARIO_TOTAL_RESULTS =
    'casas-en-alquiler-en-rosario';

  public static DEPARTAMENTOS_ROSARIO_TOTAL_RESULTS =
    'departamentos-en-rosario';

  public static DEPARTAMENTOS_ALQUILER_ROSARIO_TOTAL_RESULTS =
    'departamentos-en-alquiler-en-rosario';

  public static DEPARTAMENTOS_VENTA_ROSARIO_TOTAL_RESULTS =
    'departamentos-en-venta-en-rosario';

  public static TERRENOS_VENTA_ROSARIO_TOTAL_RESULTS =
    'terrenos-en-venta-en-rosario';

  // La plata
  public static CASAS_EN_LA_PLATA_TOTAL_RESULTS = 'casas-en-la-plata';
  public static CASAS_EN_VENTA_EN_LA_PLATA_TOTAL_RESULTS =
    'casas-en-venta-en-la-plata';

  public static CASAS_EN_ALQUILER_EN_LA_PLATA_TOTAL_RESULTS =
    'casas-en-alquiler-en-la-plata';

  public static DEPARTAMENTOS_EN_LA_PLATA_TOTAL_RESULTS =
    'departamentos-en-la-plata';

  public static DEPARTAMENTOS_EN_VENTA_EN_LA_PLATA_TOTAL_RESULTS =
    'departamentos-en-venta-en-la-plata';

  public static DEPARTAMENTOS_EN_ALQUILER_EN_LA_PLATA_TOTAL_RESULTS =
    'departamentos-en-alquiler-en-la-plata';

  public static TERRENOS_EN_LA_PLATA_TOTAL_RESULTS = 'terrenos-en-la-plata';
  public static TERRENOS_EN_VENTA_EN_LA_PLATA_TOTAL_RESULTS =
    'terrenos-en-la-plata';

  // Terrrenos Uruguay
  public static TERRENOS_EN_CANELONES_TOTAL_RESULTS = 'terrenos-en-canelones';
  public static TERRENOS_EN_ROCHA_TOTAL_RESULTS = 'terrenos-en-rocha';
  public static TERRENOS_EN_URUGUAY_TOTAL_RESULTS = 'terrenos-en-uruguay';
  public static TERRENOS_EN_MALDONADO_TOTAL_RESULTS = 'terrenos-en-maldonado';
  // Montevideo
  public static APARTAMENTOS_EN_MONTEVIDEO_TOTAL_RESULTS =
    'apartamentos-en-montevideo';

  public static APARTAMENTOS_EN_VENTA_EN_MONTEVIDEO_TOTAL_RESULTS =
    'apartamentos-en-venta-en-montevideo';

  public static APARTAMENTOS_EN_ALQUILER_EN_MONTEVIDEO_TOTAL_RESULTS =
    'apartamentos-en-alquiler-en-montevideo';

  public static CASAS_EN_MONTEVIDEO_TOTAL_RESULTS = 'casas-en-montevideo';
  public static CASAS_EN_VENTA_EN_MONTEVIDEO_TOTAL_RESULTS =
    'casas-en-venta-en-montevideo';

  public static CASAS_EN_ALQUILER_EN_MONTEVIDEO_TOTAL_RESULTS =
    'casas-en-alquiler-en-montevideo';

  public static ALQUILERES_TEMPORARIOS_EN_MONTEVIDEO =
    'alquileres-temporarios-en-montevideo';

  // Punta del este

  public static ALQUILERES_TEMPORARIOS_EN_PUNTA_DEL_ESTE =
    'alquileres-temporarios-en-punta-del-este';

  // Piriápolis

  public static ALQUILERES_TEMPORARIOS_EN_PIRIAPOLIS =
    'alquileres-temporarios-en-piriapolis';

  // Mendoza
  public static CASAS_EN_MENDOZA_TOTAL_RESULTS = 'casas-en-mendoza';
  public static CASAS_EN_VENTA_EN_MENDOZA_TOTAL_RESULTS =
    'casas-en-venta-en-mendoza';

  public static CASAS_EN_ALQUILER_EN_MENDOZA_TOTAL_RESULTS =
    'casas-en-alquiler-en-mendoza';

  public static DEPARTAMENTOS_EN_VENTA_EN_MENDOZA_TOTAL_RESULTS =
    'departamentos-en-venta-en-mendoza';

  public static DEPARTAMENTOS_EN_ALQUILER_EN_MENDOZA_TOTAL_RESULTS =
    'departamentos-en-alquiler-en-mendoza';

  public static TERRENOS_EN_VENTA_EN_MENDOZA_TOTAL_RESULTS =
    'terrenos-en-venta-en-mendoza';

  //Santa Fe
  public static CASAS_EN_SANTA_FE_TOTAL_RESULTS = 'casas-en-santa-fe';
  public static CASAS_EN_VENTA_EN_SANTA_FE_TOTAL_RESULTS =
    'casas-en-venta-en-santa-fe';

  public static CASAS_EN_ALQUILER_EN_SANTA_FE_TOTAL_RESULTS =
    'casas-en-alquiler-en-santa-fe';

  public static DEPARTAMENTOS_EN_SANTA_FE_TOTAL_RESULTS =
    'departamentos-en-santa-fe';

  public static DEPARTAMENTOS_EN_VENTA_EN_SANTA_FE_TOTAL_RESULTS =
    'departamentos-en-venta-en-santa-fe';

  public static DEPARTAMENTOS_EN_ALQUILER_EN_SANTA_FE_TOTAL_RESULTS =
    'departamentos-en-alquiler-en-santa-fe';

  public static TERRENOS_EN_SANTA_FE_TOTAL_RESULTS = 'terrenos-en-santa-fe';
  public static TERRENOS_EN_VENTA_EN_SANTA_FE_TOTAL_RESULTS =
    'terrenos-en-venta-en-santa-fe';

  // Palermo
  public static CASAS_EN_VENTA_EN_PALERMO_TOTAL_RESULTS =
    'casas-en-venta-en-palermo';

  public static CASAS_EN_ALQUILER_EN_PALERMO_TOTAL_RESULTS =
    'casas-en-alquiler-en-palermo';

  public static DEPARTAMENTOS_EN_VENTA_EN_PALERMO_TOTAL_RESULTS =
    'departamentos-en-venta-en-palermo';

  public static DEPARTAMENTOS_EN_ALQUILER_EN_PALERMO_TOTAL_RESULTS =
    'departamentos-en-alquiler-en-palermo';

  // San Isidro
  public static DEPARTAMENTOS_EN_SAN_ISIDRO_TOTAL_RESULTS =
    'departamentos-en-san-isidro';

  public static CASAS_EN_SAN_ISIDRO_TOTAL_RESULTS = 'casas-en-san-isidro';
  public static DEPARTAMENTOS_EN_VENTA_EN_SAN_ISIDRO_TOTAL_RESULTS =
    'departamentos-en-venta-en-san-isidro';

  public static CASAS_EN_VENTA_EN_SAN_ISIDRO_TOTAL_RESULTS =
    'casas-en-venta-en-san-isidro';

  // Tigre
  public static DEPARTAMENTOS_EN_TIGRE_TOTAL_RESULTS =
    'departamentos-en-san-isidro';

  public static CASAS_EN_TIGRE_TOTAL_RESULTS = 'casas-en-san-isidro';
  public static DEPARTAMENTOS_EN_VENTA_EN_TIGRE_TOTAL_RESULTS =
    'departamentos-en-venta-en-san-isidro';

  public static CASAS_EN_VENTA_EN_TIGRE_TOTAL_RESULTS =
    'casas-en-venta-en-san-isidro';

  // San Juan
  public static DEPARTAMENTOS_EN_VENTA_EN_SAN_JUAN_TOTAL_RESULTS =
    'departamentos-en-venta-en-san-juan';

  public static DEPARTAMENTOS_EN_ALQUILER_EN_SAN_JUAN_TOTAL_RESULTS =
    'departamentos-en-venta-en-san-juan';

  public static CASAS_EN_VENTA_EN_SAN_JUAN_TOTAL_RESULTS =
    'departamentos-en-venta-en-san-juan';

  public static CASAS_EN_ALQUILER_EN_SAN_JUAN_TOTAL_RESULTS =
    'departamentos-en-venta-en-san-juan';

  // SALTA
  public static TERRENOS_EN_VENTA_EN_SALTA_TOTAL_RESULTS =
    'terrenos-en-venta-en-salta';

  // JUJUY
  public static TERRENOS_EN_VENTA_EN_JUJUY_TOTAL_RESULTS =
    'terrenos-en-venta-en-jujuy';
}
