import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  ViewChild,
  PLATFORM_ID,
  OnInit,
} from '@angular/core';
import { RouterOutlet, Router, RouterModule } from '@angular/router';
import { MENU } from '../menu';
import { Constants } from '@base/src/app/core/constants/constants';
import { MenuItem } from '@app/core/models/menu.model';
import { L10nLocale, L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { environment } from '@base/environments/environment';
import { Auth } from 'aws-amplify';
import { FooterComponent } from '../public-footer/footer.component';
import { NavbarComponent } from '../public-navbar/navbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { QrButtonComponent } from '../../components/qr-button/qr-button.component';
import { QrIconButtonComponent } from '../../components/qr-icon-button/qr-icon-button.component';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'public-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    FooterComponent,
    NavbarComponent,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    QrButtonComponent,
    QrIconButtonComponent,
    L10nTranslatePipe,
    MatRippleModule,
  ],
  templateUrl: './public.component.html',
  styleUrl: './public.component.scss',
})
export class PublicLayout implements OnInit {
  node: string = environment.node;
  @ViewChild('drawer') drawer: any;
  @ViewChild('sideNavAccount') sideNavAccount: any;
  menuItems: MenuItem[] = [];

  configMenuButtonClose = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  }; // Menú: Botón: Cerrar

  configMenuButtonAccount = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  }; // Menú: Botón: Cuenta

  pathSelected: string = '';
  baseURL: string = '';
  urlRouter: string = '';
  getURLLocation: Location | undefined;

  configButtonLogin = {
    style: Constants.BUTTON_COLOR_PRIMARY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Iniciar sesión',
    // isDisabled: true,
  };

  itemsMenuMobile = [
    { name: 'Favoritos', route: '/favoritos', icon: 'favorite-outlined' },
    { name: 'Búsquedas y alertas', route: '/busquedas', icon: 'bookmark' },
    { name: 'Cuenta', route: '/cuenta/datos', icon: 'person-outlined' },
    { name: 'Datos', route: '/cuenta/datos', icon: '' },
    {
      name: 'Cambiar contraseña',
      route: '/cuenta/cambiar-contraseña',
      icon: '',
    },
    { name: 'Eliminar cuenta', route: '/cuenta/eliminar', icon: '' },
    { name: 'Cerrar sesión', route: '/', icon: 'logout' },
  ];

  userLoged = false;
  userData = {
    name: '',
    lastname: '',
  };

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.menuItems = MENU;

    if (this.node == Constants.NODE_ECUADOR) {
      // En Ecuador se oculta la sección Blog
      this.menuItems = this.menuItems.filter(
        section =>
          section.label != 'home.blog' &&
          section.label != 'home.emprendimientos'
      );
    } else if (this.node == Constants.NODE_URUGUAY) {
      // En Ecuador se oculta la sección Blog
      this.menuItems = this.menuItems.filter(
        section => section.label != 'home.emprendimientos'
      );
    }

    if (isPlatformBrowser(this.platformId)) {
      this.checkAuthProvider();
      this.pathSelected = window.location.href;
      this.getURLLocation = window.location;
      this.baseURL =
        this.getURLLocation.protocol + '//' + this.getURLLocation.host;
      this.urlRouter =
        this.getURLLocation.protocol +
        '//' +
        this.getURLLocation.host +
        '/' +
        this.getURLLocation.pathname.split('/')[1];
    }
    // Detectar cambio de router
    this.router.events.subscribe(() => {
      if (!this.getURLLocation) {
        return;
      }
      this.urlRouter =
        this.getURLLocation.protocol +
        '//' +
        this.getURLLocation.host +
        '/' +
        this.getURLLocation.pathname.split('/')[1];
    });
    //conseguir info del user logueado
    this.getAuthenticatedUserInfo();
  }

  closeSideNav(): void {
    if (this.drawer._mode == 'over') {
      this.drawer.close();
    }
  }

  closeSideNavAccount(): void {
    if (isPlatformBrowser(this.platformId)) {
      const URL: string = window.location.href;
      localStorage.setItem('returnUrl', URL);
      this.router.navigate(['/iniciar-sesion']);
    }
    this.sideNavAccount.close();
  }

  close() {
    this.sideNavAccount.close();
  }

  openSideNav(): void {
    this.drawer.open();
  }

  openSideNavAccount(): void {
    this.sideNavAccount.open();
  }

  /*isHomeSelected: Especial para detectar cuando esta en la página principal*/
  isHomeSelected(): boolean {
    let isSelected = false;
    if (this.urlRouter === this.baseURL + '/') {
      isSelected = true;
    } else {
      isSelected = false;
    }
    return isSelected;
  }

  onLogout(): void {
    Auth.signOut().then(() => {
      this.router.navigate(['']).then(() => {
        window.location.reload();
        this.sideNavAccount.close();
      });
    });
  }

  getUserInfo(): void {
    Auth.currentUserInfo()
      .then(response => {
        this.userData.name = response.attributes.given_name;
        this.userData.lastname = response.attributes.family_name;
        this.userLoged = true;
      })
      .catch(error => {
        console.log(error);
        this.userLoged = false;
      });
  }

  private getAuthenticatedUserInfo(): void {
    if (isPlatformBrowser(this.platformId)) {
      Auth.currentAuthenticatedUser()
        .then(() => {
          this.getUserInfo();

          const AUTH_PROVIDER: string =
            localStorage.getItem('authProvider') || '';

          if (AUTH_PROVIDER === 'Google' || AUTH_PROVIDER === 'Facebook') {
            const COUNT: string = localStorage.getItem('countLogin') || '0';
            const NEW_COUNT: number = parseInt(COUNT, 10) + 1;
            localStorage.setItem('countLogin', NEW_COUNT.toString());

            if (NEW_COUNT === 1) {
              const URL: string = localStorage.getItem('returnUrl') || '/';

              window.location.href = URL;
              localStorage.removeItem('returnUrl');
            }
          }
        })
        .catch(() => {
          localStorage.setItem('countLogin', '0');
          localStorage.removeItem('returnUrl');
          localStorage.removeItem('authProvider');
        });
    }
  }

  private checkAuthProvider(): void {
    if (isPlatformBrowser(this.platformId)) {
      const AUTH_PROVIDER: string = localStorage.getItem('authProvider') || '';

      if (AUTH_PROVIDER === 'Google' || AUTH_PROVIDER === 'Facebook') {
        this.updateLoginCount();
      }
    }
  }

  private updateLoginCount(): void {
    if (isPlatformBrowser(this.platformId)) {
      const COUNT: string = localStorage.getItem('countLogin') || '';

      if (COUNT === null) {
        localStorage.setItem('countLogin', '0');
      }
    }
  }
}
