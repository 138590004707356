import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { Constants } from '../../core/constants/constants';
import { QRButton } from '../../core/models/qr-button.model';
import { QRIcon } from '../../core/models/qr-icon.model';
import { QRButtonComponent } from '../ui/qr-button/qr-button.component';
import { ResizedImage } from '../../core/models/resized-photo.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  standalone: true,
  imports: [QRButtonComponent, CommonModule],
})
export class CarouselComponent implements OnInit, OnChanges, OnDestroy {
  @Input() photos!: { rawValue: string }[];
  @Input() resizedPhotoSize: string = ResizedImage.MEDIUM;
  @Input() hrefURL?: string;
  @Input() isDesktop: boolean = false;

  public resizedSlides: string[] = [];
  public currentIndex: number = 0;
  public slideWidth: number = 0;
  public isDragging: boolean = false;
  public readonly DRAG_THRESHOLD: number = 48;
  public dragOffset: number = 0;

  public readonly BUTTON_NAVIGATION_LEFT: QRButton = {
    id: 'button-navigation-left',
    hierarchy: QRButton.HIERARCHY_GREY,
    size: QRButton.SIZE_40_PX,
    icon: QRIcon.NAME_ARROW_LEFT,
    iconOnly: true,
  };

  public readonly BUTTON_NAVIGATION_RIGHT: QRButton = {
    id: 'button-navigation-right',
    hierarchy: QRButton.HIERARCHY_GREY,
    size: QRButton.SIZE_40_PX,
    icon: QRIcon.NAME_ARROW_RIGHT,
    iconOnly: true,
  };

  private dragStartX: number = 0;
  private resizeObserver!: ResizeObserver;
  private isDraggingEvent: boolean = false; // Para evitar que se ejecute el evento click en el anchor al soltar el click luego de un drag

  constructor(
    private utilsService: UtilsService,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.updateSlideWidth();
    this.observeComponentSizeChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['photos']?.currentValue) {
      if (this.photos[0]) {
        this.resizedSlides.push(this.resizePropertyPhoto(this.photos[0]));
      }
      if (this.photos[1]) {
        this.resizedSlides.push(this.resizePropertyPhoto(this.photos[1]));
      }
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  public nextSlide(): void {
    if (this.currentIndex < this.photos.length - 1) {
      this.currentIndex++;
      if (
        this.currentIndex < this.photos.length - 1 &&
        this.resizedSlides[this.currentIndex + 1] === undefined
      ) {
        this.resizedSlides.push(
          this.resizePropertyPhoto(this.photos[this.currentIndex + 1])
        );
      }
    }
  }

  public prevSlide(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  public mouseDown(event: MouseEvent): void {
    event.preventDefault();
    this.startDrag(event.clientX);
  }

  public mouseMove(event: MouseEvent): void {
    if (!this.isDragging) return;
    this.handleDrag(event.clientX);
  }

  public mouseUp(): void {
    this.endDrag();
  }

  public touchStart(event: TouchEvent): void {
    this.startDrag(event.touches[0].clientX);
  }

  public touchMove(event: TouchEvent): void {
    if (!this.isDragging) return;
    this.handleDrag(event.touches[0].clientX);
  }

  public touchEnd(): void {
    this.endDrag();
  }

  public handleAnchorClick(event: MouseEvent): void {
    if (this.isDraggingEvent) {
      event.stopPropagation(); // Stop the click event from bubbling
      event.preventDefault(); // Prevent the default anchor navigation
    }
  }

  private updateSlideWidth(): void {
    const COMPONENT_ELEMENT: HTMLElement = this.elRef
      .nativeElement as HTMLElement;
    if (COMPONENT_ELEMENT) {
      this.slideWidth = COMPONENT_ELEMENT.clientWidth;
    }
  }

  private resizePropertyPhoto(photo: { rawValue: string }): string {
    let resizedPhoto: string = '';
    if (photo?.rawValue) {
      resizedPhoto = this.utilsService.generateImageWithSize(
        photo.rawValue,
        this.resizedPhotoSize,
        Constants.DOCTYPE_WEBP,
        false
      );
    }
    return resizedPhoto;
  }

  private startDrag(startX: number): void {
    this.dragStartX = startX;
    this.isDragging = true;
    this.dragOffset = 0; // Reset offset
  }

  private handleDrag(currentX: number): void {
    const DRAG_DISTANCE: number = currentX - this.dragStartX;
    this.dragOffset = DRAG_DISTANCE;
    this.isDraggingEvent = true;
  }

  private endDrag(): void {
    const DRAG_DISTANCE: number = this.dragOffset;
    // Determine slide navigation based on drag distance
    if (DRAG_DISTANCE > this.DRAG_THRESHOLD) {
      this.prevSlide();
    } else if (DRAG_DISTANCE < -this.DRAG_THRESHOLD) {
      this.nextSlide();
    }
    this.dragOffset = 0; // Reset drag offset
    this.isDragging = false; // Reset dragging state
    setTimeout(() => {
      this.isDraggingEvent = false;
    }, 1);
  }

  private observeComponentSizeChanges(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.updateSlideWidth();
    });
    const COMPONENT_ELEMENT: HTMLElement = this.elRef
      .nativeElement as HTMLElement;
    if (COMPONENT_ELEMENT) {
      this.resizeObserver.observe(COMPONENT_ELEMENT);
    }
  }
}
