import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QRIcon } from '@app/core/models/qr-icon.model';
import { QRTextarea } from '@app/core/models/qr-textarea.model';
import { QRIconComponent } from '../qr-icon/qr-icon.component';
type TextareaValue = string | number | undefined;

@Component({
  selector: 'qr-textarea',
  templateUrl: './qr-textarea.component.html',
  styleUrls: ['./qr-textarea.component.scss'],
  standalone: true,
  imports: [CommonModule, QRIconComponent],
})
export class QRTextareaComponent {
  @Input() config?: QRTextarea;
  @Input() value: TextareaValue;
  @Input() isValid?: boolean = false;
  @Input() isDisabled?: boolean = false;
  @Input() hasError?: boolean = false;
  @Output() blurChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() textareaChange: EventEmitter<string | number | undefined> =
    new EventEmitter<string | number | undefined>();

  public textValue: string = '';

  iconCheck: QRIcon = { name: QRIcon.NAME_CHECK, color: QRIcon.COLOR_PRIMARY };
  iconError: QRIcon = { name: QRIcon.NAME_ERROR, color: QRIcon.COLOR_RED };

  public keydownChange(event: KeyboardEvent): void {
    if (this.config?.limitNumberOfCharacters) {
      if (
        this.textValue.length >= this.config?.limitNumberOfCharacters &&
        !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)
      ) {
        event.preventDefault();
      }
    }
  }

  public pasteChange(event: ClipboardEvent): void {
    if (this.config?.limitNumberOfCharacters) {
      event.preventDefault();
      const CLIPBOARD_DATA: string = event.clipboardData?.getData('text') || '';
      const ALLOWED_TEXT: string = CLIPBOARD_DATA.slice(
        0,
        this.config?.limitNumberOfCharacters - this.textValue.length
      );
      this.textValue += ALLOWED_TEXT;
      this.textareaChange.emit(this.textValue);
    }
  }

  change(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value: TextareaValue = input.value;
    this.textValue = input.value;
    this.textareaChange.emit(value);
  }
}
