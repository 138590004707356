import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TagComponent } from '../../../../../../components/ui/tag/tag.component';
import { QRDropdownComponent } from '../../../../../../components/ui/qr-dropdown/qr-dropdown.component';
import { Tag } from '@base/src/app/core/models/tag.model';
import { QRDropdown } from '@base/src/app/core/models/qr-dropdown.model';
import { Alerts } from '@base/src/app/core/enum/Alerts';
import { CommonModule, DatePipe } from '@angular/common';
import { AlertNamePipe } from '../../pipes/alert-name.pipe';
import { UserSaveSearch } from '@base/src/app/core/interfaces/user-save-search.interface';
import { SavedSearchAction } from '../../models/saved-search-action.model';
import { SaveSearchsUtilsService } from '../../services/save-searchs-utils.service';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRIcon } from '@base/src/app/core/models/qr-icon.model';
import { QRButtonComponent } from '../../../../../../components/ui/qr-button/qr-button.component';

@Component({
  selector: 'app-saved-search-item',
  standalone: true,
  imports: [
    TagComponent,
    QRDropdownComponent,
    DatePipe,
    AlertNamePipe,
    QRButtonComponent,
    CommonModule,
  ],
  templateUrl: './saved-search-item.component.html',
  styleUrl: './saved-search-item.component.scss',
})
export class SavedSearchItemComponent {
  @Input() public savedSearch!: UserSaveSearch;
  @Output() public dispatchAction: EventEmitter<SavedSearchAction> =
    new EventEmitter<SavedSearchAction>();

  public readonly BUTTON_EDIT: QRButton = {
    id: 'button-edit',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_DRAW,
    iconOnly: true,
    iconTooltipOnly: 'Editar',
  };

  public readonly BUTTON_DELETE: QRButton = {
    id: 'button-delete',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_DELETE,
    iconOnly: true,
    iconTooltipOnly: 'Eliminar',
  };

  public readonly BUTTON_GO_TO_SEARCH: QRButton = {
    id: 'button-go-to-search',
    hierarchy: QRButton.HIERARCHY_TONAL,
    size: QRButton.SIZE_48_PX,
    value: 'Ir a la búsqueda',
    icon: QRIcon.NAME_BOOKMARK,
  };

  public TAG_PRIMARY_BORDER: Tag = {
    color: Tag.COLOR_PRIMARY_BORDER,
  };

  public DROPDOWN_ALERT: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Alerta',
    options: [Alerts.never, Alerts.inmediatly, Alerts.daily, Alerts.weekly],
    label: 'Alerta',
  };

  public dropdownAlertSelected: string = Alerts.never;

  public dropdownAlertChange(e: string | string[] | undefined): void {
    if (e) {
      this.savedSearch.typeAlert = SaveSearchsUtilsService.getAlertID(
        e.toString()
      );
      this.dispatchAction.emit({
        action: SavedSearchAction.ACTION_EDIT,
        savedSearch: this.savedSearch,
      });
    }
  }

  public edit(): void {
    this.dispatchAction.emit({
      action: SavedSearchAction.ACTION_OPEN_EDIT_DIALOG,
      savedSearch: this.savedSearch,
    });
  }

  public delete(): void {
    this.dispatchAction.emit({
      action: SavedSearchAction.ACTION_OPEN_DELETE_DIALOG,
      savedSearch: this.savedSearch,
    });
  }
}
